import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import React from "react";
import {
  ListText,
  MarkIcon,
  MarketContainer,
  MarketDesc,
  MarketImg,
  MarketImgWrap,
  MarketTitle,
  TitleFeatures,
} from "./MarketIntegration.style";

function MarketIntegration({ sectionData }) {
  const itemListCotent = sectionData?.content_trading_features?.map((item) => (
    <ListItem disablePadding key={item}>
      <ListItemButton sx={{ alignItems: "baseline" }}>
        <ListItemIcon sx={{ minWidth: "30px" }}>
          <MarkIcon fontSize="small" />
        </ListItemIcon>
        <ListText primary={item} />
      </ListItemButton>
    </ListItem>
  ));
  return (
    <Box>
      <MarketContainer>
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between">
            <Grid item md={6}>
              <Stack direction="column" pt={5}>
                <MarketTitle variant="h1" mb={4}>
                  {sectionData?.title}
                </MarketTitle>
                <MarketDesc variant="subtitle1" mb={4}>
                  {sectionData?.desc}
                </MarketDesc>
                {sectionData.Title_trading_features && (
                  <>
                    <TitleFeatures variant="subtitle2" mb={2}>
                      {sectionData.Title_trading_features}
                    </TitleFeatures>
                    <Box>
                      <List>{itemListCotent}</List>
                    </Box>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item md={6}>
              <MarketImgWrap>
                <MarketImg src={sectionData.img.src} />
              </MarketImgWrap>
            </Grid>
          </Grid>
        </Container>
      </MarketContainer>
    </Box>
  );
}

export default MarketIntegration;
