import { styled, Box, Tabs, Tab } from "@mui/material";

export const Section = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));

export const ProductWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(16, 0),
}));
export const ImgItem = styled("img")(({ theme }) => ({
  objectFit: "contain",
  width: "100%",
}));
export const TabStyle = styled(Tab)(({ theme }) => ({
  width: "30%",
  color: "#3B3C8A",
  fontSize: "24px",
  fontWeight: 400,
  fontFamily: "DIN Pro",
  borderRadius: "5px",
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "#F9F9FF",
  marginBottom: "40px",
  "& .MuiButtonBase-root.Mui-selected": {
    // boxShadow: "10px 20px 40px rgba(185, 186, 189, 0.3)",
    color: "#fff ",
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.primary.main,
    height: "6px",
    borderRadius: "10px",
    display: "none",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
  },
  "& .MuiTab-root": {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
