// Prop Types
import PropTypes from "prop-types";
import { Box, Container, Stack } from "@mui/material";
import SECTIONS from "shared/sections";
import HomeHero from "./homeHero/HomeHero.component";

import { AboutHero } from "./hero.variants";
// Styles
import {
  AboutBottomBar,
  AboutBottomBarContent,
  AboutBottomBarContentDesc,
  AboutBottomBarContentImg,
  HeroContainer,
} from "./hero.styles";

// TODO:
// Make It Responsive

function Hero({ page }) {
  if (page === "About") {
    const { ABOUT_HERO } = SECTIONS;
    const bottomBar = ABOUT_HERO?.cards?.map((card, index) => (
      <Box marginRight={3} key={`card_${index}`}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <AboutBottomBarContentImg src={card.img.src} alt="" />
          <AboutBottomBarContentDesc variant="subtitle2">
            {card.desc}
          </AboutBottomBarContentDesc>
        </Stack>
      </Box>
    ));
    return (
      <>
        <HeroContainer component="section" maxWidth="xl">
          <AboutHero />
        </HeroContainer>
        <AboutBottomBar>
          <Container>
            <AboutBottomBarContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {bottomBar}
              </Stack>
            </AboutBottomBarContent>
          </Container>
        </AboutBottomBar>
      </>
    );
  }
  return <HomeHero />;
}

Hero.propTypes = {
  page: PropTypes.oneOf(["Home", "About"]).isRequired,
};

export default Hero;
