// MUI
import {
  Button,
  Container,
  styled,
  Tab,
  Tabs,
  Typography,
  Box,
} from "@mui/material";
// theme
import { COLORS } from "styles/theme";

export const FeatureContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(16),
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(18),
  },
}));

export const FeatureTabMain = styled(Tabs)(({ theme }) => ({
  margin: theme.spacing(8, 0),
}));

export const FeatureTabName = styled(Tab)(() => ({
  textAlign: "center",
  fontFamily: "DIN Pro",
  fontWeight: 900,
  fontSize: "20px",
  lineHeight: "24px",
  color: COLORS.TEXT_GREY,
  textTransform: "none",
  "@media only screen and (max-width: 991px)": {
    fontSize: "16px",
  },
}));

export const FeatureTitle = styled(Typography)(() => ({
  fontFamily: "DIN Pro",
  fontWeight: "900",
  fontSize: "32px",
  lineHeight: "196%",
  marginBottom: "27px",
  "@media only screen and (max-width: 991px)": {
    fontSize: "24px",
  },
}));

export const FeatureContent = styled(Typography)(() => ({
  fontFamily: "Nexa Bold",
  fontWeight: "700",
  fontSize: "1rem",
  lineHeight: "2.125rem",
  color: COLORS.TEXT_GREY,
  marginBottom: "50px",
}));
export const FeatureImageWrap = styled(Box)(() => ({
  textAlign: "right",
  "@media only screen and (max-width: 991px)": {
    textAlign: "center",
    marginTop: "25px",
  },
}));
export const FeatureImage = styled("img")(() => ({
  maxWidth: "100%",
  height: "auto",
}));

export const FeatureButton = styled(Button)(() => ({
  background: COLORS.SECONDARY_GREEN,
  "&:hover": {
    background: "#0a6042",
  },
}));
