import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import React from "react";
import {
  FeaturesContainer,
  FeaturesIcon,
  FeaturesImg,
  FeaturesImgWrap,
  ListText,
  TitleFeatures,
} from "./FeaturesBruno.style";

function FeaturesBruno({ sectionData }) {
  const itemListCotent = (
    <Stack rowGap={2}>
      {sectionData?.content_faetures?.map((item) => (
        <ListItem disablePadding key={item.desc}>
          <Stack direction="row" alignItems="baseline">
            <ListItemIcon sx={{ minWidth: "20px" }}>
              <FeaturesIcon src={item.img.src} />
            </ListItemIcon>
            <ListText
              primary={<Typography component="p">{item.desc}</Typography>}
            />
          </Stack>
        </ListItem>
      ))}
    </Stack>
  );

  return (
    <FeaturesContainer>
      <SectionTitle title={sectionData.title} subtitle={sectionData.subTitle} />
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" rowGap={4}>
            <Grid item md={6}>
              <Stack direction="column" pt={5}>
                <Box>
                  <List>{itemListCotent}</List>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <FeaturesImgWrap>
                <FeaturesImg src={sectionData.img.src} />
              </FeaturesImgWrap>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </FeaturesContainer>
  );
}

export default FeaturesBruno;
