import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import SectionTitle from "components/SectionTitle/sectionTitle.component";
import {
  ListText,
  ChoosePackageContainer,
  ChoosePackageImg,
  ChoosePackageImgWrap,
  TitleFeatures,
  ChoosePackageIcon,
  ChoosePackageCard,
  ChoosePackageCardTitle,
  ChoosePackageCardDesc,
} from "./ChoosePackage.style";

function ChoosePackage({ sectionData }) {
  const itemListCotent = (
    <Stack rowGap={2}>
      {sectionData?.content_package?.map((item) => (
        <ListItem disablePadding key={item}>
          <Stack direction="row" alignItems="baseline">
            <ListItemIcon sx={{ minWidth: "20px" }}>
              <ChoosePackageIcon fontSize="small" />
            </ListItemIcon>
            <ListText
              primary={
                <Typography
                  component="p"
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              }
            />
          </Stack>
        </ListItem>
      ))}
    </Stack>
  );

  const cards = sectionData?.title_package_card?.map((item, index) => (
    <Grid key={item} item xs={12} sm={6} md={4}>
      <Stack alignItems="center" sx={{ height: "100%" }}>
        <ChoosePackageCard>
          <ChoosePackageCardTitle mb={2} variant="h4">
            {item}
          </ChoosePackageCardTitle>
          <Stack direction="row" spacing={2} alignItems="baseline">
            <ChoosePackageIcon fontSize="small" />
            <ChoosePackageCardDesc variant="subtitle1">
              {sectionData.content_package_card[index]}
            </ChoosePackageCardDesc>
          </Stack>
          {/* Removed Till Updates */}
          {/* <Button
          size="small"
          variant="outlined"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          sx={{
            fontSize: "16px",
            padding: "10px 20px",
            mt: 3,
            position: "absolute",
            bottom: "35px",
          }}
        >
          Discover now
        </Button> */}
        </ChoosePackageCard>
      </Stack>
    </Grid>
  ));
  return (
    <ChoosePackageContainer>
      <SectionTitle title="Choose Your Package" />

      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" rowGap={4}>
          <Grid item md={6}>
            <Stack justifyContent="center" sx={{ height: "100%" }}>
              {sectionData.title_package && (
                <>
                  <TitleFeatures variant="subtitle1">
                    {sectionData.title_package}
                  </TitleFeatures>
                  <Box>
                    <List>{itemListCotent}</List>
                    {/* Removed Till updates */}
                    {/* <Button
                          variant="contained"
                          color="primary"
                          endIcon={<ArrowForwardIcon />}
                          sx={{
                            fontSize: "16px",
                            padding: "10px 20px",
                            mt: 3,
                          }}
                        >
                          Discover now
                        </Button> */}
                  </Box>
                </>
              )}
            </Stack>
          </Grid>
          <Grid item md={6}>
            <ChoosePackageImgWrap>
              <ChoosePackageImg src={sectionData.img.src} />
            </ChoosePackageImgWrap>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={2}
          rowGap={2}
          justifyContent="center"
          alignItems="stretch"
        >
          {cards}
        </Grid>
      </Container>
    </ChoosePackageContainer>
  );
}

export default ChoosePackage;
