import { Stack, styled, Typography, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import theme, { COLORS } from "styles/theme";

// Contact - General Components
// ------- there are Not General components

// ContactForm - First Card Components
export const ContactHeaderForm = styled(Typography)(() => ({
  fontFamily: "DIN Pro",
  fontWeight: "900",
  fontSize: "3.75rem",
  color: COLORS.PRIMARY_YELLOW,
}));

export const ContactSubHeaderForm = styled(Typography)(() => ({
  fontFamily: "Courier New",
  fontWeight: "700",
  color: COLORS.TEXT_WHITE,
  fontSize: "1.125rem",
  lineHeight: "2.3",
}));

export const ContactFormCard = styled(Stack)(({ src, theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  minHeight: "50rem",
  width: "100%",
  backgroundColor: COLORS.PRIMARY_BLUE,
  backgroundImage: `url(${src})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: "modal",
  [theme.breakpoints.up("lg")]: {
    alignItems: "center",
  },
}));

export const ContactFormInput = styled(TextField)(() => ({
  maxWidth: 500,
  fontFamily: "inherit",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",

  color: "rgba(255, 255, 255, 0.7)",

  // color: COLORS.TEXT_WHITE,
  "& .MuiTextField-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& label.Mui-focused": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.TEXT_WHITE,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.TEXT_WHITE,
    },
    "&:hover fieldset": {
      borderColor: COLORS.TEXT_WHITE,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.TEXT_WHITE,
    },
  },
  "& .MuiOutlinedInput-input": {
    color: "rgba(255, 255, 255, 0.7)",
    margin: theme.spacing(1),
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

// ContactInfoCard - Second Card Components
export const ContactHeaderInfo = styled(Typography)(() => ({
  fontFamily: "DIN Pro",
  fontWeight: "900",
  fontSize: "2rem",
  color: COLORS.SECONDARY_GREEN,
}));

export const ContactSubHeaderInfo = styled(Typography)(() => ({
  fontFamily: "Courier New",
  fontWeight: "700",
  fontSize: "1.125rem",
  color: COLORS.TEXT_BLACK,
  lineHeight: "2.3",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

// Temporary Contact InfoCard
export const ContactInfoCardLeft = styled(Box)(({ theme }) => ({
  top: "20%",
  left: "70%",
  zIndex: 1000,
  maxHeight: "30.81rem",
  width: "120%",
  maxWidth: 500,
  backgroundColor: COLORS.TEXT_WHITE,
  padding: theme.spacing(6),
  borderRadius: "1.25rem",
  boxShadow: `3rem 1.5rem 3rem rgba(24, 37, 56, 0.12)`,
  border: "1px solid #CDCDCD",
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 2),
  },
  [theme.breakpoints.up("lg")]: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: 600,
  },
}));

export const InfoContainer = styled(Box)(() => ({
  fontFamily: "Nexa Bold",
  fontWeight: "700",
  marginTop: theme.spacing(4.25),
  marginLeft: theme.spacing(1),
}));

export const InfoItem = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",

  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

export const ContactIcon = styled("img")(() => ({
  width: "2rem",
  height: "2rem",
}));

export const LogoContainer = styled(Box)(() => ({
  width: "12.8rem",
  height: "5rem",
  alignSelf: "center",
  justifySelf: "flex-end",
}));
