// MUI
import styled from "@emotion/styled";
import { Card, Typography, Box, Stack } from "@mui/material";
// shared
// theme
import { COLORS } from "styles/theme";

export const LeadershipCardWrp = styled(Box)(({ theme }) => ({
  marginBottom: "20px",
  height: "415px",
  position: "relative",
  opacity: 0,
  // "& .MuiCard-root:before": {
  //   content: '""',
  //   backgroundImage: `url(${IMAGES?.POLYGON?.src})`,
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "center",
  //   position: "absolute",
  //   backgroundSize: "contain",
  //   zIndex: "-1",
  //   width: "212.28px",
  //   height: "212.28px",
  //   top: "calc(50% - 15px)",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  // },
  [theme.breakpoints.down("md")]: {
    marginBottom: "30px",
  },
}));
export const LeadershipCardBox = styled(Card)(({ theme }) => ({
  height: "100%",
  maxWidth: "303.63px",
  background: COLORS.TEXT_WHITE,
  borderRadius: "20px",
  padding: "16px 16px",
  // boxShadow: "none",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // justifyContent: "space-between",
  // filter: "drop-shadow(48px 24px 48px rgba(24, 37, 56, 0.12))",
  boxShadow: " 0px 15px 30px rgba(3, 126, 77, 0.1)",
  [theme.breakpoints.down("md")]: {
    marginBottom: "30px",
    maxWidth: "100%",
  },
}));

export const LeadershipContentBox = styled(Stack)(() => ({
  height: "100%",
}));

export const LeadershipImage = styled("img")(() => ({
  width: "134.85px",
  height: "134.85px", // Adjust the height to match the width for a perfect circle
  borderRadius: "50%", // Add this line to apply circular cropping
  objectFit: "cover", // Ensure the image fills the circular container
}));

export const LeadershipTopBox = styled(Box)(() => ({
  margin: "20px 0  0",
}));

export const LeadershipName = styled(Typography)(() => ({
  fontFamily: "DIN Pro",
  fontSize: "22px",
  marginBottom: "8px",
  lineHeight: "30px",
  color: COLORS.TEXT_BLACK,
}));

export const LeadershipDesignation = styled(Typography)(() => ({
  fontFamily: "Courier New",
  fontSize: "18px",
  color: "#000",
  lineHeight: "158%",
  margin: "0",
  fontWeight: 900,
  marginBottom: "10px",
}));
export const LeadershipDesc = styled(Typography)(() => ({
  lineHeight: "26px",
  fontWeight: 400,
}));

export const SocialMediaList = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  gap: "11px",
  margin: "0 auto",
  marginTop: "auto",
}));

export const SocialMediaImage = styled("img")(() => ({
  backgroundColor: "#007BB5",
  width: "23.94px",
  height: "23.94px",
  borderRadius: "100px",
  objectFit: "none",
}));
