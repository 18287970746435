import { COLORS } from "styles/theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { styled, Box, Typography, ListItemText, Stack } from "@mui/material";

export const ChoosePackageContainer = styled(Box)(({ theme }) => ({
  //   backgroundColor: COLORS.PRIMARY_BLUE,
  // paddingTop: theme.spacing(2),
  // paddingBottom: theme.spacing(2),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));
export const ChoosePackageTitle = styled(Typography)(() => ({
  color: COLORS.TEXT_WHITE,
  fontSize: "3.5rem",
}));
export const ChoosePackageDesc = styled(Typography)(() => ({
  color: "#D5D5D5",
  lineHeight: "23px",
  fontSize: "1.25rem",
}));
export const TitleFeatures = styled(Typography)(() => ({
  color: COLORS.PRIMARY_BLUE,
  lineHeight: "68px",
  fontSize: "2rem",
  fontFamily: "Courier New",
}));
export const ListText = styled(ListItemText)(() => ({
  "& p": {
    fontFamily: "Nexa Bold",
    color: "#8C8C8C",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28.8px",
  },
  "& span": {
    color: COLORS.PRIMARY_BLUE,
  },
}));

export const ChoosePackageIcon = styled(FiberManualRecordIcon)(() => ({
  color: "#8C8C8C",
  fontSize: ".5rem",
}));
export const ChoosePackageImgWrap = styled(Box)(({ theme }) => ({
  margin: theme.spacing(-8, 0),
}));
export const ChoosePackageImg = styled("img")(() => ({
  width: "100%",
  maxHeight: 700,
  height: "100%",
  objectFit: "contain",
}));
export const ChoosePackageCard = styled(Stack)(({ theme }) => ({
  maxWidth: "385px",
  height: "100%",
  justifyContent: "baseline",
  padding: theme.spacing(4, 2),
  background: "#FFFFFF",
  border: "1px solid #D4D2E3",
  borderRadius: "24px",
}));
export const ChoosePackageCardTitle = styled(Typography)(() => ({
  fontWeight: 700,
}));
export const ChoosePackageCardDesc = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "1rem",
}));
