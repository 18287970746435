import { styled } from "@mui/material";
import Paper from "@mui/material/Paper";

// Home / History
export const HomeImage = styled("img")(() => ({
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "cover",
}));

// About / History

export const AboutCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(8),
}));

export const AboutBgImage = styled("img")(() => ({
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "cover",
}));
