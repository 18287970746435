// Components
import { useLocation } from "react-router-dom";
import SolutionDetail from "./solutionDetail.component";
import SolutionsPreview from "./solutionsPreview.component";

function getSubPage(fullPath) {
  const pathArray = fullPath.split("/"); // ["" , "solutions", ""] or ["", "solutions" , "brokerage", ""]
  const subPage = pathArray[pathArray.length - 2]; // solutions or brokerage
  return subPage;
}

function SolutionsContent() {
  const location = useLocation();
  const subPage = getSubPage(location.pathname);
  if (subPage !== "solutions") {
    return <SolutionDetail subPage={subPage} />;
  }

  return <SolutionsPreview />;
}

export default SolutionsContent;
