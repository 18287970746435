import {
  Box,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemText,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import { ImgItem } from "./brunoProducts.styles";

function TabPanel({ value, index, items, ...other }) {
  const { palette } = useTheme();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`bruno-tabpanel-${index}`}
      aria-labelledby={`bruno-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <ImgItem src={items.media} />
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack>
              <Box pt={5}>
                <Box>
                  {/* <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontSize: { xs: "1.1rem", md: "1.25rem" },
                                    color: palette.secondary.green,
                                    my: 2,
                                  }}
                                >
                                  No financial knowledge required. Enabling your customers to
                                  execute trades with their fingertips.
                                </Typography> */}

                  <List sx={{ pr: { md: 6 } }}>
                    {items.content.map((item, index) => (
                      <ListItem disableGutters key={index}>
                        {index !== 2 ? (
                          <ListItemText
                            primary={item}
                            primaryTypographyProps={{
                              fontSize: { xs: "1rem" },
                              // color: palette.primary.main,
                              fontFamily: "Nexa Bold",
                              fontWeight: 400,
                              color: "#101010",
                            }}
                          />
                        ) : (
                          <ListItemText
                            primary={item}
                            primaryTypographyProps={{
                              fontSize: { xs: "1rem" },
                              // color: palette.primary.main,
                              // fontFamily: "Nexa Bold",
                              fontWeight: 800,
                              color: "#101010",
                            }}
                          />
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
                {/* Removed Till Updates */}
                {/* <Button
                  sx={{
                    paddingTop: "8px",
                    paddingBottom: "7px",
                    marginTop: "10px",
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  Read More
                </Button> */}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default TabPanel;
