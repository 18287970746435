// MUI
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
// styles
import {
  TitleContainer,
  ContentContainer,
  Subtitle,
  Title,
} from "./title.styles";

function TitleComponent(props) {
  const { title, subTitle } = props;

  return (
    <TitleContainer component="section" maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <ContentContainer>
            <Title variant="h2">{title}</Title>
            <Subtitle>{subTitle}</Subtitle>
          </ContentContainer>
        </Grid>
      </Grid>
    </TitleContainer>
  );
}
TitleComponent.defaultProps = {
  title: "",
  subTitle: "",
};

TitleComponent.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
export default TitleComponent;
