import { Box, Paper, styled } from "@mui/material";
import IMAGES from "shared/images";

export const CardWrapper = styled(Box)({
  marginTop: "30px",
  "& .MuiGrid-item:last-child .MuiTypography-subtitle2": {
    lineHeight: "21px",
    fontSize: "14px",
  },
});

export const CardWrap = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: "20px 40px",
  minHeight: "308px",
});

export const Section = styled((props) => (
  <Box component="section" {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  backgroundImage: `url(${IMAGES.ARCHITECTURE_BG_SHAPE_BLUE.src})`,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
}));

export const StyledPaper = styled((props) => (
  <Paper elevation={20} {...props} />
))({
  boxShadow:
    "0px 10px 13px - 6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  height: "100%",
});
