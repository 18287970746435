import { styled, Box } from "@mui/material";

const SliderWrapper = styled(Box)(({ theme }) => ({
  marginTop: "70px",

  "& .slick-slider": {
    paddingBottom: "75px",
  },

  "& .slick-slide": {
    width: "311px",
    height: `232px !important`,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.text.white,
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: "20px",
  },

  "& .slick-track": {
    display: "flex",
    gap: "25px",
  },

  "& .slick-dots ul": {
    display: "flex",
    alignItems: "center",
  },

  "& .slick-dots ul li": {
    margin: "0 1px",
  },

  "& .slick-dots li button:before": {
    fontSize: "10px",
  },

  "& .slick-dots li.slick-active button:before": {
    color: theme.palette.primary.main,
  },
}));

export default SliderWrapper;
