import { Box, Typography, styled } from "@mui/material";
import { COLORS } from "styles/theme";

export const WhoWeServeWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));
export const WhoWeServeCards = styled(Box)(({ theme }) => ({}));
export const WhoWeServeCardsContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));
export const WhoWeServeCardsContentCard = styled(Box)(({ theme }) => ({
  position: "relative",
  maxWidth: "267px",
  maxHeight: "243px",
  background: "#FFFFFF",
  boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  marginTop: theme.spacing(2),
}));
export const WhoWeServeCardsContentCardTitle = styled(Typography)(
  ({ theme }) => ({
    position: "relative",
    fontWeight: 800,
    color: "#595959",
    marginBottom: theme.spacing(3),
    "&:after": {
      content: `""`,
      position: "absolute",
      bottom: "-8px",
      left: 0,
      width: "100%",
      height: "2px",
      background: "#000",
    },
  }),
);
export const WhoWeServeCardsContentCardDesc = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 400,
    color: COLORS.TEXT_GREY6,
    textAlign: "center",
  }),
);
export const WhoWeServeCardsContentCardImg = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "110%",
  objectFit: "contain",
}));
