import { Box, Stack, Typography, Grid } from "@mui/material";
import { COLORS } from "styles/theme";
import { StyledPaper, CardWrap } from "./architecture.styles";

function Cart({ title, description, icon }) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <StyledPaper>
        <CardWrap>
          <Stack direction="row" spacing={2.5} alignItems="center">
            <Box
              component="img"
              src={icon.src}
              alt={icon.alt}
              sx={{ objectFit: "cover", width: "70px" }}
            />

            <Typography
              variant="h4"
              sx={{
                color: COLORS.TEXT_BLACK,
                fontSize: "24px",
              }}
            >
              {title}
            </Typography>
          </Stack>

          <Typography
            variant="subtitle2"
            sx={{ pt: "15px", lineHeight: "28px" }}
          >
            {description}
          </Typography>
        </CardWrap>
      </StyledPaper>
    </Grid>
  );
}

export default Cart;
