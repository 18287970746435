import { Box, Container, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import SECTIONS from "shared/sections";
import useSlider from "hooks/useSlider";
import AppendDots from "components/AppendDots";
import { ImageWrapper } from "./projects.styles";

function Projects({ txtTitle }) {
  const { sliderRef, handleNext, handlePrevious } = useSlider();
  const {
    PROJECTS: { title, subtitle, slides },
  } = SECTIONS;

  const appendDots = (dots) => {
    return AppendDots(dots, handleNext, handlePrevious);
  };
  const MatchesDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const MatchesDownMD = useMediaQuery((theme) => theme.breakpoints.down("md"));

  let noOfSlides = 4;
  if (MatchesDownSM) {
    noOfSlides = 1;
  } else if (MatchesDownMD) {
    noOfSlides = 2;
  }
  const settings = {
    slidesToShow: noOfSlides,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    infinite: true,
    slideToScroll: false,
  };

  return (
    <Box
      component="section"
      sx={{
        marginTop: "64px",
        marginBottom: "64px",
      }}
    >
      <Container maxWidth="xl">
        <Box>
          <SectionTitle title={txtTitle} subtitle={subtitle} maxWidth="80%" />

          <Box mt={9}>
            <Slider
              {...settings}
              appendDots={appendDots}
              ref={(c) => {
                sliderRef.current = c;
              }}
            >
              {slides.map((slide, index) => {
                const key = `slide-${index}`;
                return (
                  <Box key={key} sx={{ minHeight: 300, p: 1 }}>
                    <ImageWrapper>
                      <Box
                        component="img"
                        src={slide.src}
                        alt={slide.alt}
                        sx={{
                          objectFit: "contain",
                          width: "100%",
                          maxWidth: 250,
                          maxHeight: 120,
                          height: "100%",
                          mx: "auto",
                        }}
                      />
                    </ImageWrapper>
                  </Box>
                );
              })}
            </Slider>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Projects;
