// MUI
import { Box, styled } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IMAGES from "shared/images";
// Theme
import { COLORS } from "styles/theme";

// Hero Component General Styling
export const HeroContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(14),
  position: "relative",
  [theme.breakpoints.up("lg")]: {
    paddingRight: 0,
  },
}));

export const HeroTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

// Home Page Styling
export const HomeContentContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  paddingTop: theme.spacing(8),
  alignItems: "flex-start",
}));

export const HomeContent = styled(Typography)(() => ({
  color: COLORS.TEXT_GREY,
  lineHeight: "2.125rem",
}));

export const HomeImage = styled("img")(() => ({
  maxHeight: "40rem",
  maxWidth: "50rem",
  width: "100%",
  objectFit: "cover",
}));

export const HomeBgShapeRed = styled("img")(() => ({
  position: "absolute",
  right: 0,
  top: "10%",
  maxHeight: "40rem",
  maxWidth: "40rem",
  objectFit: "contain",
  zIndex: -1,
}));

export const HomeBgShapeYellow = styled("img")(() => ({
  position: "absolute",
  top: 0,
  right: "12%",
  maxHeight: "40rem",
  maxWidth: "40rem",
  objectFit: "contain",
  zIndex: -1,
}));

// About Page Styling

export const AboutTitle = styled(HeroTitle)(({ theme }) => ({
  fontSize: "9rem",
  lineHeight: 1,
  [theme.breakpoints.down("md")]: {
    fontSize: "6rem",
  },
}));
// export const AboutSubtitle = styled(Typography)(({ theme }) => ({
//   fontSize: "2rem",
//   paddingRight: theme.spacing(8),
//   color: COLORS.PRIMARY_BLUE,
//   [theme.breakpoints.down("lg")]: {
//     paddingRight: theme.spacing(4),
//   },
//   [theme.breakpoints.down("md")]: {
//     paddingRight: 0,
//     fontSize: "1.5rem",
//   },
//   [theme.breakpoints.down("sm")]: {
//     paddingRight: 0,
//     fontSize: "1.2rem",
//   },
// }));
// export const AboutImage = styled("img")(() => ({
//   maxHeight: "50rem",
//   width: "80%",
//   objectFit: "cover",
//   objectPosition: "100% 6%",
//   position: "absolute",
//   right: 0,
//   bottom: "0%",
// }));
export const AboutWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  minHeight: "35.375rem",
  [theme.breakpoints.down("xl")]: {
    marginBottom: theme.spacing(20),
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(40),
  },
}));
export const AboutWrapperContent = styled(Box)(() => ({
  // position: "relative",
  minHeight: "35.375rem",
  display: "flex",
  alignItems: "center",
}));
export const AboutContentAbout = styled(Box)(({ theme }) => ({
  position: "absolute",
  // top: "25px",
  left: 0,
  width: "95%",
  background: "#30337E",
  borderRadius: "0px 200px 0px 0px",
  [theme.breakpoints.down("md")]: {
    borderRadius: "0px 0px 200px 0px",
    paddingTop: theme.spacing(2),

    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(3),
  },
}));
export const AboutContentAboutContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(10),
  },
}));
export const AboutContentAboutTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 800,
  color: COLORS.TEXT_WHITE,
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
}));
export const AboutContentAboutDesc = styled(Typography)(() => ({
  fontWeight: 400,
  color: COLORS.TEXT_WHITE,
}));
export const AboutContentAboutImg = styled("img")(() => ({
  maxWidth: "404px",
  maxHeight: "358px",
  width: "100%",
  height: "100%",
}));
export const AboutImgWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 0,
  zIndex: -1,
  maxWidth: "493px",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    maxHeight: "356px",
    right: "18px",
    bottom: "-200px",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "356px",
    right: "18px",
    bottom: "-200px",
  },
}));
export const AboutImg = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));
export const AboutBottomBar = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.PRIMARY_BLUE,
  marginTop: theme.spacing(3),
  width: "100%",
}));
export const AboutBottomBarContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
}));
export const AboutBottomBarContentImg = styled("img")(({ theme }) => ({
  maxWidth: "21px",
  width: "100%",
}));
export const AboutBottomBarContentDesc = styled(Typography)(({ theme }) => ({
  // maxWidth: "122px",
  fontWeight: 800,
  color: COLORS.TEXT_WHITE,
  marginLeft: theme.spacing(4),
  position: "relative",
  "&:before": {
    content: `""`,
    position: "absolute",
    left: "-17px",
    top: "50%",
    transform: `translateY(-50%)`,
    height: "40px",
    width: "2px",
    background: COLORS.TEXT_WHITE,
  },
}));
