// MUI
import { styled, Stack } from "@mui/material";
// shared

const QuestionWrap = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(8),
  gap: theme.spacing(4),
}));

export default QuestionWrap;
