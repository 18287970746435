import { Box, Container, useMediaQuery } from "@mui/material";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import Slider from "react-slick";
import SECTIONS from "shared/sections";
import useSlider from "hooks/useSlider";
import AppendDots from "components/AppendDots";
import SliderWrapper from "./awards.styles";

function Awards() {
  const { sliderRef, handleNext, handlePrevious } = useSlider();
  const {
    AWARDS: { title, subtitle, awards },
  } = SECTIONS;
  const MatchesDownMD = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const MatchesDownSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const MatchesDownXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  let noOfSlides = 4;
  if (MatchesDownXS) {
    noOfSlides = 1;
  } else if (MatchesDownSM) {
    noOfSlides = 2;
  } else if (MatchesDownMD) {
    noOfSlides = 3;
  }
  const settings = {
    slidesToShow: noOfSlides,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    swipeToSlide: true,
    autoplaySpeed: 2000,
  };
  const appendDots = (dots) => {
    return AppendDots(dots, handleNext, handlePrevious);
  };

  return (
    <Box
      component="section"
      sx={{
        marginTop: "64px",
        marginBottom: "64px",
      }}
    >
      <Container maxWidth="xl">
        <Box>
          <SectionTitle
            title={title}
            subtitle={subtitle}
            maxWidth="78%"
            fontFamily="Nexa Bold"
            gap="15px"
          />

          <SliderWrapper>
            <Slider
              {...settings}
              appendDots={appendDots}
              ref={(c) => {
                sliderRef.current = c;
              }}
            >
              {awards.map((award, index) => (
                <Box key={index}>
                  <Box component="img" src={award.src} alt={award.alt} />
                </Box>
              ))}
            </Slider>
          </SliderWrapper>
        </Box>
      </Container>
    </Box>
  );
}

export default Awards;
