import IMAGES from "./images";

const HOME_HERO = {
  title: "Bruno Core",
  subtitle: "Investech Re_Defined!",
  content: `Enables banks, brokers, wealth managers, pension firms and other financial institutions to provide their clients with a streamlined and flawless digital trading and investing experience. The platform includes features like self-trading, digital advisory, thematic baskets. Bruno can be launched as an out-of-the-box solution or with fully customizable journeys and features that give you a quick, stable & flexible entry point to the market.`,
  buttonText: "Discover",
  link: "/#discover",
  media: IMAGES.HERO_BG,
};

const FEATURES_TABS = [
  "Signup",
  "Compliance",
  "Payments",
  "Transactions",
  "Market Data",
  "Admin ERP",
];

const FEATURES_SECTION_HEADER = {
  title: "Features",
  content:
    "Bruno’s digital investment journey includes all building blocks to give your client a smooth experience and to let you run your business efficiently.",
};

const FEATURES = [
  {
    title: "No Hassle Signup",
    content:
      "Easy signup process directly on the web or mobile platform signup page or integrate with your own custom solution or any solution provider from Tradesocio’s marketplace.",
    buttonText: "Learn More",
    media: IMAGES.FEATURES_IMAGE,
  },
  {
    title: "Compliance assurance",
    content:
      "Fully digital KYC, risk assessment, and compliance verification to get the necessary details of your client and ensure that he/she complies with the required regulations with minimum hassle.",
    buttonText: "Learn More",
    media: IMAGES.FEATURES_IMAGE,
  },
  {
    title: "Clear, Easy Payments",
    content:
      "Transparent fee calculation and application, automated accounting and compliance processes, and a fully flexible reporting suite.",
    buttonText: "Learn More",
    media: IMAGES.FEATURES_IMAGE,
  },
  {
    title: "Transactions",
    content:
      "Support for banking and PSP providers to readily integrate your account with banks for smooth transactions.",
    buttonText: "Learn More",
    media: IMAGES.FEATURES_IMAGE,
  },
  {
    title: "Market Data",
    content:
      "Get reliable and up-to-date relevant data through integration with Thomson Reuters, Bloomberg, FactSet and other providers.",
    buttonText: "Learn More",
    media: IMAGES.FEATURES_IMAGE,
  },
  {
    title: "Bird’s eye view to business - ADMIN ERP",
    content:
      "Manage all business operations easily and keep full visibility on clients, transactions and reporting with Bruno’s flexible and comprehensive admin ERP.",
    buttonText: "Learn More",
    media: IMAGES.FEATURES_IMAGE,
  },
];

const DELIVERY_PLANS = {
  title: "Delivery Plans",
  subtitle:
    "Explore our capabilities and choose how you would like them to be implemented. Whether your priority is speed or attention to detail, we can deliver.",
};

const BLOG = {
  title: "Our Latest Blog and News",
  subTitle:
    "Keeping you up to date and informed with our blogs, updated weekly so you can make an informed decision",
  content: [
    {
      title: "Tradesocio Splits From Bambu To Become An Independent Entity",
      content:
        "After its merger in July 2021 with Bambu, Tradesocio has just announced that it has split from Bambu and the two firms are now independent sister companies....",
      image: IMAGES.BLOG_1,
      date: "1 Jan 2023",
      buttonText: "Read More",
      link: "/#",
    },
    {
      title:
        "Tradesocio Integrates Trulioo for Quicker and More Secured Sign-up Experience",
      content:
        "Tradesocio is delighted to announce the integration of Trulioo into the Marketplace. To fulfill our promise of delivering accessibility, security, and transparency, we partnered with Truiloo, which shares the ...",
      image: IMAGES.BLOG_2,
      date: "12 Jan 2023",
      buttonText: "Read More",
      link: "/#",
    },
    {
      title:
        "Tradesocio integrates SendGrid to Provide Customers with an Exceptional Email Experience.",
      content:
        "Tradesocio announces its integration of SendGrid into the Marketplace. In our vigorous effort to deliver our commitment to our customers, we partnered with SendGrid to achieve our mission even better...",
      image: IMAGES.BLOG_3,
      date: "20 Jan 2023",
      buttonText: "Read More",
      link: "/#",
    },
  ],
};
const BRUNO_PRODUCTS = {
  title: "Choose Your Product",
  // subtitle:
  //   "Bruno’s digital investment journey includes all building blocks to give your client a smooth experience and to let you run your business efficiently.",
  // products: [
  //   {
  //     title: "Bruno Core",
  //     description:
  //       "If speed is your thing, Bruno Core is right for you. Your clients can start trading and investing in less than 30 days with a white labeled, end-to-end integrated platform solution, including mobile app, web app and back office.",
  //     icon: IMAGES.BRUNO_CORE_ICON,
  //     link: "/products/bruno-core/#",
  //   },
  //   {
  //     title: "Bruno Bespoke",
  //     description:
  //       "Customized application end-to-end tailored to your specific requirements as a mature financial institution. Delivery includes code transfer and IP rights. Minimum engagement of two years.",
  //     icon: IMAGES.BRUNO_BESPOKE_ICON,
  //     link: "/products/bruno-bespoke/#",
  //   },
  //   {
  //     title: "Bruno API",
  //     description:
  //       "Bruno has your back if you want to connect your applications to 3rd parties smoothly and securely. Bruno API aims to save time and hassle via our library of FIX and Rest APIs.",
  //     icon: IMAGES.BRUNO_API_ICON,
  //     link: "/products/bruno-api/#",
  //   },
  //   {
  //     title: "Bruno Engine",
  //     description:
  //       "Bruno Engine provides you with a highly customizable and efficient order management system to be in full control of your business and eco system.",
  //     icon: IMAGES.BRUNO_ENGINE_ICON,
  //     link: "/products/bruno-engine/#",
  //   },
  // ],

  tabs: ["Self-Trading", "Thematic Baskets", "Digital Advisory"],

  panels: [
    {
      media: IMAGES.CHOOSE_PRODUCT_SELF.src,
      content: [
        "With our self-trading platform, your clients are in full control of their investments and can trade with ease. Our intuitive interface and powerful tools give your clients everything they need to make informed decisions and stay ahead of the market",
        "Our platform caters to savvy and independent investors and designed for experienced and active traders seeking a feature-rich platform to execute their investment strategies with precision",
      ],
    },
    {
      media: IMAGES.CHOOSE_PRODUCT_THIMATIC.src,
      content: [
        "Our thematic baskets offer a unique way to let your clients invest in their favorite themes and sectors. From renewable energy to tech and beyond, design and launch your own baskets to help your clients achieve their investment goals",
        "Our platform caters to investors and financial advisors seeking thematic investment strategies and sector exposure with ease.",
      ],
    },
    {
      media: IMAGES.CHOOSE_PRODUCT_DIGITAL.src,
      content: [
        "Let our advanced algorithms do the work for you with our digital advisory platform. With personalized recommendations based on your end clients' risk profile and investment goals, They can sit back and watch their portfolio grow. Plus, our intuitive interface and real-time reporting make it easy to track their progress.",
        "Our platform caters to novice and experienced investors alike, with a low-cost, easy-to-use digital solution that leverages advanced technology to provide customizable investment options.",
        "Choose Bruno Core for a new level of service and support.",
      ],
    },
    // [
    //   "- Open to all levels of investors with fractional shares investing and trading - allowing investments as low as USD 1.",
    //   "- Multi asset capability: offer trading in equities, ETFs, indices, futures, FX and crypto",
    //   "- Easy onboarding process with digital KYC procedures.",
    //   "- Visual fundamental reports in easy-to-read infographics helps investors to make better informed decisions.",
    //   "- Transparent fee calculation, automated accounting, and a fully flexible reporting suite.",
    //   "- Secure hosted SaaS for web and mobile, allowing instant and safe deposit and withdrawal.",
    // ],
    // [
    //   "- Allows users to spot a theme according to their preferences. ",
    //   "- Analyzes the theme, its current status, progress, future, and targets.",
    //   "- Identify the stocks (companies) that would be benefitted from the selected theme Conduct fundamental, technical, and quantitative analyses for each of the company stocks.",
    //   "- Enables investors to invest in Equities directly, Exchanged Traded Funds (ETFs), and Mutual Funds. ",
    // ],
    // [
    //   "- Utilizes AI Algorithm that analyzes customers' assets, liabilities, risk modeling, and objectives.",
    //   "- Offers cost-effective precious advice for low-net-worth clients End-to-end wealth solutions from reputable financial institutions.",
    //   "- Designed for passive investors with an easy-to-use interface and real-time NAV. ",
    //   "- Allows investment in multiple goal-based strategies with control and rebalance across multiple assets.",
    // ],
  ],
};
// choose your package
const CHOOSE_PACKAGE = {
  title: "Choose Your Package",
  title_package: "Bruno Core",
  title_package_card: ["Bruno API", "Bruno Engine", "Bruno Bespoke"],
  content_package_card: [
    "Offers a reliable solution to seamlessly and securely integrate your applications with third-party services. With a vast library of FIX and REST APIs.And...",
    "Offers a powerful and customizable order management system that empowers you to take complete control of your business and ecosystem.For more info, please contact us.",
    "Provides a fully customized and scalable platform to meet the unique needs of your mature financial institution.",
  ],
  img: IMAGES.CHOOSE_PACKAGE_IMG,
  content_package: [
    "Bruno Core supports one or more of Tradesocio's digital investment journeys:<span> self-trading</span>,<span> digital advisory</span> and <span> thematic baskets</span>.",
    "Bruno Core is an integrated, white-labeled platform that includes a mobile apps for IOS and Android, web app, and back-office.",
    "Soft launch program enables going live in just 30 days.",
    "Features include custody readiness, payment readiness, Azure cloud hosting, execution readiness, identity validation, custom branding, and market data readiness.",
  ],
};
// features
const FEATURESBRUNO_DATA = {
  title: "Features",
  img: IMAGES.FEATURES_BRUNO,
  subTitle:
    "Tradesocio's digital investment journey offers a complete suite of features for a seamless trading and investing experience for your clients",
  content_faetures: [
    {
      img: IMAGES.FEATURES_ICON_1,
      desc: "User-friendly si gn-up process with integrated compliance checks for easy onboarding.",
    },
    {
      img: IMAGES.FEATURES_ICON_2,
      desc: "Secure payment processing and real-time transaction monitoring for smooth and secure investment processing.",
    },
    {
      img: IMAGES.FEATURES_ICON_3,
      desc: "Real-time market data for informed investment decisions.",
    },
    {
      img: IMAGES.FEATURES_ICON_4,
      desc: "Admin ERP feature streamlines business operations for a comprehensive overview of business performance.",
    },
  ],
};
// Hiring section
const HIRING = {
  title: "Join TradeSocio!",
  contetentTitle: "Discover our exciting career opportunities.",
  cards: [
    {
      title: "Sr. Node.Js Developer",
      content:
        "Join us as a Senior Node.js Developer and propel our fintech innovations forward. You'll be pivotal in crafting robust, scalable backend solutions that drive the financial technologies of tomorrow.",
      location: "Cairo / Noida",
      popUpContent: {
        title: "Senior Node.js Developer",
        yearsOfExperince: "3 - 6 years.",
        media: IMAGES.HIRING_MODAL_ICON,
        JobDescription: [
          "Develop and optimize server-side applications using Node.js, Express, and MongoDB.",
          "Collaborate with cross-functional teams to define, design, and ship new features.",
          "Ensure the performance, quality, and responsiveness of applications.",
          "Mentor junior developers, providing guidance and support through code reviews and best practices.",
        ],
        requirements: [
          "Expertise in JavaScript, Node.js, Express, and MongoDB.",
          "Proven track record with Azure and building scalable solutions.",
          "Strong understanding of the full mobile and web development life cycle.",
          "In-depth knowledge of data structures, algorithms, and system design.",
          "Hands-on experience in developing APIs and backend services.",
          "Ability to write clean, maintainable code, and a passion for automated testing.",
          "Familiarity with continuous integration and deployment (CI/CD) practices.",
          "A visionary in technology with a relentless drive to innovate.",
        ],
      },
    },
    {
      title: "C++ Developer",
      content:
        "We seek an experienced C++ Developer for our tech team, focusing on enhancing our Order Routing and Management System. Apply your expertise in high-frequency data, FIX protocols, Kafka, and Azure Event Hubs.",
      location: "Noida / Singapore",
      popUpContent: {
        title: "Senior C++ Developer - Financial Systems",
        yearsOfExperince: "5 - 8 years.",
        media: IMAGES.HIRING_MODAL_ICON,
        JobDescription: [
          "Design, develop, and maintain high-performance C++ applications for financial market data processing and order management.",
          "Integrate and manage live data feeds using the FIX protocol to ensure accurate and timely data retrieval.",
          "Develop scalable message broadcasting systems to distribute large volumes of market data internally and externally using Kafka and/or Azure Event Hubs.",
          "Collaborate with cross-functional teams, including network engineers, data scientists, and product managers, to enhance system functionality and meet business needs.",
          "Implement robust testing and debugging practices to ensure the reliability and performance of the system.",
          "Document development processes, code changes, and other technical information for ongoing software maintenance and upgrades.",
          "Keep abreast of new technology trends and best practices in financial software development.",
        ],
        requirements: [
          "Bachelor’s or Master’s degree in Computer Science, Engineering, or a related field",
          "Strong knowledge of the FIX protocol and experience with financial market data systems.",
          "Proven experience in designing and building scalable, high-throughput systems that handle live data feeds.",
          "Proficient with message-oriented middleware technologies such as Apache Kafka and Azure Event Hubs.",
          "amiliarity with software development best practices and the full software development life cycle (SDLC).",
          "Excellent problem-solving skills and ability to work under tight deadlines.",
          "Strong communication and teamwork skills, with a proven record of working in fast-paced environments.",
        ],
      },
    },
    {
      title: "Sr. React Native Developer",
      content:
        "We are on the lookout for a Sr.React Native Developer to join our mobile development team. Your role will focus on developing and refining mobile solutions that redefine user experiences in fintech.",
      location: "Cairo / Noida",
      popUpContent: {
        title: "Senior React Native Developer",
        yearsOfExperince: "5+ years.",
        media: IMAGES.HIRING_MODAL_ICON,
        JobDescription: [
          "Develop and maintain high-quality React Native applications.",
          "Integrate third-party APIs and data sources into mobile applications.",
          "Write well-documented, clean JavaScript code.",
          "Work with native modules as required.",
        ],
        requirements: [
          "Extensive experience in building mobile applications using React Native.",
          "Familiarity with state/data management libraries (Redux, MobX).",
          "Experience with automated testing suites, like Jest or Mocha.",
          "Understanding of REST APIs, the document request model, and offline storage.",
          "Proven track record of releasing apps to the App Store and Google Play.",
        ],
      },
    },
    {
      title: "Sr. Angular Developer",
      content:
        "Seeking a Senior Angular Developer to lead development on cutting-edge financial platforms. Your expertise will enhance our capabilities in delivering high-quality, user-centric solutions.",
      location: "Cairo / Noida",
      popUpContent: {
        title: "Senior Angular Developer",
        yearsOfExperince: "5+ years.",
        media: IMAGES.HIRING_MODAL_ICON,
        JobDescription: [
          "Design and develop high-quality, performant features using Angular.",
          "Ensure the technical feasibility of UI/UX designs.",
          "Optimize applications for maximum speed and scalability.",
          "Collaborate with team members and stakeholders.",
        ],
        requirements: [
          "Strong expertise in Angular frameworks.",
          "Proficient understanding of web markup, including HTML5 and CSS3.",
          "Good understanding of TypeScript, its quirks, and workarounds.",
          "Experience with asynchronous request handling, partial page updates, and AJAX.",
          "Familiarity with front-end build tools, such as Grunt and Gulp.js.",
          "Proficient understanding of cross-browser compatibility issues and ways to work around them.",
          "Good understanding of SEO principles and ensuring that the application will adhere to them.",
          "Ability to perform well in a fast-paced environment.",
          "Excellent analytical and multitasking skills.",
        ],
      },
    },
    {
      title: "React TypeScript Developer",
      content:
        "Join the forefront of fintech innovation as a React TypeScript Developer. You'll be instrumental in building cutting-edge, scalable web applications that redefine financial services for the digital age.",
      location: "Cairo",
      popUpContent: {
        title: "React TypeScript Developer",
        yearsOfExperince: "5+ years.",
        media: IMAGES.HIRING_MODAL_ICON,
        JobDescription: [
          "Develop and enhance web applications using React with TypeScript, ensuring code quality and maintainability.",
          "Leverage modern libraries and frameworks like Material-UI, React-Query, Redux, MobX, and Capacitor to build responsive and intuitive user interfaces.",
          "Implement end-to-end automated testing and deployment pipelines using tools like Cypress, ensuring rapid and reliable delivery cycles.",
          "Collaborate with cross-functional teams to integrate cloud solutions, focusing on AWS and Azure platforms to optimize application performance and scalability.",
        ],
        requirements: [
          "Proven experience in React development with a strong grasp of TypeScript.",
          "Expertise in using key libraries and frameworks such as Material-UI, React-Query, Redux, MobX, and Capacitor.",
          "Strong experience with cloud services (AWS or Azure) and understanding of cloud architecture.",
          "Proficient in implementing CI/CD pipelines, automated testing, and release processes.",
          "Ability to work in a fast-paced, agile environment, with a passion for innovation and a drive to create exceptional user experiences.",
          "Excellent problem-solving skills and ability to think strategically about technology and business challenges.",
        ],
      },
    },
    // Full Stack Developer - MERN
    {
      title: "Full Stack Developer - MERN",
      content:
        "Join our dynamic team as a Mid-Level Full Stack Developer focusing on the MERN stack. This role calls for developing comprehensive solutions in a collaborative, fintech-focused environment.",
      location: "Cairo",
      popUpContent: {
        title: "Mid-Level Full Stack Developer (MERN)",
        yearsOfExperince: "3 - 5 years.",
        media: IMAGES.HIRING_MODAL_ICON,
        JobDescription: [
          "Develop full-stack web applications using MongoDB, Express, React, and Node.js.",
          "Build reusable code and libraries for future use.",
          "Ensure the technical feasibility of UI/UX designs.",
          "Implement robust set of services and APIs to power the web application.",
        ],
        requirements: [
          "Solid experience with the MERN stack.",
          "Strong understanding of JavaScript, including ES6+ syntax.",
          "Experience with React.js and popular state management libraries (Redux, Context).",
          "Knowledge of Node.js and frameworks available for it, such as Express.",
          "Understanding of MongoDB, its indexing and aggregation framework.",
          "Proficient understanding of code versioning tools, such as Git.",
          "Experience with cloud platforms like Azure is a plus.",
        ],
      },
    },

    {
      title: "Business Developer",
      content:
        "Exciting opportunity for a Business Developer specializing in Fintech SaaS. Propel our business growth through strategic partnerships and innovative business solutions.",
      location: "Cairo / Dubai",
      popUpContent: {
        title: "Fintech SaaS Business Developer",
        yearsOfExperince: "4+ years.",
        media: IMAGES.HIRING_MODAL_ICON,
        JobDescription: [
          "Identify new business opportunities and manage relationships with clients and partners.",
          "Develop strategies to increase market penetration and revenue growth.",
          "Work closely with product and marketing teams to align strategies with market needs.",
          "Lead negotiations and close agreements with high-profile clients.",
        ],
        requirements: [
          "Proven experience in business development, preferably in the fintech sector.",
          "Strong understanding of SaaS business models and digital transformation.",
          "Excellent negotiation and presentation skills.",
          "Ability to drive and manage complex sales cycles.",
          "Strong analytical skills to identify trends and challenges.",
          "Experience with CRM software and KPI reporting.",
        ],
      },
    },
    {
      title: "DevOps Engineer",
      content:
        "Join our leading fintech firm as a DevOps Engineer, where you will drive innovation through robust deployment, automation, and cloud infrastructure management.",
      location: "Cairo / Noida / Singapore",
      popUpContent: {
        title: "DevOps Engineer",
        yearsOfExperince: "4+ years.",
        media: IMAGES.HIRING_MODAL_ICON,
        JobDescription: [
          "Design and implement efficient CI/CD pipelines for seamless code integration and deployment.",
          "Optimize cloud infrastructure with a focus on Azure and AWS, ensuring scalability and security.",
          "Stay at the forefront of DevOps practices with containerization using Docker and orchestration with Kubernetes.",
          "Collaborate closely with development teams to ensure a development-first approach that integrates security and best practices from the project's onset.",
        ],
        requirements: [
          "Minimum of 4 years' experience in a DevOps role with strong expertise in Azure; AWS knowledge is a plus.",
          "Certified in Azure with a solid understanding of cloud architectures and services.",
          "Proficient with Docker, Kubernetes, and other containerization technologies.",
          "Experienced in building and maintaining CI/CD pipelines and automated testing frameworks.",
          "Adept at cloud and system optimization for high availability and disaster recovery scenarios.",
          "Strong planning skills and ability to manage deployment schedules effectively.",
          "Keen on staying updated with the latest trends and technologies in DevOps and cloud computing.",
        ],
      },
    },

    // {
    //   title: "Solution Architect",
    //   content:
    //     "We are looking for solution architect for working closely with clients to understand their requirements and being the technical representative to them as well as to internal teams.",
    //   popUpContent: {
    //     title: "Solution Architect",
    //     yearsOfExperince: " 6 - 10 years.",
    //     media: IMAGES.HIRING_MODAL_ICON,

    //     JobDescription: [
    //       "Working closely with clients to understand their requirements and being the technical representative to them as well as to internal teams (sales, pre-sales and project teams).",

    //       "Provide solutions that are feasible for the project teams to deliver while still ensuring that client's needs are met.",

    //       "PCollaborate with the technology team to architect, design, build, and implement application integrations.",

    //       "Assisting with integration and acceptance testing and supporting internal teams with technical queries",
    //       "Building quick prototypes to validate 3rd party tools or client external systems.",
    //       "Strong familiarity with agile software development practices and tools.",
    //       "Overlooking deployment deliverables and monitoring the technical checklist",
    //       "Strong organizational skills and ability to perform multiple tasks simultaneously, set priorities, and work in a team with diverse cultural backgrounds.",
    //       "This role entails wearing multiple hats depending on the demand of the project/teams.",
    //       "Tell us how to get things done better.",
    //       "Love what you do!",
    //     ],
    //     requirements: [
    //       "Extensive knowledge of integration programs (SOAP API, REST API, SFTP), System Architecture frameworks, networking, database schema, CI/CD and security standards is a MUST.",
    //       "System Integrations with feasibility analysis must be a strong suite.",
    //       "Excellent interpersonal and communication skills - written and verbal",
    //       "Adaptable to different types of clients, systems and have enough experience to conceptualize new solutions.",
    //       "Hands on experience with Web Apps and Mobile Apps development is a definite plus",
    //       "Experience working with Core Banking, payment or brokerage systems preferred.",
    //       "You should be a creative thinker who can provide alternatives to existing or proposed solutions.",
    //       "You should also be proactive, dynamic and have the desire to make a difference, coupled with strong business and technical knowledge.",
    //       "Know-how about retail brokerage, including standard practices (Trading, Onboarding, Reporting) or banking & finance systems is a definite plus.",
    //       "Good understanding of Finance will be a plus.",
    //       "Having worked with Azure in depth will be a plus.",
    //       "When needed will be able to be the hands-on developer and help the team.",
    //     ],
    //   },
    // },
  ],
};

const ARCHITECTURE = {
  title: "Architecture",
  features: [
    {
      title: "Security",
      description:
        "Security monitoring and threat detection provided by Qualys & Azure to support all infrastructure and apps.",
      icon: IMAGES.SECURITY_ICON,
    },
    {
      title: "API Ready",
      description:
        "Bruno is easily integrated with your existing solutions and 3rd party providers via our extensive library of APIs.",
      icon: IMAGES.API_READY_ICON,
    },
    {
      title: "Multi-Asset",
      description:
        "No limitations on your product offering: Bruno supports multi-asset trading in equities, ETFs, mutual funds, cryptos, futures, Forex & fixed income.",
      icon: IMAGES.MULTI_ASSET_ICON,
    },
    {
      title: "Technology Stack",
      description:
        "Utilizing a unified code base, we leverage React, Node.js, Python, MongoDB, and Postgres for seamless web and mobile app development.",
      icon: IMAGES.TECHNOLOGY_STACK_ICON,
    },
    {
      title: "Cloud Hosting",
      description:
        "Cloud Hosting via Azure or AWS ensures security, availability, and scalability.",
      icon: IMAGES.CLOUD_HOSTING_ICON,
    },
    {
      title: "Marketplace",
      description:
        "We have created a centralized repository for all of the investment products, liquidity providers, trading platforms, and auxiliary technical solutions that financial institutions may ever need to assist these institutions in increasing the lifetime value of their clients.",
      icon: IMAGES.MARKETPLACE_ICON,
    },
  ],
};

const QUESTION = {
  title: "FAQs",
  subTitle: "Looking for answers? Browse our FAQs to gain more insight.",

  content: [
    {
      question: "What are our Hosting capabilities?",
      answer:
        "We are a supported Azure partner and primarily deploy on Azure, but our solutions are cloud-agnostic, running on Kubernetes for flexibility and cost-effectiveness.",
    },
    {
      question: "Does Bruno offer Integration with API Based Brokers?",
      answer:
        "Bruno integrates with API-based brokers like Drive Wealth, Apex Fintech Solutions, and Alpaca, providing a customizable journey for Custody, Clearing, and brokerage applications.",
    },
    {
      question: "What asset classes are tradable?",
      answer:
        "We support a wide range of asset classes, including Equities, ETFs, Futures, Indices, Foreign Exchange, and Crypto Markets. Stocks can be traded as Cash, Portfolio Margin, or CFDs.",
    },
    {
      question: "Can we customize Bruno?",
      answer:
        "Yes, Bruno is fully customizable end-to-end, accommodating user experience enhancements, branding updates, and new modules and components as per customer requests.",
    },
    {
      question:
        "Can we migrate from current ORMS to a different provider in the future?",
      answer:
        "Absolutely. Our front-end components are built on custom API endpoints, allowing for smooth migration between different ORMS, ensuring flexibility and compatibility.",
    },
    {
      question: "Do we support APIs?",
      answer:
        "Yes, our APIs support firms building their own tech teams or front-end solutions. Built on a serverless cloud-based architecture, our APIs ensure scalability, security, and seamless fintech application experiences.",
    },
    {
      question: "How does Bruno ensure data security and privacy?",
      answer:
        "Bruno prioritizes data security and privacy by implementing industry-standard encryption protocols, regular security audits, and compliance with regulatory frameworks like GDPR and SOC 2.",
    },
    {
      question: "What kind of support can we expect from TradeSocio?",
      answer:
        "TradeSocio provides comprehensive support through a dedicated customer success team, 24/7 technical assistance, and regular updates and maintenance.",
    },
    {
      question:
        "How does Bruno handle high-frequency trading and real-time data processing?",
      answer:
        "Bruno is equipped with high-performance computing capabilities and low-latency data processing to support high-frequency trading and real-time analytics.",
    },
    {
      question: "Can Bruno integrate with third-party tools and platforms?",
      answer:
        "Yes, Bruno offers flexible integration capabilities with third-party tools and platforms, including CRM systems, analytics tools, and payment gateways.",
    },
    {
      question:
        "How customizable are the reporting and analytics features in Bruno?",
      answer:
        "Bruno's reporting and analytics features are highly customizable, allowing you to generate tailored reports and insights based on your specific requirements.",
    },
    {
      question: "What is the typical implementation timeline for Bruno?",
      answer:
        "The implementation timeline varies based on customization and integration requirements, typically ranging from a few weeks to several months.",
    },
    {
      question: "How does Bruno support multi-asset trading platforms?",
      answer:
        "Bruno supports multi-asset trading platforms by providing a unified interface, seamless integration with market data feeds, and advanced order management capabilities.",
    },
    {
      question:
        "What measures does TradeSocio take to ensure platform scalability?",
      answer:
        "TradeSocio ensures platform scalability through cloud-native architecture, load balancing, and auto-scaling capabilities.",
    },
    {
      question: "Can Bruno be deployed in a multi-tenant environment?",
      answer:
        "Yes, Bruno can be deployed in a multi-tenant environment, offering separate and secure instances for each client.",
    },
    {
      question:
        "How does Bruno facilitate regulatory compliance for financial institutions?",
      answer:
        "Bruno incorporates compliance checks, audit trails, and reporting features that align with financial regulations, ensuring adherence to industry standards.",
    },
  ],
};

const LEADERSHIP = {
  title: "Leadership",
  subTitle:
    "Throughout its corporate journey, Tradeosico has been fortunate to have forward-thinking, technically proficient, and customer-service-oriented individuals devoted to meeting the requirements specified by their customers.",
  socialImage: {
    facebook: IMAGES.FACEBOOK,
    instagram: IMAGES.INSTAGRAM,
    pinterest: IMAGES.PINTEREST,
    linkdin: IMAGES.LINKDIN,
  },
  content: [
    {
      name: "Eric Koh",
      designation: "Chairman",
      desc: "Provides strategic guidance and industry expertise as the Chairman of Tradesocio. ",
      image: IMAGES.LEADER1,
      facebook: "",
      instagram: "",
      pinterest: "",
      linkedin: "https://www.linkedin.com/in/erickohsg/",
    },
    {
      name: "Wael Salem",
      designation: "CEO",
      desc: "Visionary leader spearheading Tradesocio's mission to empower financial institutions.",
      image: IMAGES.LEADER2,
      facebook: "",
      instagram: "",
      pinterest: "",
      linkedin: "https://www.linkedin.com/in/waelsalem/",
    },
    {
      name: "Bhaskar Roy",
      designation: "CTO",
      desc: "Drives technological advancements and product development to keep Tradesocio at the forefront of innovation.",
      image: IMAGES.LEADER3,
      facebook: "",
      instagram: "",
      pinterest: "",
      linkedin: "https://www.linkedin.com/in/bhaskarrai/",
    },

    {
      name: "Dennis Yeo",
      designation: "CPO",
      desc: "Shapes TradeSocio's product vision, combining technical expertise with strategic planning to drive innovation and growth.",
      image: IMAGES.LEADER4,
      facebook: "",
      instagram: "",
      pinterest: "",
      linkedin: "https://www.linkedin.com/in/dennis-yeo-7186762b/",
    },
  ],
};

const PROJECTS = {
  title: "Projects",
  subtitle:
    "Tradesocio is proud to engage in some of the most exciting and challenging projects serving the most prominent market-leading companies from around the globe. Some of our current engagements include designing & implementing these projects.",
  slides: [
    IMAGES.PROJECTS_1,
    IMAGES.PROJECTS_2,
    IMAGES.PROJECTS_3,
    IMAGES.PROJECTS_4,
    IMAGES.PROJECTS_5,
    IMAGES.PROJECTS_6,
    IMAGES.PROJECTS_7,
    IMAGES.PROJECTS_8,
    IMAGES.PROJECTS_9,
    IMAGES.PROJECTS_10,
    // IMAGES.PROJECTS_INVESTEC,
    // IMAGES.PROJECTS_APEX_FINTECH,
    // IMAGES.FRANKLIN_TEMPLETON_AWARDS,
    // IMAGES.PROJECTS_GO_MARKETS,
  ],
};

const MARKETPLACE = {
  title: "Choose your Integration",
  subtitle:
    "Tradesocio's marketplace centralizes investment solutions. Ready-made integrations speed up app launch process. Integrated gateways allow scalable apps and offer fundamental analysis for sound investment decisions.",
  categories: [
    "Custody & Brokerage",
    "Aggregators & OMS",
    "Market Data",
    "others",
    // "Market Data Providers",
    // "Payments & Wealth Aggregation",
    // "Architecture",
    // "Identity Validation",
    // "Engagement",
  ],
  brands: [
    // IMAGES.ALPACA_IMAGE,
    // IMAGES.APEX_FIN_IMAGE,
    // IMAGES.DRIVE_WEALTH_IMAGE,
    // IMAGES.INTERACTIVE_BROKERS_IMAGE,
    // IMAGES.SAXO_BE_INVESTED_IMAGE,
    // IMAGES.FX_CUBIC_IMAGE,
    // IMAGES.GOLD_I_IMAGE,
    // IMAGES.ONE_ZERO_IMAGE,
    // IMAGES.PRIME_XM_IMAGE,
    // IMAGES.ARK_DEV_IMAGE,
    // IMAGES.BLOOM_BERG_IMAGE,
    // IMAGES.FACT_SET_IMAGE,
    // IMAGES.THOMSON_REUTERS_IMAGE,
    // IMAGES.PLAID_IMAGE,
    // IMAGES.AWS_IMAGE,
    // IMAGES.DATA_DOG_IMAGE,
    // IMAGES.PARTNER_IMAGE,
    // IMAGES.QUALYS_IMAGE,
    // IMAGES.JUMIO_IMAGE,
    // IMAGES.TRULIOO_IMAGE,
    // IMAGES.INTERCOM_IMAGE,
    // IMAGES.TWILIO_IMAGE,
    [
      IMAGES.MARKET_BROKERAGE_1,
      IMAGES.MARKET_BROKERAGE_2,
      IMAGES.MARKET_BROKERAGE_3,
      IMAGES.MARKET_BROKERAGE_4,
      IMAGES.MARKET_BROKERAGE_5,
    ],
    [
      IMAGES.MARKET_DMS_1,
      IMAGES.MARKET_DMS_2,
      IMAGES.MARKET_DMS_3,
      IMAGES.MARKET_DMS_4,
      IMAGES.MARKET_DMS_5,
    ],
    [IMAGES.MARKET_MARKET_1, IMAGES.MARKET_MARKET_2, IMAGES.MARKET_MARKET_3],
    [
      IMAGES.MARKET_OTHER_1,
      IMAGES.MARKET_OTHER_2,
      IMAGES.MARKET_OTHER_3,
      IMAGES.MARKET_OTHER_4,
      IMAGES.MARKET_OTHER_5,
    ],

    // [
    //   IMAGES.ALPACA_IMAGE,
    //   IMAGES.APEX_FIN_IMAGE,
    //   IMAGES.DRIVE_WEALTH_IMAGE,
    //   IMAGES.INTERACTIVE_BROKERS_IMAGE,
    //   IMAGES.SAXO_BE_INVESTED_IMAGE,
    // ],
    // [
    //   IMAGES.FX_CUBIC_IMAGE,
    //   IMAGES.GOLD_I_IMAGE,
    //   IMAGES.ONE_ZERO_IMAGE,
    //   IMAGES.PRIME_XM_IMAGE,
    // ],
    // [IMAGES.ARK_DEV_IMAGE, IMAGES.BLOOM_BERG_IMAGE],
    // [
    //   IMAGES.BLOOM_BERG_IMAGE,
    //   IMAGES.FACT_SET_IMAGE,
    //   IMAGES.THOMSON_REUTERS_IMAGE,
    // ],
    // [IMAGES.PLAID_IMAGE],
    // [
    //   IMAGES.AWS_IMAGE,
    //   IMAGES.DATA_DOG_IMAGE,
    //   IMAGES.PARTNER_IMAGE,
    //   IMAGES.QUALYS_IMAGE,
    // ],
    // [IMAGES.JUMIO_IMAGE, IMAGES.TRULIOO_IMAGE],
    // [IMAGES.INTERCOM_IMAGE, IMAGES.TWILIO_IMAGE],
  ],
};

const AWARDS = {
  title: "Awards",
  subtitle:
    "Recognized for innovation and excellence, TradeSocio's meticulous approach to project and product deliveries has earned widespread accolades. Our dedicated focus on advancing financial technologies ensures superior quality and customer satisfaction, underscoring our commitment to leading the industry in effective and innovative solutions.",
  awards: [
    IMAGES.GLOBAL_FOREX_AWARDS,
    IMAGES.GLOBAL_FOREX_B2B_AWARDS,
    IMAGES.MEA_FINANCE_AWARDS,
    IMAGES.CLEAR_VIEW_FINANCIAL_AWARDS,
    IMAGES.WEALTH_TECH_100_AWARDS,
    IMAGES.FRANKLIN_TEMPLETON_AWARDS,
  ],
};
const HOME_HISTORY = {
  title: "Our Short History",
  subtitle:
    "EST. 2015 with the idea to revolutionize the financial industry, Tradesocio is a leading global SAAS provider of Trading and Wealth Management technology for banks and financial institutions. Headquartered in Singapore, we are at the forefront of innovation, powering the future of Fintech and Wealth Tech.",
  content: [
    `With its highly specialized and appealing approach toward digital transformation and tailored solutions, Tradesocio takes pride in having more than 400,000 end users with a total AUM exceeding USD 10+ Billion.`,
    `Tradesocio aids its corporate clients add additional functionality with the help of its backend APIs for Transactions, Calculators, Risk Profiling, and Portfolio Builder.`,
    `We specialize in designing end-to-end Fintech applications with APIs customized for Brokerage, Wealth Management, Discretionary, and Pension Management segments with two major categories in view: General public and corporate clients. By adapting our core product range to meet your specific needs, Tradesocio can provide comprehensive services in designing and implementing Investment management APIs and customized UI experiences in these areas to make your digital investment experience exactly fit your requirements.`,
  ],

  media: IMAGES.HOME_HISTORY_BG,
};

const ABOUT_HISTORY = {
  title: "Our Short History",

  content: [
    `With its highly specialized and appealing approach toward digital transformation and tailored solutions, Tradesocio takes pride in having more than 400,000 end users with a total AUM exceeding USD 10+ Billion.
Tradesocio aids its corporate clients add additional functionality with the help of its backend APIs for Transactions, Calculators, Risk Profiling, and Portfolio Builder.`,
    `We specialize in designing end-to-end Fintech applications with APIs customized for Brokerage, Wealth Management, Discretionary, and Pension Management segments with two major categories in view: General public and corporate clients. By adapting our core product range to meet your specific needs, Tradesocio can provide comprehensive services in designing and implementing Investment management APIs and customized UI experiences in these areas to make your digital investment experience exactly fit your requirements.`,
  ],

  media: IMAGES.ABOUT_HISTORY,
};

const CALL_ACTION = {
  title: "Let’s start a conversation",
  subTitle:
    "Let’s work together to reach your goals. Please provide us with your details and a representative will be in touch.",
};
const ABOUT_HERO = {
  title:
    "Welcome to Tradesocio – Fintech Pioneers Powering a Brighter Financial Future.",
  subtitle: `Established in 2015, Tradesocio is a leading Fintech enabler, driving the digital transformation of global Investment Banks, Brokerages, Advisors, and Pensions. From our Singaporean stronghold, we're consistently influencing the global Fintech industry through strategic market introductions and steadfast efficiency.
Our robust technology empowers over 400,000 end users, managing an impressive portfolio exceeding USD 10+ Billion. Tradesocio's innovative tools enable financial institutions to break into new markets, fostering financial inclusion and bridging gaps for the unbanked populace.`,
  secondSubtitle:
    "Experience the Tradesocio difference – Your steadfast technology partner, committed to reshaping the financial journey, one transaction at a time.”",
  cards: [
    {
      img: IMAGES.ABOUT_US_ICON_1,
      desc: "Headquartered in Singapore",
    },
    {
      img: IMAGES.ABOUT_US_ICON_2,
      desc: "Offices: Dubai, India, Malaysia and Egypt",
    },
    {
      img: IMAGES.ABOUT_US_ICON_3,
      desc: "Team: 100+ members from 20+ nations",
    },
    {
      img: IMAGES.ABOUT_US_ICON_4,
      desc: "Achievements: 50+ completed 400K+ live customers Managing $10+ billion AUM",
    },
  ],
  media: IMAGES.ABOUT_US_HERO,
  media_2: IMAGES.ABOUT_US_HERO_2,
  bg: IMAGES.ABOUT_US_BG,
};

// About Who We Serve
const ABOUT_SERVE = {
  title: "Who we serve ?",
  subTitle:
    "At Tradesocio, we cater to the financial sector, offering tailored fintech solutions to Banks, Brokerage Houses, Advisors, and Pensions Management firms. Our expertise and advanced technology empower these industries to streamline operations, enhance customer experiences, and gain a competitive edge.",
  cards: [
    {
      title: "Banks",
      desc: "Advanced investment and wealth management services through customizable APIs for optimized processes and regulatory compliance.",
      img: IMAGES.WHO_WE_SERVE_1,
    },
    {
      title: "Brokerages",
      desc: "End-to-end solutions to enhance trading capabilities, improve order execution, and deliver seamless trading experiences.",
      img: IMAGES.WHO_WE_SERVE_2,
    },
    {
      title: "Advisors",
      desc: "Comprehensive tools for streamlined advisory processes, from client onboarding to portfolio management and reporting.",
      img: IMAGES.WHO_WE_SERVE_3,
    },
    {
      title: "Pensions",
      desc: "Simplified pension administration, automated processes, and regulatory compliance for better retirement solutions.",
      img: IMAGES.WHO_WE_SERVE_4,
    },
  ],
};
const PRODUCTS_HERO = {
  title: "Our Products",
  subtitle: "Investech Re_Defined!",
  media: IMAGES.PRODUCTS_HERO_BG,
};

const PRODUCTS_WELCOME = {
  title: "Welcome to The Bruno Product Suite by",
  subtitle: `a powerful set of solutions designed to help financial institutions and fintech companies enhance their digital offerings and improve the   overall customer experience. Our products include Bruno Core, Bruno API, Bruno Bespoke, and Bruno Engine, each with its own unique set of features and capabilities to suit the specific needs of our clients.`,
  media: IMAGES.PRODUCTS_WELCOME_BG,
};

// features
const FEATURES_SELF_TRADING_HEADER = {
  title: "Self-Trading",
  content:
    "Our Self-Trading platform is designed to provide investors with the tools and capabilities they need to take control of their investments. With our platform, you can easily manage and track your trades, access advanced trading features, and customize your experience to suit your needs. Whether you're a novice or an expert trader, our self-trading feature has something for everyone. Bruno supports a wide range of asset classes, including stocks, ETFs, Forex, Future and Crypto. This means that you can trade the assets that you're most interested in and that align with your investment strategy.",
  img: IMAGES.SELF_TRADING_HEADER,
};
const FEATURES_SECTION_FEATURE = {
  mainTitle: "Features",

  title: ["Customization and Branding", "OMS Supported", "Ready Integrations"],
  desc: [
    'Our platform allows you to  customize and brand your self-trading experience to suit your needs. This includes simple branding customization options under Bruno Core and end-to-end customization with source code delivery under "Bruno Bespoke".',
    "We have ready-made integrations with Meta Trader 5 as a Tech OMS and with DriveWealth, Alpaca and Apex Fintech Solutions as broker OMS. These integrations allow you to seamlessly connect to your preferred OMS and start trading right away.",
    "With our ready integrations and marketplace, you can quickly and easily connect to the tools and resources you need to trade with confidence.",
  ],
  images: [
    IMAGES.SELF_TRADING_FEATURE_ICON_1,
    IMAGES.SELF_TRADING_FEATURE_ICON_2,
    IMAGES.SELF_TRADING_FEATURE_ICON_3,
  ],
};
const FEATURES_SECTION_DATA_INTEGRATION = {
  title: "Market Data Integrations",
  desc: "Our market data integrations with Thomson Reuters, FactSet, and Bloomberg provide you with access to the most accurate and up-to-date market data, allowing you to make informed investment decisions.",
  img: IMAGES.SELF_TRADING_MARKET_DATA,
  Title_trading_features: "Advanced Trading Features:",
  content_trading_features: [
    "Dollar-based investing starting from $1 USD or equivalent, Invest by Units or by Dollar.",
    "Real-time market data and news updates, powered by Market Data Providers.",
    "Technical & Fundamental Analysis components.",
    "Mobile and web trading platforms available.",
    "Customizable watchlists.",
    "Customizable and user-friendly interface.",
  ],
};
const FEATURES_SECTION_CONCLUSION = {
  title: "Conclusion and next steps",
  desc: "In conclusion, our self-trading feature provides investors with the tools and capabilities they need to take control of their investments. With support for a wide range of asset classes, advanced trading features, and easy integrations,our platform is designed to meet the needs of traders of all levels. If you're interested in learning more about our self-trading feature or would like to schedule a demo, please don't hesitate to contact us",
  img: IMAGES.SELF_TRADING_CONCLUSION_ICON,
  bgImg: IMAGES.SELF_TRADING_CONCLUSION_BACKGROUND,
};
//  Feature Thematic Basket
const THEMATIC_BASKET_HEADER = {
  title: "Thematic Baskets",
  content:
    "A thematic basket is a portfolio of stocks or other assets that are selected based on a specific theme or industry. The global market for thematic baskets is worth billions of dollars and is growing rapidly, with a growing number of investors turning to thematic baskets as a way to diversify their portfolios and tap into specific growth areas. With the ability to invest starting at $1 USD, thematic baskets are also making it possible for low-income investors to access new investment opportunities and benefit from the growth in emerging markets.",
  img: IMAGES.THEMATIC_BASKET_HEADER,
};
const THEMATIC_BASKET_MARKET_DATA = {
  title: "Market Data Integrations",
  desc: "Bruno's unique approach to thematic baskets allows banks and brokerage houses to quickly and easily curate and offer a wide range of thematic investment options to their customers. Our platform utilizes existing algorithms and tools to help curate baskets, and offers rebalancing and fractional share capabilities. Additionally, our built-in risk questionnaire feature ensures that customers are matched with the appropriate thematic basket based on their individual risk tolerance. All of this makes it easy for banks to offer and manage thematic investments at scale.",
  img: IMAGES.THEMATIC_MARKET_DATA,
};
const THEMATIC_BASKET_FEATURES = {
  mainTitle: "Features",
  title: [
    "Risk Management & Questionnaires",
    "Automatic Quarterly Rebalancing",
    "Immediate Execution",
    "Real-time Net Asset Value",
    "Fractional Share Investing",
    "Rapid Deployment",
  ],
  desc: [
    "Assessing and managing risk through personalized questionnaires.",
    "Maintaining portfolio balance and maximizing returns through regular rebalancing.",
    "Buying and selling securities in real-time for maximum efficiency.",
    "Continuously updated NAV for transparent and accurate portfolio value.",
    "Investing in any amount, including fractional shares, for increased accessibility and affordability.",
    "Launch your thematic basket platform in minutes with our easy to use technology.",
  ],
  images: [
    IMAGES.THEMATIC_BASKET_FEATURE_ICON_1,
    IMAGES.THEMATIC_BASKET_FEATURE_ICON_2,
    IMAGES.THEMATIC_BASKET_FEATURE_ICON_3,
    IMAGES.THEMATIC_BASKET_FEATURE_ICON_4,
    IMAGES.THEMATIC_BASKET_FEATURE_ICON_5,
    IMAGES.THEMATIC_BASKET_FEATURE_ICON_6,
  ],
};
const THEMATIC_BASKET_ANALYTIC = {
  mainTitle: "Analytical Components",
  title: "Holdings",
  desc: "Detailed list of stocks included in the thematic basket, allowing investors to understand the composition of their investment.",
  img: IMAGES.THEMATIC_BASKET_ANALYTIC_ICON,
  bgImg: IMAGES.THEMATIC_BASKET_ANALYTIC_BACKGROUND,
};
const THEMATIC_BASKET_REGIONAL = {
  mainTitle: "Regional",
  title: [
    "Distribution",
    "Asset Distribution",
    "Analyst Opinion",
    "Risk & Targets",
    "Back Tested Performance",
  ],
  desc: [
    "Information on the geographic distribution of stocks within the thematic basket, providing insight into the basket's diversification.",
    "Breakdown of the types of assets within the thematic basket, such as stocks, bonds, and cash,allowing investors to understand the asset allocation.",
    "Analysis and recommendations from financial experts on the thematic basket, providing insight into the basket's potential performance.",
    "Information on the basket's potential risk and target returns, allowing investors to align their investment with their risk tolerance and financial goals",
    "Historical performance data for the thematic basket, allowing investors to evaluate the basket's past performance and potential future performance",
  ],
  images: [
    IMAGES.THEMATIC_BASKET_REGIONAL_ICON_1,
    IMAGES.THEMATIC_BASKET_REGIONAL_ICON_2,
    IMAGES.THEMATIC_BASKET_REGIONAL_ICON_3,
    IMAGES.THEMATIC_BASKET_REGIONAL_ICON_4,
    IMAGES.THEMATIC_BASKET_REGIONAL_ICON_5,
  ],
};
const THEMATIC_BASKET_BENEFIT = {
  mainTitle: "Benefits",
  cards: [
    {
      title: "Banks",
      media: IMAGES.THEMATIC_BASKET_BENEFITS_BANKS,
      content: [
        "Increased revenue through offering new investment options",
        "Attract and retain customers with unique investment offerings",
        "Improved risk management through diversification of portfoliosS",
        "Ability to offer custom thematic baskets to target specific customer segments",
      ],
    },
    {
      title: "End Customer",
      media: IMAGES.THEMATIC_BASKET_BENEFIT_CUSTOMERS,
      content: [
        "Diversification of investment portfolios",
        "Ability to align investments with personal values and interests",
        "Access to professionally curated and managed thematic baskets",
        "Potentially higher returns through focused investment strategies",
      ],
    },
  ],
};
const THEMATIC_BASKET_USECASE = {
  mainTitle: "USE CASES",
  title: ["Technology basket", "monthly yield dividend stocks basket"],
  content: [
    "A technology thematic basket may include a selection of stocks from companies that are at the forefront of technological innovation, such as companies in the AI, VR, and 5G industries. This basket would be ideal for investors who want to gain exposure to the technology sector and capitalize on the growth potential of these companies",
    " A thematic basket focused on monthly yield dividend stocks may include a selection of stocks from companies that pay a steady stream of dividends on a monthly basis. This basket would be ideal for investors looking for a steady stream of income and looking to diversify their portfolio. This could include utility companies, real estate investment trusts and other companies that pay dividends regularly",
  ],
  media: IMAGES.THEMATIC_BASKET_USCASE_IMG,
};

// Feature Goal Based
const GOAL_BASED_HEADER = {
  title: "Goal-Based Investment",
  content:
    "Goal-based investing is a powerful tool that allows investors to achieve their financial goals. Whether you're planning for retirement, saving for a child's education, or building a long-term savings plan, goal-based investing can help you get there. This feature is designed to make it easy and efficient for investors to reach their financial goals by providing a user-friendly interface and a wide range of investment options.",
  img: IMAGES.GOAL_BASED_HEADER,
};
const GOAL_BASED_GETTING_STARTED = {
  title: "Getting Started",
  content:
    "Getting started with goal-based investing is easy. Investors can start by setting a financial goal, creating a plan, and tracking progress towards achieving it. The user interface makes it easy to sta on track and reach your financial goals",
  img: IMAGES.GOAL_BASED_GETTING_STARTED,
};
const GOAL_BASED_TYPES_GOALS = {
  title: "Types Of Goals",
  desc: "Goal-based investing allows investors to set a wide range of financial goals. Some of the most popular goals include retirement planning, education planning, and long-term savings. By setting a goal, investors can create a plan and track progress towards achieving it.",
  img: IMAGES.GOAL_BASED_TYPES_GOALS_ICON,
  bgImg: IMAGES.GOAL_BASED_TYPES_GOALS,
};
const GOAL_BASED_USE_CASE = {
  mainTitle: "USE CASES AND EXAMPLES",
  title: ["wORKING TOWARDS A GOAL"],
  content: [
    "Goal-based investing is a versatile tool that can be used to achieve a wide range of financial goals. For example, an investor who is nearing retirement may use goal-based investing to create a plan for generating a steady stream of income during retirement. Similarly, a parent who wants to save for their child's education might use goal-based investing to build a long-term savings plan",
  ],
  media: IMAGES.GOAL_BASED_USE_CASE,
};

const GOAL_BASED_USER_INTERFACE = {
  title: "User Interface",
  content:
    "The user interface of goal-based investing is intuitive and easy to use. It allows investors to set goals, create a plan,and track progress towards achieving them. This makes it easy for investors to stay on track and reach their financial goals.",
  img: IMAGES.GOAL_BASED_USER_INTERFACE,
};
const GOAL_BASED_CEO = {
  title: "Wael Salem",
  subTitle: "ceo",
  content:
    "With the growing number of retirees worldwide, the adaptation of retirement plans versus traditional trading methods is on the rise. By onboarding a user for life, fintech companies have the opportunity to build a long-term trust relationship with their customers. Additionally, this approach provides ample opportunities for upselling and diversifying product offerings throughout the customer's lifetime. By leveraging the power of goal-based investing and targeting retirement, fintech companies can tap into a vast and growing market with great potential for long- term success",
  img: IMAGES.GOAL_BASED_CEO,
  bgImg: IMAGES.GOAL_BASED_CEO_BACKGROUND,
};
const GOAL_BASED_INVESTMENT = {
  title: "Comparison with Traditional Investment Methods",
  content:
    "Traditional investment methods can be complex and difficult to navigate. Goal-based investing, on the other hand, is designed to be easy and efficient. It allows investors to set financial goals, create a plan, and track progress in a user- friendly way. This is a great way for investors to achieve their financial goals without the complexity of traditional investment methods.",
};
const SECTIONS = {
  // Home Sections
  HOME_HERO,
  FEATURES,
  FEATURES_TABS,
  FEATURES_SECTION_HEADER,
  DELIVERY_PLANS,
  BLOG,
  QUESTION,
  LEADERSHIP,
  BRUNO_PRODUCTS,
  ARCHITECTURE,
  PROJECTS,
  MARKETPLACE,
  AWARDS,
  HOME_HISTORY,
  CALL_ACTION,
  // About Sections
  ABOUT_HERO,
  ABOUT_HISTORY,
  // Products Sections
  PRODUCTS_HERO,
  PRODUCTS_WELCOME,
  // Features Self-Trading
  FEATURES_SELF_TRADING_HEADER,
  FEATURES_SECTION_FEATURE,
  FEATURES_SECTION_DATA_INTEGRATION,
  FEATURES_SECTION_CONCLUSION,
  //  Features Thematic BASKET
  THEMATIC_BASKET_ANALYTIC,
  THEMATIC_BASKET_BENEFIT,
  THEMATIC_BASKET_FEATURES,
  THEMATIC_BASKET_HEADER,
  THEMATIC_BASKET_MARKET_DATA,
  THEMATIC_BASKET_REGIONAL,
  THEMATIC_BASKET_USECASE,
  // Goal Based
  GOAL_BASED_CEO,
  GOAL_BASED_GETTING_STARTED,
  GOAL_BASED_HEADER,
  GOAL_BASED_INVESTMENT,
  GOAL_BASED_TYPES_GOALS,
  GOAL_BASED_USER_INTERFACE,
  GOAL_BASED_USE_CASE,
  // choose your package
  CHOOSE_PACKAGE,
  // ABOUT_SERVE
  ABOUT_SERVE,
  // HIRING
  HIRING,
  // features
  FEATURESBRUNO_DATA,
};

export default SECTIONS;
