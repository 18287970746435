// React
import { useEffect, useRef, useState } from "react";
// Hash Link
import { HashLink } from "react-router-hash-link";

// MUI
import { Box, Container, Grid, Slide } from "@mui/material";

// Shared
import IMAGES from "shared/images";

// Theme

import {
  SolutionCard,
  SolutionCardContent,
  SolutionCardDescription,
  SolutionCardImage,
  SolutionCardTitle,
  SolutionsCardButton,
  SolutionsPageTitle,
} from "./solutions.styles";

const SolutionsData = [
  {
    title: "Digital Banks",
    description: `Tradesocio's platform is the perfect solution for banks looking to digitize their operations and stay ahead of the competition. Our platform offers a wide range of features and capabilities that can help banks improve customer experience, increase efficiency, and drive growth.`,
    media: IMAGES.SOLUTIONS_DIGITAL_BANKS,
    link: "/solutions/digital-banks/#",
  },
  {
    title: "Brokerage",
    description: `Tradesocio's brokerage solutions provide a powerful, flexible, and secure platform for digital transformation. Our platform is designed to meet the needs of modern brokerage firms, offering a wide range of features and customization options to help you expand your offerings and improve the overall customer experience.`,
    media: IMAGES.SOLUTIONS_BROKERAGE,
    link: "/solutions/brokerage/#",
  },
  {
    title: "Advisors",
    description: `Tradesocio's platform for advisors offers a powerful and customizable solution for goal-based planning and thematic investments. Our platform is designed to help advisors offer customized solutions to meet the unique needs of their clients and support them in achieving their investment goals.`,
    media: IMAGES.SOLUTIONS_ADVISORS,
    link: "/solutions/advisors/#",
  },
];

function getSolutions(solutions) {
  return solutions.map((solution) => {
    return (
      <Grid key={solution.title} item xs={12} md={4}>
        <SolutionCard>
          <SolutionCardImage
            component="img"
            src={solution.media.src}
            alt={solution.media.alt}
          />
          <SolutionCardContent>
            <SolutionCardTitle variant="h3">{solution.title}</SolutionCardTitle>
            <SolutionCardDescription variant="subtitle1">
              {solution.description}
            </SolutionCardDescription>
            <SolutionsCardButton component={HashLink} to={solution.link}>
              See More
            </SolutionsCardButton>
          </SolutionCardContent>
        </SolutionCard>
      </Grid>
    );
  });
}

function SolutionsPreview() {
  const { SOLUTIONS_BG_SHAPE_GREEN_SVG, SOLUTIONS_BG_SHAPE_RED_SVG } = IMAGES;
  const [animate, setAnimate] = useState(false);
  const containerRef = useRef(null);
  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <Box component="section" sx={{ paddingTop: 38, position: "relative" }}>
      <Container maxWidth="xxl">
        <Grid
          container
          columnSpacing={8}
          rowSpacing={8}
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            minHeight: "54rem",
          }}
          ref={containerRef}
        >
          {getSolutions(SolutionsData)}

          <Slide
            direction="up"
            in={animate}
            timeout={20 * 60}
            container={containerRef.current}
          >
            <SolutionsPageTitle>Solutions</SolutionsPageTitle>
          </Slide>
          <Slide
            direction="up"
            in={animate}
            timeout={20 * 60}
            container={containerRef.current}
          >
            <Box
              sx={{ position: "absolute", bottom: "3%", right: 0, zIndex: -1 }}
              component={SOLUTIONS_BG_SHAPE_GREEN_SVG}
            />
          </Slide>
          <Slide
            direction="right"
            in={animate}
            timeout={20 * 60}
            container={containerRef.current}
          >
            <Box
              sx={{ position: "absolute", top: "11%", left: 0, zIndex: -1 }}
              component={SOLUTIONS_BG_SHAPE_RED_SVG}
            />
          </Slide>
        </Grid>
      </Container>
    </Box>
  );
}

export default SolutionsPreview;
