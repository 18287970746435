// MUI
import {
  Accordion,
  AccordionSummary,
  Box,
  styled,
  Typography,
} from "@mui/material";
// theme
import { COLORS } from "styles/theme";

export const AccordianWrap = styled(Box)(() => ({
  boxShadow: "none",
  border: "none",
  backgroundColor: "transparent",
  ".Mui-expanded": {
    filter: "drop-shadow(48px 24px 48px rgba(24, 37, 56, 0.12))",
    boxShadow: "none",
  },
}));
export const AccordianBox = styled(Accordion)(({ theme }) => ({
  background: COLORS.TEXT_WHITE,
  border: "1px solid",
  borderColor: COLORS.TEXT_LIGHT_GREY,
  borderRadius: "10px !important",
  boxShadow: "none",
  "& .MuiSvgIcon-root": {
    fill: COLORS.TEXT_BLACK,
  },
  ".Mui-expanded": {
    filter: "drop-shadow(48px 24px 48px rgba(24, 37, 56, 0.12))",
  },
  "& .Mui-expanded": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px 10px 0 0 !important",
    boxShadow: "none",
    filter: "drop-shadow(48px 24px 48px rgba(24, 37, 56, 0.12))",
    "& .MuiTypography-root": {
      color: COLORS.TEXT_WHITE,
    },
    "& .MuiSvgIcon-root": {
      fill: COLORS.TEXT_WHITE,
    },
  },
}));
export const AccordionSummaryBox = styled(AccordionSummary)(() => ({
  fontFamily: "DIN Pro",
  fontStyle: "normal",
  overflow: "hidden",
  minHeight: "60px",
}));

export const AccordianQuestion = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN Pro",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  color: theme.palette.primary.main,
}));

export const AccordianAnswer = styled(Typography)(() => ({
  fontFamily: "Nexa Bold",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "24px",
  color: COLORS.TEXT_GREY,
}));
