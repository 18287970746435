import { Button, Card, Stack, styled, Typography } from "@mui/material";
import { BORDERS, COLORS, SHADOWS } from "styles/theme";

// Solutions Preview
export const SolutionCard = styled(Card)(() => ({
  backgroundColor: COLORS.TEXT_WHITE,
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    "& .MuiTypography-subtitle1": {
      WebkitLineClamp: 40,
      maxHeight: "30rem",
    },
  },
}));

export const SolutionCardContent = styled(Stack)(({ theme }) => ({
  rowGap: theme.spacing(2),
  padding: theme.spacing(2),
  alignItems: "flex-start",
}));

export const SolutionCardImage = styled("img")(() => ({
  maxWidth: "100%",
  width: "29rem",
  height: "13.5rem",
  objectFit: "cover",
}));

export const SolutionCardTitle = styled(Typography)(() => ({
  fontFamily: "Courier New",
  color: COLORS.PRIMARY_BLUE,
}));

export const SolutionCardDescription = styled(Typography)(() => ({
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
  transition: "all .5s",
  overflow: "hidden",
  maxHeight: "8rem",
  fontSize: "1rem",
}));

export const SolutionsCardButton = styled(Button)(() => ({
  borderRadius: "1rem",
  color: COLORS.SECONDARY_GREEN,
  backgroundColor: COLORS.SOLUTIONS_CARD_BUTTON_GREEN,
  minWidth: "5rem",
}));

export const SolutionsPageTitle = styled(Typography)(() => ({
  fontFamily: "DIN Pro",
  fontWeight: 900,
  fontSize: "8rem",
  color: COLORS.PRIMARY_YELLOW,
  position: "absolute",
  margin: "auto",
  top: "8%",
  zIndex: -10,
}));

// Solution Detail

export const SolutionHeroTitle = styled(Typography)(() => ({
  fontSize: "6rem",
  color: COLORS.PRIMARY_BLUE,
}));

export const SolutionHeroSubtitle = styled(Typography)(() => ({
  color: COLORS.PRIMARY_YELLOW,
}));

export const SolutionHeroImage = styled("img")(() => ({
  objectFit: "cover",
  objectPosition: "center",
  width: "100%",
  maxHeight: "30rem",
  maxWidth: "32rem",
  position: "absolute",
}));

export const SolutionFeatureTitle = styled(Typography)(() => ({
  fontSize: "1.5rem",
  color: COLORS.TEXT_BLACK,
}));

export const ArchitecturePrototypeImage = styled(Stack)(() => ({
  objectFit: "contain",
  width: "100%",
  height: "100%",
  maxWidth: "42rem",
  maxHeight: "40rem",
  margin: "auto",
}));

export const ArchitectureDescription = styled(Typography)(() => ({
  color: COLORS.TEXT_WHITE,
  fontSize: "1.5rem",
  width: "100%",
  maxWidth: "38rem",
}));

export const ArchitectureDescriptionCard = styled(Stack, {
  shouldForwardProp: (props) => props !== "cardIndex",
})(({ cardIndex, theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: COLORS.TEXT_WHITE,
  boxShadow: SHADOWS.ARCHITECTURE_CARD,
  border: BORDERS.ARCHITECTURE_CARD,
  borderRadius: "14px",
  padding: theme.spacing(4),
  position: "absolute",
  transition: "all .2s",
  left: cardIndex % 2 === 0 ? "100%" : "-100%",
  [theme.breakpoints.down("md")]: {
    position: "static",
    padding: theme.spacing(4, 1),
    borderRadius: 0,
  },
}));
