// PropTypes
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";

function SectionTitle({
  title,
  subtitle,
  maxWidth,
  fontFamily,
  titleColor,
  subTitleColor,
  textAlign,
  PT,
}) {
  return (
    <Box
      textAlign={textAlign}
      display="flex"
      flexDirection="column"
      gap={2}
      // sx={{ paddingTop: `${PT}` }}
    >
      <Typography variant="h2" color={titleColor}>
        {title}
      </Typography>

      {subtitle && (
        <Typography
          m="0 auto"
          variant="subtitle1"
          sx={{
            maxWidth: { xs: "100%", lg: maxWidth },
            fontWeight: 800,
            fontSize: "1rem",
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  maxWidth: PropTypes.string,
  fontFamily: PropTypes.string,
  titleColor: PropTypes.string,
  subTitleColor: PropTypes.string,
  textAlign: PropTypes.string,
  PT: PropTypes.string,
};

SectionTitle.defaultProps = {
  subtitle: "",
  maxWidth: "70%",
  fontFamily: "Courier New",
  titleColor: "",
  subTitleColor: "",
  textAlign: "center",
  PT: "",
};
export default SectionTitle;
