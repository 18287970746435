import PropTypes from "prop-types";
// MUI
import { Grid, Link } from "@mui/material";
// styles
import {
  LeadershipCardBox,
  LeadershipCardWrp,
  LeadershipContentBox,
  LeadershipDesc,
  LeadershipDesignation,
  LeadershipImage,
  LeadershipName,
  LeadershipTopBox,
  SocialMediaImage,
  SocialMediaList,
} from "./card.styles";

function CardComponent({
  name,
  designation,
  desc,
  image,
  socialImage,
  // facebook,
  // instagram,
  // pinterest,
  linkedin,
  index,
}) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <LeadershipCardWrp className={`card-${index}`}>
        <LeadershipCardBox>
          <LeadershipImage src={image?.src} />
          <LeadershipContentBox>
            <LeadershipTopBox>
              <LeadershipName gutterBottom variant="h5" component="div">
                {name}
              </LeadershipName>
              <LeadershipDesignation gutterBottom variant="h5" component="div">
                {designation}
              </LeadershipDesignation>
              <LeadershipDesc gutterBottom variant="subtitle2" component="div">
                {desc}
              </LeadershipDesc>
            </LeadershipTopBox>
            <SocialMediaList>
              {/* <SocialMediaImage src={facebook?.src} />
              <SocialMediaImage src={instagram?.src} /> */}
              <Link href={linkedin} target="_blank">
                <SocialMediaImage src={socialImage.linkdin?.src} />
              </Link>

              {/* <SocialMediaImage src={pinterest?.src} /> */}
            </SocialMediaList>
          </LeadershipContentBox>
        </LeadershipCardBox>
      </LeadershipCardWrp>
    </Grid>
  );
}

CardComponent.propTypes = {
  name: PropTypes.string,
  designation: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.string),
  socialImage: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
  // instagram: PropTypes.objectOf(PropTypes.string),
  // pinterest: PropTypes.objectOf(PropTypes.string),
  linkedin: PropTypes.string,
  index: PropTypes.number,
};

CardComponent.defaultProps = {
  name: "",
  designation: "",
  image: "",
  socialImage: "",
  // instagram: "",
  // pinterest: "",
  linkedin: "",
  index: 1,
};
export default CardComponent;
