import * as React from "react";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  useMediaQuery,
  useTheme,
  Box,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";

import { ListText } from "components/ChoosePackage/ChoosePackage.style";
import {
  MarkIcon,
  ModalTitleDesc,
  ModalDesc,
  ModalDialogTitle,
  ModalDialogContent,
  ModalNormalText,
  ModalDialog,
} from "./Modal.style";

export default function Modal({ open, setOpen, scroll, data }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function designList(data) {
    return (
      <List>
        {data?.map((item) => (
          <ListItem disablePadding key={item}>
            <ListItemButton sx={{ alignItems: "baseline" }}>
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <MarkIcon fontSize="small" />
              </ListItemIcon>
              <ListText
                primary={<ModalDesc variant="subtitle2">{item}</ModalDesc>}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  }
  const fixedText =
    "Join our team of talented professionals and make a significant impact in the Fintech industry. We invite you to apply by filling out our application form. Simply click on 'Apply Now' to begin. We will meticulously review your information and respond promptly. Thank you for your interest in becoming a part of our dynamic team at Tradesocio.";
  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullScreen={useMediaQuery(theme.breakpoints.down("sm"))}
    >
      <ModalDialogContent dividers={scroll === "body"}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <ModalDialogTitle id="scroll-dialog-title">
            {data?.title}{" "}
          </ModalDialogTitle>
          <ModalNormalText sx={{ display: "flex" }} variant="subtitle2">
            Years of experience:
            <ModalTitleDesc ml={1} variant="subtitle2">
              {" "}
              {data?.yearsOfExperince}
            </ModalTitleDesc>
          </ModalNormalText>
          <ModalTitleDesc variant="subtitle2">Job Description:</ModalTitleDesc>
          {designList(data?.JobDescription)}
        </DialogContentText>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <ModalTitleDesc variant="subtitle2">
            Desired Skills & Requirements:
          </ModalTitleDesc>
          {designList(data?.requirements)}
        </DialogContentText>
        <Box display="flex" justifyContent="flex-start">
          <ModalNormalText
            sx={{ display: "block", paddingLeft: "12px" }}
            variant="subtitle2"
            dangerouslySetInnerHTML={{ __html: fixedText }}
          />
        </Box>
        <Button
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          sx={{
            fontSize: { xs: "14px", sm: "16px" },
            padding: { xs: "8px 16px", sm: "10px 20px" },
            mt: 3,
            width: "100%",
          }}
          component={Link}
          href="https://docs.google.com/forms/d/e/1FAIpQLSeCxYgMMmWs_UAN_PU-Pg-TYIcC45zYQr3h7nzFtLgh2UwggQ/viewform?usp=sf_link"
        >
          Apply Now
        </Button>
      </ModalDialogContent>

      {/* <DialogActions>
        </DialogActions> */}
    </ModalDialog>
  );
}
