import { Box, Typography, styled } from "@mui/material";
import { COLORS } from "styles/theme";

export const HeroContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));

export const HeroContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));
export const HeroContentTitle = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  color: COLORS.PRIMARY_BLUE,
  marginBottom: theme.spacing(2),
}));
export const HeroCardsWrapper = styled(Box)(() => ({}));
export const HeroCard = styled(Box)(() => ({
  position: "relative",
  maxWidth: "296px",
  padding: "24px",
  height: "100%",
  border: "1px solid #E6E5E5",
  // filter: "drop-shadow(0px 11px 13px rgba(0, 0, 0, 0.25))",
  borderRadius: "5px",
}));
export const HeroCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: COLORS.PRIMARY_BLUE,
  marginBottom: theme.spacing(2),
}));
export const HeroCardContent = styled(Typography)(({ theme }) => ({
  lineHeight: "26px",
  fontWeight: 400,
  marginBottom: theme.spacing(2),
}));
export const HeroCardBtns = styled(Box)(() => ({
  marginTop: "auto",
  // position: "absolute",
  // bottom: "5px",
}));
