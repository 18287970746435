import {
  Grid,
  Box,
  Stack,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
// Shared
import IMAGES from "shared/images";
import SECTIONS from "shared/sections";

// Styles
import {
  // Hero
  HomeContentContainer,
  // Home
  HomeImage,
  HeroTitle,
  HomeContent,
  HomeBgShapeRed,
  HomeBgShapeYellow,
  //   About
  HeroContainer,
  AboutWrapper,
  AboutWrapperContent,
  AboutContentAbout,
  AboutImgWrapper,
  AboutImg,
  AboutContentAboutTitle,
  AboutContentAboutContainer,
  AboutContentAboutDesc,
  AboutContentAboutImg,
} from "./hero.styles";

export function HomeHero() {
  const { HOME_HERO } = SECTIONS;
  const { HERO_BG_SHAPE_RED, HERO_BG_SHAPE_YELLOW, HOME_HERO_BRUNO_LOGO_SVG } =
    IMAGES;
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box>
      {/* Background Shapes */}

      <HomeBgShapeRed src={HERO_BG_SHAPE_RED.src} alt={HERO_BG_SHAPE_RED.alt} />
      {!matchesSM && (
        <HomeBgShapeYellow
          src={HERO_BG_SHAPE_YELLOW.src}
          alt={HERO_BG_SHAPE_YELLOW.alt}
        />
      )}

      {/* Content */}
      <HeroContainer maxWidth="xl">
        <Grid
          container
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid item xs={12} md={6} lg={5}>
            <HomeContentContainer>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box component={HOME_HERO_BRUNO_LOGO_SVG} />
                <HeroTitle variant="h1">{HOME_HERO.title}</HeroTitle>
              </Stack>

              <Typography variant="h3">{HOME_HERO.subtitle}</Typography>

              <HomeContent variant="body1">{HOME_HERO.content}</HomeContent>

              {/* <Button variant="contained" color="primary" href={HOME_HERO.link}>
                {HOME_HERO.buttonText}
              </Button> */}
            </HomeContentContainer>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Stack sx={{ height: "100%", justifyContent: "center" }}>
              <HomeImage
                component="img"
                src={HOME_HERO.media.src}
                alt={HOME_HERO.media.alt}
              />
            </Stack>
          </Grid>
        </Grid>
      </HeroContainer>
    </Box>
  );
}

export function AboutHero() {
  const { ABOUT_HERO } = SECTIONS;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AboutWrapper>
      <Container>
        <AboutWrapperContent>
          <AboutContentAbout>
            <Grid container>
              <Grid item xs={12} md={6}>
                <AboutContentAboutContainer>
                  <Stack>
                    <AboutContentAboutTitle variant="h4" mb={2}>
                      {ABOUT_HERO.title}
                    </AboutContentAboutTitle>
                    <AboutContentAboutDesc variant="subtitle2" mb={2}>
                      {ABOUT_HERO.subtitle}
                    </AboutContentAboutDesc>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 800,
                        color: "#fff",
                      }}
                    >
                      {ABOUT_HERO.secondSubtitle}
                    </Typography>
                  </Stack>
                </AboutContentAboutContainer>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                justifyContent={isSmallScreen ? "flex-start" : "center"}
                alignItems={isSmallScreen ? "flex-start" : "center"}
                display="flex"
              >
                <AboutContentAboutImg
                  src={
                    isSmallScreen
                      ? ABOUT_HERO.media_2.src
                      : ABOUT_HERO.media.src
                  }
                />
              </Grid>
            </Grid>
          </AboutContentAbout>
          <AboutImgWrapper>
            <AboutImg src={ABOUT_HERO.bg.src} />
          </AboutImgWrapper>
        </AboutWrapperContent>
      </Container>
    </AboutWrapper>
  );
}
