import PropTypes from "prop-types";
// MUI
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
// Components
import SectionTitle from "components/SectionTitle/sectionTitle.component";

import { COLORS } from "styles/theme";

import IMAGES from "shared/images";
import {
  ArchitectureDescription,
  ArchitectureDescriptionCard,
  ArchitecturePrototypeImage,
  SolutionFeatureTitle,
  SolutionHeroImage,
  SolutionHeroSubtitle,
  SolutionHeroTitle,
} from "./solutions.styles";

const SolutionDetailData = {
  "digital-banks": {
    title: "Digital Banks",
    description: `Tradesocio's platform is the perfect solution for banks looking to digitize their operations and stay ahead of the competition. Our platform offers a wide range of features and capabilities that can help banks improve customer experience, increase efficiency, and drive growth.`,
    media: IMAGES.SOLUTION_HERO_DIGITAL_BANKS,
    features: [
      {
        title: "Self-Trading",
        media: IMAGES.SOLUTION_FEATURE_DIGITAL_BANKS_ICON_1_SVG,
        content: `Tradesocio's platform allows banks to offer self-trading capabilities to their customers, allowing them to make trades on their own, in real-time. This can help banks increase customer engagement and reduce the need for human intervention.`,
      },
      {
        title: "Multi-Asset Capabilities",
        media: IMAGES.SOLUTION_FEATURE_DIGITAL_BANKS_ICON_2_SVG,
        content: `Tradesocio's platform supports trading in multiple asset classes, including equities, fixed income, currencies, and more. This allows banks to offer a wider range of investment options to their customers and increase revenue potential.`,
      },
      {
        title: "Advanced Analytics",
        media: IMAGES.SOLUTION_FEATURE_DIGITAL_BANKS_ICON_3_SVG,
        content: `Tradesocio's platform provides banks with powerful analytics and reporting tools that can help them better understand their customers' behavior and preferences. This can help banks improve customer segmentation and targeting, as well as identify new business opportunities.`,
      },
    ],
    architecture: {
      description: `Tradesocio's platform  is built on a hybrid architecture that allows for easy integration with legacy systems, while also providing the scalability and security needed for modern financial institutions. The platform is hosted on Azure, a leading cloud provider, which allows for easy expansion and customization as needed. The platform also includes a comprehensive API, which allows for easy integration with other systems and enables financial institutions to fully leverage the capabilities of Bruno.`,
      cards: [
        {
          title: "Integration",
          media: IMAGES.SOLUTION_ARCHITECTURE_DIGITAL_BANKS_INTEGRATION,
          content: `Tradesocio's platform is designed to be easily integrated with other systems, including legacy systems, to help financial institutions streamline their digital transformation efforts. The platform's API allows for easy integration with other systems, while also providing the flexibility needed to adapt to the unique requirements of each institution. This makes it easy for financial institutions to take full advantage of the capabilities of the Tradesocio platform and to quickly and easily implement new features and functionalities as needed.`,
        },
        {
          title: "Security",
          media: IMAGES.SOLUTION_ARCHITECTURE_DIGITAL_BANKS_SECURITY,
          content: `Security is a top priority at Tradesocio, and the platform is built with a range of security features to help protect the sensitive data of financial institutions and their customers. The platform is hosted on Azure, which provides a secure, scalable and reliable infrastructure. Additionally, the platform includes a range of security features such as encryption, secure protocols, and role-based access controls to help ensure that only authorized users can access sensitive data`,
        },
        {
          title: "Scalability",
          media: IMAGES.SOLUTION_ARCHITECTURE_DIGITAL_BANKS_SCALABILITY,
          content: `Tradesocio's platform is designed to be highly scalable, so it can adapt to the changing needs of financial institutions. The platform is hosted on Azure, which provides a scalable infrastructure that can easily adapt to changing traffic patterns. Additionally, the platform includes a range of features such as load balancing and auto-scaling, which help ensure that the platform can handle large volumes of traffic and data without any disruptions or slowdowns.`,
        },
      ],
    },
    conclusion: [
      `Tradesocio's platform for banks offers a comprehensive solution for digital transformation. Built on Azure, it offers the ability to scale seamlessly and handle increased traffic, as well as the ability to integrate with legacy systems through open API architecture. This ensures enhanced security and compliance, including SOC 2 and PCI-DSS, keeping your data protected at all times. In terms of customization and branding, the platform allows for complete white-labeling, enabling you to present the platform as your own, and align it with your existing brand guidelines.`,
      `Our team of experts will work closely with you to ensure that the platform meets your specific requirements and fits seamlessly into your existing infrastructure. Additionally, our dedicated team of experts is available 24/7 to provide ongoing technical support and maintenance to ensure that the platform is always running at its best.`,
    ],
  },
  brokerage: {
    title: "Brokerage",
    description: `Tradesocio's brokerage solutions provide a powerful, flexible, and secure platform for digital transformation. Our platform is designed to meet the needs of modern brokerage firms, offering a wide range of features and customization options to help you expand your offerings and improve the overall customer experience.`,
    media: IMAGES.SOLUTION_HERO_BROKERAGE,
    features: [
      {
        title: "Self-Trading Capabilities",
        media: IMAGES.SOLUTION_FEATURE_BROKERAGE_ICON_1_SVG,
        content: `Our platform enables customers to take control of their own investments with self-trading options.`,
      },
      {
        title: "Multi-Asset Trading",
        media: IMAGES.SOLUTION_FEATURE_BROKERAGE_ICON_2_SVG,
        content: `Our platform supports a wide range of assets, including stocks, bonds, commodities, and more.`,
      },
      {
        title: "End-to-end digital journey",
        media: IMAGES.SOLUTION_FEATURE_BROKERAGE_ICON_3_SVG,
        content: `Our platform supports a wide range of assets, including stocks, bonds, commodities, and more.`,
      },
      {
        title: "Easy onboarding",
        media: IMAGES.SOLUTION_FEATURE_BROKERAGE_ICON_4_SVG,
        content: `Our platform allows for frictionless onboarding in under 5 minutes.`,
      },
      {
        title: "Fractional shares",
        media: IMAGES.SOLUTION_FEATURE_BROKERAGE_ICON_5_SVG,
        content: `Our platform enables customers to start investing with as little as $1 USD through the use of fractional shares.`,
      },
      {
        title: "Invest in units or local currency base",
        media: IMAGES.SOLUTION_FEATURE_BROKERAGE_ICON_6_SVG,
        content: `Our platform allows customers to invest in units or local currency base.`,
      },
      {
        title: "Thematic baskets and goal-based planning",
        media: IMAGES.SOLUTION_FEATURE_BROKERAGE_ICON_7_SVG,
        content: `Our platform allows brokerage firms to offer thematic baskets or goal-based planning options to customers.`,
      },
    ],
    architecture: {
      description: `Our platform for brokerage solutions is built on Azure, which provides the ability to scale seamlessly and handle increased traffic, as well as the ability to integrate with legacy systems through our open API architecture. Additionally, Azure's cloud hosting allows for enhanced security and compliance, including SOC 2 and PCI-DSS, ensuring that your data is always protected. Our platform also inherits the security certifications from Azure, which provides an added layer of protection for your customers' sensitive information.`,
      cards: [
        {
          title: "Customization",
          media: IMAGES.SOLUTION_ARCHITECTURE_BROKERAGE_CUSTOMIZATION,
          content: `In terms of customization and branding, our platform allows for complete white-labeling, enabling you to present the platform as your own and align it with your existing brand guidelines. Our team of experts will work closely with you to ensure that the platform meets your specific requirements and fits seamlessly into your existing infrastructure. We also offer localization options, allowing you to adjust the platform to best fit the customer journey in different regions. We can also customize the end-to-end application and also allow them build their own with Code and or IP transfer possibilities.`,
        },
        {
          title: "Support",
          media: IMAGES.SOLUTION_ARCHITECTURE_BROKERAGE_SUPPORT,
          content: `We also provide ongoing technical support and maintenance to ensure that the platform is always running at its best. Our dedicated team of experts are available 24/7 to address any issues and provide assistance with any questions you may have. We understand the importance of providing a seamless and frictionless experience for your customers, and our team is here to help you achieve that goal.`,
        },
      ],
    },
  },
  advisors: {
    title: "Advisors",
    description: `Tradesocio's platform for advisors offers a powerful and customizable solution for goal-based planning and thematic investments. Our platform is designed to help advisors offer customized solutions to meet the unique needs of their clients and support them in achieving their investment goals.`,
    media: IMAGES.SOLUTION_HERO_ADVISORS,
    features: [
      {
        title: "End-to-end automated KYC, identity, and compliance validation",
        media: IMAGES.SOLUTION_FEATURE_ADVISORS_ICON_1_SVG,
        content: `Our platform automates the process of verifying the identity and compliance of your clients, making it easier for you to onboard new customers and comply with regulatory requirements.`,
      },
      {
        title: "Automated user risk questionnaire and benefits",
        media: IMAGES.SOLUTION_FEATURE_ADVISORS_ICON_2_SVG,
        content: `Our platform includes a user-friendly questionnaire that helps to assess the risk tolerance and investment goals of your clients, so you can provide customized solutions that are tailored to their specific needs.`,
      },
      {
        title: "Real-time NAV",
        media: IMAGES.SOLUTION_FEATURE_ADVISORS_ICON_3_SVG,
        content: `Our platform provides real-time NAV (Net Asset Value) information, so you can keep track of the performance of your clients' portfolios in real-time and make informed investment decisions.`,
      },
      {
        title: "Customized solution offering",
        media: IMAGES.SOLUTION_FEATURE_ADVISORS_ICON_4_SVG,
        content: `Our platform allows you to offer customized solutions that are tailored to the specific needs of your clients. Whether you're looking to provide retirement planning, thematic investing, or any other type of solution, our platform makes it easy to create and manage customized products.`,
      },
      {
        title: "Support of retirement accounts",
        media: IMAGES.SOLUTION_FEATURE_ADVISORS_ICON_5_SVG,
        content: `Our platform supports a wide range of retirement accounts, including 401(k), IRA, and Roth IRA, making it easy for you to provide retirement planning services to your clients.`,
      },
      {
        title: "Mandatory and non-mandatory structures",
        media: IMAGES.SOLUTION_FEATURE_ADVISORS_ICON_6_SVG,
        content: `Our platform can be configured to support both mandatory and non-mandatory structures, allowing you to provide solutions that are tailored to the specific needs of your clients.`,
      },
    ],
    architecture: {
      description: `Our platform for advisors is built on Azure, which provides the ability to scale seamlessly and handle increased traffic, as well as the ability to integrate with legacy systems through our open API architecture. Additionally, Azure's cloud hosting allows for enhanced security and compliance, including SOC 2 and PCI-DSS, ensuring that your data is always protected. Additionally, our platform also inherits all the security certifications from Azure, which ensures that your data is always protected and in compliance with the highest industry standards.`,
      cards: [
        {
          title: "Customization",
          media: IMAGES.SOLUTION_ARCHITECTURE_ADVISORS_CUSTOMIZATION,
          content: `In terms of customization and branding, our platform allows for complete white-labeling, enabling you to present the platform as your own and align it with your existing brand guidelines. Our team of experts will work closely with you to ensure that the platform meets your specific requirements and fits seamlessly into your existing infrastructure. Additionally, we offer localization options to cater to different regions and cultures. Furthermore, we also provide the ability to integrate with other systems to provide a seamless user experience throughout the customer journey. With our customizable solution offering, you can tailor the platform to fit the unique needs of your clients`,
        },
        {
          title: "Support",
          media: IMAGES.SOLUTION_ARCHITECTURE_ADVISORS_SUPPORT,
          content: `Our team of experts will work closely with you to ensure that the platform meets your specific requirements and fits seamlessly into your existing infrastructure. Additionally, our dedicated team of experts is available 24/7 to provide ongoing technical support and maintenance to ensure that the platform is always running at its best.`,
        },
      ],
    },
  },
};

function getSolutionFeatures(featuresList, solutionTitle) {
  return featuresList.map((feature, index) => {
    const key = `${solutionTitle}-${index}`;
    return (
      <Grid key={key} item xs={12} md={4}>
        <Stack
          sx={{
            textAlign: "center",
            alignItems: "center",
            gap: 2,
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box component={feature.media} />
          <SolutionFeatureTitle variant="subtitle1">
            {feature.title}
          </SolutionFeatureTitle>
          <Typography variant="subtitle2"> {feature.content}</Typography>
        </Stack>
      </Grid>
    );
  });
}

function getSolutionArchitectureCards(cardsList, solutionTitle, breakpoint) {
  return cardsList.map((card, index) => {
    const key = `${solutionTitle}-${index}`;
    let flexDirection = "row";
    if (breakpoint) flexDirection = "column";
    else if (index % 2 !== 0) flexDirection = "row-reverse";
    return (
      <Grid
        key={key}
        container
        direction={flexDirection}
        sx={{
          paddingY: { xs: 4, sm: 12 },
          "&:hover": {
            "& .architecture-card": {
              left: 0,
            },
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            position: "relative",
            minHeight: { md: 365 },
          }}
        >
          <ArchitectureDescriptionCard
            justifyContent="center"
            className="architecture-card"
            cardIndex={index}
          >
            <Typography variant="subtitle2"> {card.content}</Typography>
          </ArchitectureDescriptionCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            sx={{
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={card.media.src}
              alt={card.media.alt}
              sx={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
            <Typography
              variant="h1"
              sx={{
                maxWidth: "20rem",
                position: "absolute",
                color: COLORS.TEXT_GREY3,
              }}
            >
              {card.title}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    );
  });
}

function getConclusionContent(contentList, solutionTitle) {
  return contentList.map((content, index) => {
    const key = `${solutionTitle}-${index}`;
    return (
      <Typography
        key={key}
        variant="body1"
        color={COLORS.TEXT_BLACK}
        sx={{
          textAlign: "center",
          fontSize: "1.5rem",
          lineHeight: { md: 2.5 },
        }}
      >
        {content}
      </Typography>
    );
  });
}

function SolutionDetail({ subPage }) {
  const solution = SolutionDetailData[subPage];
  console.log(solution);
  const {
    SOLUTION_HERO_BG_SVG,
    SOLUTION_HERO_SHAPE_RED_SVG,
    SOLUTION_ARCHITECTURE_PROTOTYPE,
    LEGAL_TS_LOGO,
    SOLUTION_ARCHITECTURE_BG_SHAPE_GREEN_SVG,
    SOLUTION_ARCHITECTURE_BG_SHAPE_RED_SVG,
    SOLUTION_ARCHITECTURE_BG_SHAPE_YELLOW_SVG,
  } = IMAGES;
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box sx={{ paddingTop: 15 }}>
      <Box
        component={SOLUTION_HERO_SHAPE_RED_SVG}
        sx={{ position: "absolute", right: 0, top: "2%", zIndex: -1 }}
      />
      <Container maxWidth="xl">
        {/* Hero */}
        <Grid container columnSpacing={4} mb={8} component="section">
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Stack gap={4}>
              <SolutionHeroTitle variant="h1">
                {solution.title}
              </SolutionHeroTitle>
              <SolutionHeroSubtitle variant="h3">
                Introduction
              </SolutionHeroSubtitle>
              <Typography variant="subtitle2" sx={{ maxWidth: "35rem" }}>
                {solution.description}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              justifyContent="center"
              sx={{ position: "relative", height: "40rem" }}
            >
              <Box
                component={SOLUTION_HERO_BG_SVG}
                sx={{ position: "absolute", zIndex: -1, width: "100%" }}
              />
              <SolutionHeroImage
                src={solution.media.src}
                alt={solution.media.alt}
              />
            </Stack>
          </Grid>
        </Grid>
        {/* Features */}
        <Stack sx={{ gap: 8 }} mt={8} mb={8} component="section">
          <SectionTitle title="Features" />
          <Grid container columnSpacing={2} rowGap={8} justifyContent="center">
            {getSolutionFeatures(solution.features, solution.title)}
          </Grid>
        </Stack>
      </Container>

      {/* Architecture */}
      <Grid
        container
        mt={8}
        mb={8}
        sx={{
          minHeight: "40rem",
          backgroundColor: COLORS.PRIMARY_BLUE,
        }}
      >
        <Grid item xs={12} md={6}>
          <ArchitecturePrototypeImage
            component="img"
            src={SOLUTION_ARCHITECTURE_PROTOTYPE.src}
            alt={SOLUTION_ARCHITECTURE_PROTOTYPE.alt}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            sx={{
              height: "100%",
              gap: 4,
              justifyContent: "center",
              "& .MuiTypography-h2": {
                textAlign: "left",
              },
              padding: { xs: 2, lg: 0 },
            }}
          >
            <SectionTitle title="Architecture" />
            <ArchitectureDescription>
              {solution.architecture.description}
            </ArchitectureDescription>
          </Stack>
        </Grid>
      </Grid>

      {/* Architecture Cards */}
      <Box mt={8} mb={8} sx={{ position: "relative" }}>
        <Box
          component={SOLUTION_ARCHITECTURE_BG_SHAPE_RED_SVG}
          sx={{ position: "absolute", left: 0, top: "2%", maxWidth: "100%" }}
        />
        <Box
          component={SOLUTION_ARCHITECTURE_BG_SHAPE_YELLOW_SVG}
          sx={{ position: "absolute", right: 0, top: "30%", maxWidth: "100%" }}
        />
        <Box
          component={SOLUTION_ARCHITECTURE_BG_SHAPE_GREEN_SVG}
          sx={{ position: "absolute", left: 0, top: "62%", maxWidth: "100%" }}
        />
        <Container maxWidth="xl">
          {getSolutionArchitectureCards(
            solution.architecture.cards,
            solution.title,
            matchesSM,
          )}
        </Container>
      </Box>
      {/* Conclusion */}
      {solution.conclusion && (
        <Box
          mt={8}
          mb={8}
          component="section"
          sx={{
            background: `url(${LEGAL_TS_LOGO.src})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              backgroundColor: "rgb(255, 255, 255,.5)",
              backdropFilter: "blur(15px)",
              minHeight: "20rem",
            }}
          >
            <Stack
              sx={{
                gap: 4,
                height: "100%",
              }}
            >
              {getConclusionContent(solution.conclusion, solution.title)}
            </Stack>
          </Container>
        </Box>
      )}
    </Box>
  );
}

SolutionDetail.propTypes = {
  subPage: PropTypes.string.isRequired,
};
export default SolutionDetail;
