// MUI
import { styled, Typography } from "@mui/material";
// theme
import { COLORS } from "styles/theme";

export const LeadershipMainBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  margin: "0 auto",
  padding: "100px 0",
  width: "100%",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
export const LeadershipMainLeft = styled("div")(() => ({
  background: "#fff",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "0.4s",
  position: "absolute",
  top: "0",
  left: "0",
  bottom: "0",
  width: "50%",
  "& img": {
    width: "70%",
  },
}));

export const LeadershipMainRight = styled("div")(({ theme }) => ({
  fontFamily: "Nexa Bold,sans-serif",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "1.5",
  background: COLORS.TEXT_WHITE,
  height: "100%",
  width: "50%",
  transition: "0.4s",
  position: "absolute",
  right: "0",
  top: "0",
  margin: "0",
  bottom: "0",
  zIndex: "0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "0 100px 0 68px",
  "&:after": {
    content: '""',
    backgroundColor: theme.palette.primary.yellow,
    width: "90%",
    height: "calc(100% + 100px)",
    position: "absolute",
    right: "-70px",
    zIndex: "-1",
    top: "-40px",
    padding: "10px",
    bottom: "0",
  },
  "&:before": {
    content: '""',
    background: "#303183",
    borderRadius: "0px 60px 0px 0px",
    width: "90%",
    height: "100%",
    position: "absolute",
    left: "0",
    top: "0",
    bottom: "0",
    zIndex: "0",
  },
}));

export const LeadershipMainTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN Pro",
  fontStyle: "normal",
  fontWeight: 900,
  fontSize: "70px",
  lineHeight: "84px",
  zIndex: "1",
  position: "relative",
  color: theme.palette.primary.yellow,
  [theme.breakpoints.down("md")]: {
    fontSize: "38px",
  },
}));

export const LeadershipMainSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "New Courier",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "28px",
  lineHeight: "37px",
  zIndex: 1,
  color: COLORS.TEXT_WHITE,
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    lineHeight: "1.4",
  },
}));
