import { Grid, Container } from "@mui/material";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import SECTIONS from "shared/sections";
import Cart from "./Cart";
import { CardWrapper, Section } from "./architecture.styles";

function Architecture() {
  const {
    ARCHITECTURE: { title, features },
  } = SECTIONS;

  return (
    <Section id="architecture">
      <Container maxWidth="xl">
        <SectionTitle title={title} />

        <CardWrapper>
          <Grid container columnSpacing={4} rowSpacing={4}>
            {features.map((feature, index) => (
              <Cart {...feature} key={index} />
            ))}
          </Grid>
        </CardWrapper>
      </Container>
    </Section>
  );
}

export default Architecture;
