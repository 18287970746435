import { useEffect, useRef } from "react";
import { Box } from "@mui/material";

// shared
import IMAGES from "shared/images";
import SECTIONS from "shared/sections";
// styles
import {
  LeadershipMainBox,
  LeadershipMainLeft,
  LeadershipMainRight,
  LeadershipMainSubTitle,
  LeadershipMainTitle,
} from "./leadershipMain.styles";

function LeadershipMain() {
  const elementRef = useRef(null);
  const { LEADERSHIP } = SECTIONS;
  // useEffect(() => {
  //   const bodyRect = document.body.getBoundingClientRect();
  //   const leaderContainerPosition = elementRef.current.getBoundingClientRect();
  //   const offset = Math.abs(bodyRect.top - leaderContainerPosition.top);
  //   const handleScroll = () => {
  //     const currentPosition = window.pageYOffset;

  //     if (currentPosition > offset) {
  //       elementRef.current.classList.add("LeadershipAnimBox");
  //     } else {
  //       elementRef.current.classList.remove("LeadershipAnimBox");
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);
  return (
    <LeadershipMainBox ref={elementRef} id="LeadershipMain">
      <LeadershipMainLeft className="LeadershipLeftBox">
        <Box
          component="img"
          src={IMAGES.TS_LOGO.src}
          alt={IMAGES.TS_LOGO.alt}
        />
      </LeadershipMainLeft>
      <LeadershipMainRight className="LeadershipRightBox">
        <LeadershipMainTitle>{LEADERSHIP.title}</LeadershipMainTitle>
        <LeadershipMainSubTitle>{LEADERSHIP.subTitle}</LeadershipMainSubTitle>
      </LeadershipMainRight>
    </LeadershipMainBox>
  );
}
export default LeadershipMain;
