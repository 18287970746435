// import CallAction from "components/CallAction/callaction.component";
import Awards from "components/Awards/awards.component";
import Contact from "components/Contact/contact.component";
import Hero from "components/Hero/hero.component";
import Hiring from "components/HiringSection/Hiring.component";
import History from "components/History/history.component";
import Leadership from "components/Leadership/leadership.component";
import Projects from "components/Projects/projects.component";
import WhoWeServe from "components/WhoWeServe/WhoWeServe.component";

function About() {
  return (
    <>
      <Hero page="About" />
      {/* <History page="About" /> */}
      {/* <CallAction /> */}
      <WhoWeServe />
      <Projects txtTitle="Our Projects" />
      <Leadership />
      <Hiring />
      <Awards />

      <Contact />
    </>
  );
}

export default About;
