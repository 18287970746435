import { styled, Tabs, Typography } from "@mui/material";

export const TabTitle = styled((props) => (
  <Typography variant="h4" {...props} />
))(({ theme }) => ({
  fontSize: "20px",
  color: theme.palette.text.black,
  textTransform: "uppercase",
  marginBottom: "15px",
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    alignItems: "start",
    gap: "20px",
    paddingBottom: "15px",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  "& .MuiTabScrollButton-root": {
    width: "fit-content",
  },

  "& .MuiTab-root": {
    background: theme.palette.text.white,
    // border: `1px solid ${theme.palette.border.light}`,
    // borderRadius: "10px",
    width: "100%",
    alignItems: "flex-start",
    textTransform: "capitalize",
    padding: "32px 20px",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      maxWidth: "fit-content",
      padding: theme.spacing(1),
    },
  },

  "& .MuiButtonBase-root.Mui-selected": {
    // boxShadow: "10px 20px 40px rgba(185, 186, 189, 0.3)",
    color: "#303183 ",
    position: "relative",
  },
  "& .MuiButtonBase-root.Mui-selected:after": {
    content: `""`,
    height: "3px",
    width: "56%",
    position: "absolute",
    bottom: "0px",
    left: "0px",
    background: "#303183",
  },

  "& .MuiButtonBase-root.Mui-selected .MuiTypography-root": {
    color: "#303183 ",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },
}));
