import styled from "@emotion/styled";
import {
  Box,
  DialogTitle,
  Typography,
  DialogContent,
  Dialog,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { COLORS } from "styles/theme";

export const ModalDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#595959",
}));
export const MarkIcon = styled(FiberManualRecordIcon)(({ theme }) => ({
  color: "#595959",
  fontSize: ".5rem",
}));

export const ModalDialog = styled(Dialog)(({ theme }) => ({
  height: "95%",
  "& .css-rudpty-MuiPaper-root-MuiDialog-paper": {
    maxWidth: "850px",
    maxHeight: "100%",
    paddingTop: "20px",
    top: "20px",
    paddingBottom: "21px",
    borderRadius: "16px",
  },
}));
export const ModalDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 900,
  paddingLeft: 0,
  fontSize: "20px",
  color: COLORS.PRIMARY_BLUE,
}));
export const ModalDialogContent = styled(DialogContent)(({ theme }) => ({
  border: "none",
  paddingTop: 0,
}));
export const ModalNormalText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: "#000",
  paddingTop: "0 ",
  paddingBottom: "0",
  paddingRight: "24px",
  "& span": {
    color: COLORS.PRIMARY_BLUE,
    fontWeight: 600,
    textDecoration: "underline",
  },
}));
export const ModalTitleDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  color: "#000",
}));
export const ModalContentImg = styled("img")(({ theme }) => ({
  objectFit: "contain",
  height: "26px",
  width: "26px",
}));
