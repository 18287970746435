import { Box, Button, Typography, styled } from "@mui/material";

import { COLORS } from "styles/theme";
import IMAGES from "shared/images";

export const HeroImg = styled("img")(({ theme }) => ({
  objectFit: "contain",
  width: "100%",
  height: "544px",
  [theme.breakpoints.down("lg")]: {
    height: "420px",
  },
  [theme.breakpoints.down("md")]: {
    height: "100%",
  },
}));
export const HeroRightSection = styled(Box)(({ theme }) => ({
  height: "630px",
  //   paddingTop: "62px",
  //   paddingBottom: "69px",
  //   background: "#DD9326",
  width: "100%",
  backgroundImage: `url(${IMAGES.HERO_BG_2.src})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  paddingLeft: "32px",
  paddingRight: "54px",
  [theme.breakpoints.down("md")]: {
    height: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "400px",
  },
}));
// export const HeroRightSectionContent = styled(Box)(({ theme }) => ({
//   background: "#30337E",
//   borderRadius: " 0px 200px 0px 0px",
//   position: "relative",
//   left: "-24px",
//   padding: "94px 31px",
//   [theme.breakpoints.down("lg")]: {
//     padding: " 14px",
//   },
// }));
export const HeroRightSectionContentTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.TEXT_WHITE,
  fontSize: "3.5rem",
  textTransform: "capitalize",
  marginBottom: "25px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "3rem",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));
export const HeroRightSectionContentBtn = styled(Button)(({ theme }) => ({
  color: COLORS.TEXT_WHITE,
  backgroundColor: "#2E7D32",
  fontFamily: "DIN Pro",
  fontStyle: "normal",
  fontWeight: 900,
  fontSize: "1rem",
  borderRadius: "4px",
  maxWidth: 150,
  "&:hover": { backgroundColor: "#1B4B1E" },
}));
