// MUI
import { styled, Box } from "@mui/material";
// shared
import IMAGES from "shared/images";

export const LeadershipWrp = styled(Box)(() => ({
  // backgroundImage: `url(${IMAGES?.LEADERSHIP?.src})`,
  // backgroundRepeat: "no-repeat",
  backgroundSize: "47%",
}));

export const LeadershipBox = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));
export const LeadershipMain = styled(Box)(() => ({
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
}));

export const CardBottomLogo = styled(Box)(({ theme }) => ({
  marginBottom: "106px",
  "&img": {
    width: "241px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "30px",
  },
}));
