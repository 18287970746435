import { Box, Grid } from "@mui/material";

function TabPanel({ brands, value, index, ...other }) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      {...other}
      sx={{ height: "100%" }}
    >
      <Grid
        container
        spacing={3}
        justifyContent="space-around"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        {value === index &&
          brands.map((brand, indx) => (
            <Grid item xs={6} md={4} sx={{ maxHeight: "170px" }} key={indx}>
              <Box
                component="img"
                src={brand.src}
                alt={brand.alt}
                width="100%"
                height="100%"
                sx={{
                  objectFit: "contain",
                  borderRadius: "10px",
                  padding: "5px",
                  maxWidth: "180px",
                  maxHeight: "115px",
                }}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default TabPanel;
