// MUI
import { Card, Stack, styled, Typography, Box, Button } from "@mui/material";
import Container from "@mui/material/Container";
// theme
import { COLORS } from "styles/theme";

export const CardBox = styled(Card)(({ theme }) => ({
  maxWidth: "368px",
  height: "100%",

  paddingBottom: "28px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

export const CardContentBox = styled(Box)(() => ({
  padding: "16px",
}));
export const CardContentDetails = styled(Typography)(() => ({
  padding: 0,
  maxHeight: 96,
  overflow: "hidden",
  fontFamily: "Nexa Bold",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "150%",
  letterSpacing: "0.15px",
  marginBottom: "16px",
  color: "#595959",
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
  whiteSpace: "normal",
}));

export const CardCategory = styled(Stack)(({ theme }) => ({
  fontFamily: "Lato",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  display: "flex",
  lineHeight: "157%",
  marginBottom: "8px",
  letterSpacing: "0.1px",
  color: theme.palette.primary.yellow,
}));

export const CardImage = styled("img")(({ theme }) => ({
  height: "368px",
  width: "368px",
  objectFit: "contain",
  objectPosition: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

export const CardDate = styled(Container)(({ theme }) => ({
  fontFamily: "Lato",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  display: "contents",
  color: theme.palette.secondary.green,
}));

export const CardTitle = styled(Typography)(() => ({
  lineHeight: "160%",
  marginBottom: "8px",
  letterSpacing: "0.15px",
  color: COLORS.PRIMARY_BLUE,
  display: "-webkit-box !important",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  whiteSpace: "normal",
  overflow: "hidden",
}));
export const BlogSubtitle = styled(Typography)(() => ({
  fontFamily: "Courier New",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "28px",
  maxWidth: "1073px",
  textAlign: "center",
  color: COLORS.TEXT_GREY,
}));

export const CardButton = styled(Button)(() => ({
  maxWidth: 120,
  minWidth: 100,
  marginTop: "auto",
  marginLeft: "18px",
}));
