import { Stack, styled } from "@mui/material";

export const ImageWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1, 2),
  height: "100%",
  minHeight: 250,
  maxWidth: 250,
  // boxShadow:
  //   "0px 13px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.08)",
  borderRadius: "15px",
  background: "white",
  margin: "auto",
}));

export default ImageWrapper;
