import { Box } from "@mui/material";

function Section({ children, ...other }) {
  return (
    <Box component="section" py={10} position="relative" {...other}>
      {children}
    </Box>
  );
}

export default Section;
