import BRUNO_CORE_ICON from "assets/images/bruno_core.webp";
import BRUNO_BESPOKE_ICON from "assets/images/bruno_bespoke.webp";
import BRUNO_API_ICON from "assets/images/bruno_api.webp";
import BRUNO_ENGINE_ICON from "assets/images/bruno_engine.webp";
import SECURITY_ICON from "assets/images/security_icon.webp";
import API_READY_ICON from "assets/images/api_ready_icon.webp";
import MULTI_ASSET_ICON from "assets/images/multi_asset_icon.webp";
import TECHNOLOGY_STACK_ICON from "assets/images/technology_stack_icon.webp";
import CLOUD_HOSTING_ICON from "assets/images/cloud_hosting_icon.webp";
import MARKETPLACE_ICON from "assets/images/marketplace_icon.webp";
import { ReactComponent as HOME_PRODUCTS_BG_COUNTDOWN_SVG } from "assets/images/products_bg_countdown.svg";
import PRODUCTS_BG_SHAPE_GREEN from "assets/images/products_bg_shape_green.webp";
import HOME_PRODUCTS_PHONE_PROTOTYPE_IMAGE from "assets/images/home_products_phone_prototype_image.webp";
import PRODUCTS_BG_SHAPE_BLUE from "assets/images/products_bg_shape_blue.webp";
import PRODUCTS_BG_SHAPE_ORANGE from "assets/images/products_bg_shape_orange.svg";
import ARCHITECTURE_BG_SHAPE_BLUE from "assets/images/architecture_bg_shape_blue.webp";

// Projects
// import JFD_BANK_IMAGE from "assets/images/brands/jfdlogoBrand.webp";
// import PROJECTS_APEX_FINTECH_IMAGE from "assets/images/brands/ApexFinBrand.webp";
// import PROJECTS_INVESTEC_IMAGE from "assets/images/brands/InvestecBrand.webp";
// import PROJECTS_GO_MARKETS_IMAGE from "assets/images/brands/GoMarketBrand.webp";
import PROJECTS_1 from "assets/images/projects_1.png";
import PROJECTS_2 from "assets/images/projects_2.png";
import PROJECTS_3 from "assets/images/projects_3.png";
import PROJECTS_4 from "assets/images/projects_4.png";
import PROJECTS_5 from "assets/images/projects_5.png";
import PROJECTS_6 from "assets/images/projects_6.png";
import PROJECTS_7 from "assets/images/projects_7.png";
import PROJECTS_8 from "assets/images/projects_8.png";
import PROJECTS_9 from "assets/images/projects_9.png";
import PROJECTS_10 from "assets/images/projects_10.png";

// Who We Serve
import WHO_WE_SERVE_1 from "assets/images/who_serve_1.svg";
import WHO_WE_SERVE_2 from "assets/images/who_serve_2.svg";
import WHO_WE_SERVE_3 from "assets/images/who_serve_3.svg";
import WHO_WE_SERVE_4 from "assets/images/who_serve_4.svg";

//  choose your product
import CHOOSE_PRODUCT_SELF from "assets/images/choose_product_self.png";
import CHOOSE_PRODUCT_DIGITAL from "assets/images/choose_product_digital.png";
import CHOOSE_PRODUCT_THIMATIC from "assets/images/choose_product_thematic.png";
//  choose your package
import CHOOSE_PACKAGE_IMG from "assets/images/choose_package_img.png";

// Navbar
import TS_LOGO_SVG from "assets/images/ts_logo.svg";
import { ReactComponent as NAV_MENU_ICON_SVG } from "assets/images/nav_menu_icon.svg";
import { ReactComponent as TS_LOGO_ICON_SVG } from "assets/images/ts_logo_icon.svg";

// Home / Hero
import HERO_LAPTOP_IMAGE from "assets/images/hero_laptop_image.webp";
import HERO_BG from "assets/images/hero_bg.png";
import HERO_BG_2 from "assets/images/hero_bg_2.png";
import HERO_BG_SHAPE_RED_IMAGE from "assets/images/hero_bg_shape_red.svg";
import HERO_BG_SHAPE_YELLOW_IMAGE from "assets/images/hero_bg_shape_yellow.svg";
import { ReactComponent as HOME_HERO_BRUNO_LOGO_SVG } from "assets/images/home_hero_bruno_logo.svg";

// Home / Features
import FEATURES_IMAGE from "assets/images/feature-image.webp";
import FEATURES_BRUNO from "assets/images/features_bruno.png";
import FEATURES_ICON_1 from "assets/images/features_icon_1.png";
import FEATURES_ICON_2 from "assets/images/features_icon_2.png";
import FEATURES_ICON_3 from "assets/images/features_icon_3.png";
import FEATURES_ICON_4 from "assets/images/features_icon_4.png";
// Home / Delivery Plans
import PLANS_CHECK_CIRCLE_GREEN_IMAGE from "assets/images/plans_check_circle_green.svg";
import PLANS_CHECK_CIRCLE_WHITE_IMAGE from "assets/images/plans_check_circle_white.svg";
// Home / Short History
import HOME_HISTORY_BG_IMAGE from "assets/images/home_history_bg_image.webp";

import BLOG_1 from "assets/images/blog_1.webp";
import BLOG_2 from "assets/images/blog_2.webp";
import BLOG_3 from "assets/images/blog_3.webp";
import QUESTION_1 from "assets/images/question-1.webp";
import QUESTION_2 from "assets/images/question-2.webp";
import FACEBOOK from "assets/images/facebook.webp";
import INSTAGRAM from "assets/images/instagram.webp";
import PINTEREST from "assets/images/pinterest.webp";
import LINKDIN from "assets/images/linkdin.webp";
import LEADER1_IMAGE from "assets/images/Leader-1.webp";
import LEADER2_IMAGE from "assets/images/Leader-2.webp";
import LEADER3_IMAGE from "assets/images/Leader-3.webp";
import DENNIS_IMAGE from "assets/images/dennis.webp";
import POLYGON from "assets/images/Polygon.webp";
import LEADERSHIP from "assets/images/LeadershipImage.webp";

// Features page
import SELF_TRADING_HEADER from "assets/images/self_trading_header.png";
import SELF_TRADING_FEATURE_ICON_1 from "assets/images/self_trading_feature_icon_1.png";
import SELF_TRADING_FEATURE_ICON_2 from "assets/images/self_trading_feature_icon_2.png";
import SELF_TRADING_FEATURE_ICON_3 from "assets/images/self_trading_feature_icon_3.png";
import SELF_TRADING_MARKET_DATA from "assets/images/self_trading_market_data.png";
import SELF_TRADING_CONCLUSION_ICON from "assets/images/self_trading_conclusion_icon.png";
import SELF_TRADING_CONCLUSION_BACKGROUND from "assets/images/self_trading_conclusion_background.png";

// Thematic Baskets
import THEMATIC_BASKET_HEADER from "assets/images/thematic_baskets_header.png";
import THEMATIC_MARKET_DATA from "assets/images/thematic_baskets_market_data.png";
import THEMATIC_BASKET_FEATURE_ICON_1 from "assets/images/thematic_baskets_feature_icon_1.png";
import THEMATIC_BASKET_FEATURE_ICON_2 from "assets/images/thematic_baskets_feature_icon_2.png";
import THEMATIC_BASKET_FEATURE_ICON_3 from "assets/images/thematic_baskets_feature_icon_3.png";
import THEMATIC_BASKET_FEATURE_ICON_4 from "assets/images/thematic_baskets_feature_icon_4.png";
import THEMATIC_BASKET_FEATURE_ICON_5 from "assets/images/thematic_baskets_feature_icon_5.png";
import THEMATIC_BASKET_FEATURE_ICON_6 from "assets/images/thematic_baskets_feature_icon_6.png";
import THEMATIC_BASKET_ANALYTIC_BACKGROUND from "assets/images/thematic_baskets_analytical_background.png";
import THEMATIC_BASKET_ANALYTIC_ICON from "assets/images/thematic_baskets_analytical_icon.png";
import THEMATIC_BASKET_BENEFITS_BANKS from "assets/images/thematic_baskets_benefits_banks.png";
import THEMATIC_BASKET_BENEFITS_BANKS_BACKGROUND from "assets/images/thematic_baskets_benefits_banks_background.png";
import THEMATIC_BASKET_BENEFITS_BANKS_BACKGROUND_2 from "assets/images/thematic_baskets_benefits_banks_background_2.png";
import THEMATIC_BASKET_BENEFIT_CUSTOMERS from "assets/images/thematic_baskets_benefits_customers.png";
import THEMATIC_BASKET_BENEFIT_CUSTOMERS_BACKGROUND from "assets/images/thematic_baskets_benefits_customers_background.png";
import THEMATIC_BASKET_REGIONAL_ICON_1 from "assets/images/thematic_baskets_regional_icon_1.png";
import THEMATIC_BASKET_REGIONAL_ICON_2 from "assets/images/thematic_baskets_regional_icon_2.png";
import THEMATIC_BASKET_REGIONAL_ICON_3 from "assets/images/thematic_baskets_regional_icon_3.png";
import THEMATIC_BASKET_REGIONAL_ICON_4 from "assets/images/thematic_baskets_regional_icon_4.png";
import THEMATIC_BASKET_REGIONAL_ICON_5 from "assets/images/thematic_baskets_regional_icon_5.png";
import THEMATIC_BASKET_USECASE_IMG from "assets/images/thematic_baskets_usecase_img.png";

// Goal Based
import GOAL_BASED_HEADER from "assets/images/goal_based_header.png";
import GOAL_BASED_GETTING_STARTED from "assets/images/goal_based_getting_started.svg";
import GOAL_BASED_TYPES_GOALS from "assets/images/goal_based_types_goals.png";
import GOAL_BASED_TYPES_GOALS_ICON from "assets/images/goal_based_types_goals_Icon.png";
import GOAL_BASED_USE_CASE from "assets/images/goal_based_use_case.png";
// Contact
import ContactBackground from "assets/images/contactBackground.png";
import TSLogo from "assets/images/contact/TSLogo.svg";
import PhoneIcon from "assets/images/contact/icons/phoneIcon.svg";
import EmailIcon from "assets/images/contact/icons/emailIcon.svg";
import LocationIcon from "assets/images/contact/icons/locationIcon.svg";
// Market Place
import ARK_DEV_IMAGE from "assets/images/marketplace/3rdParty/arkdev.webp";
import FX_CUBIC_IMAGE from "assets/images/marketplace/Aggregators/fxcubic.webp";
import GOLD_I_IMAGE from "assets/images/marketplace/Aggregators/goldI.jpg";
import ONE_ZERO_IMAGE from "assets/images/marketplace/Aggregators/onezero.webp";
import PRIME_XM_IMAGE from "assets/images/marketplace/Aggregators/PrimeXM.webp";
import AWS_IMAGE from "assets/images/marketplace/Architecture/aws.webp";
import DATA_DOG_IMAGE from "assets/images/marketplace/Architecture/datadog.webp";
import PARTNER_IMAGE from "assets/images/marketplace/Architecture/partner.svg";
import QUALYS_IMAGE from "assets/images/marketplace/Architecture/qualys.svg";
import ALPACA_IMAGE from "assets/images/marketplace/ClearingBrokers/alpacamarkets.webp";
import APEX_FIN_IMAGE from "assets/images/marketplace/ClearingBrokers/ApexFin.webp";
import DRIVE_WEALTH_IMAGE from "assets/images/marketplace/ClearingBrokers/drivewealth.webp";
import INTERACTIVE_BROKERS_IMAGE from "assets/images/marketplace/ClearingBrokers/InteractiveBrokers.webp";
import SAXO_BE_INVESTED_IMAGE from "assets/images/marketplace/ClearingBrokers/SAXOBeInvested.webp";
import INTERCOM_IMAGE from "assets/images/marketplace/Engagement/intercom.webp";
import TWILIO_IMAGE from "assets/images/marketplace/Engagement/twilio.webp";
import JUMIO_IMAGE from "assets/images/marketplace/IDValidation/Jumio.svg";
import TRULIOO_IMAGE from "assets/images/marketplace/IDValidation/Trulioo.svg";
import BLOOM_BERG_IMAGE from "assets/images/marketplace/MarketDataProviders/bloomberg.webp";
import FACT_SET_IMAGE from "assets/images/marketplace/MarketDataProviders/factset.webp";
import THOMSON_REUTERS_IMAGE from "assets/images/marketplace/MarketDataProviders/ThomsonReuters.webp";
import PLAID_IMAGE from "assets/images/marketplace/PaymentandWealthAggregation/Plaid.webp";
// new market place

import MARKET_BROKERAGE_1 from "assets/images/market_brokerage_1.png";
import MARKET_BROKERAGE_2 from "assets/images/market_brokerage_2.png";
import MARKET_BROKERAGE_3 from "assets/images/market_brokerage_3.png";
import MARKET_BROKERAGE_4 from "assets/images/market_brokerage_4.png";
import MARKET_BROKERAGE_5 from "assets/images/market_brokerage_5.png";
import MARKET_DMS_1 from "assets/images/market_dms_1.png";
import MARKET_DMS_2 from "assets/images/market_dms_2.png";
import MARKET_DMS_3 from "assets/images/market_dms_3.png";
import MARKET_DMS_4 from "assets/images/market_dms_4.png";
import MARKET_DMS_5 from "assets/images/market_dms_5.png";
import MARKET_MARKET_1 from "assets/images/market_market_1.png";
import MARKET_MARKET_2 from "assets/images/market_market_2.png";
import MARKET_MARKET_3 from "assets/images/market_market_3.png";
import MARKET_OTHER_1 from "assets/images/market_other_1.png";
import MARKET_OTHER_2 from "assets/images/market_other_2.png";
import MARKET_OTHER_3 from "assets/images/market_other_3.png";
import MARKET_OTHER_4 from "assets/images/market_other_4.png";
import MARKET_OTHER_5 from "assets/images/market_other_5.png";
// About  Us Hero
import ABOUT_US_HERO from "assets/images/about_us_hero.png";
import ABOUT_US_HERO_2 from "assets/images/about_us_hero_2.png";
import ABOUT_US_BG from "assets/images/about_us_bg.png";
import ABOUT_US_ICON_1 from "assets/images/about_us_icon_1.png";
import ABOUT_US_ICON_2 from "assets/images/about_us_icon_2.png";
import ABOUT_US_ICON_3 from "assets/images/about_us_icon_3.png";
import ABOUT_US_ICON_4 from "assets/images/about_us_icon_4.png";

// Awards
import GLOBAL_FOREX_AWARDS from "assets/images/brands/GFABrand.webp";
import GLOBAL_FOREX_B2B_AWARDS from "assets/images/brands/GFAB2BBrand.webp";
import MEA_FINANCE_AWARDS from "assets/images/brands/MEAFBrand.webp";
import CLEAR_VIEW_FINANCIAL_AWARDS from "assets/images/brands/ClearViewFinancialBrand.webp";
import WEALTH_TECH_100_AWARDS from "assets/images/brands/WealthTechBrand.webp";
import FRANKLIN_TEMPLETON_AWARDS from "assets/images/brands/FranklinBrand.webp";

// About Hiring Section

import HIRING_MODAL_ICON from "assets/images/hiring_modal_icon.svg";
// Call Action
// -- Call Action Images
import CallActionBG from "assets/images/callAction/callActionBG.webp";
// About / History
import ABOUT_HISTORY_IMAGE from "assets/images/about_history_image.webp";

// Products / Hero
import PRODUCTS_HERO_PROTOTYPE_IMAGE from "assets/images/products_hero_prototype_image.webp";

// Products / Benefits
import { ReactComponent as PRODUCTS_BRUNO_CORE_BENEFITS_ICON_1_SVG } from "assets/images/products_bruno_core_benefits_1.svg";
import { ReactComponent as PRODUCTS_BRUNO_CORE_BENEFITS_ICON_2_SVG } from "assets/images/products_bruno_core_benefits_2.svg";
import { ReactComponent as PRODUCTS_BRUNO_CORE_BENEFITS_ICON_3_SVG } from "assets/images/products_bruno_core_benefits_3.svg";
import { ReactComponent as PRODUCTS_BRUNO_CORE_BENEFITS_ICON_4_SVG } from "assets/images/products_bruno_core_benefits_4.svg";
import { ReactComponent as PRODUCTS_BRUNO_CORE_BENEFITS_ICON_5_SVG } from "assets/images/products_bruno_core_benefits_5.svg";

import { ReactComponent as PRODUCTS_BRUNO_API_BENEFITS_ICON_1_SVG } from "assets/images/products_bruno_api_benefits_1.svg";
import { ReactComponent as PRODUCTS_BRUNO_API_BENEFITS_ICON_2_SVG } from "assets/images/products_bruno_api_benefits_2.svg";
import { ReactComponent as PRODUCTS_BRUNO_API_BENEFITS_ICON_3_SVG } from "assets/images/products_bruno_api_benefits_3.svg";
import { ReactComponent as PRODUCTS_BRUNO_API_BENEFITS_ICON_4_SVG } from "assets/images/products_bruno_api_benefits_4.svg";
import { ReactComponent as PRODUCTS_BRUNO_API_BENEFITS_ICON_5_SVG } from "assets/images/products_bruno_api_benefits_5.svg";

import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_1_SVG } from "assets/images/products_bruno_bespoke_benefits_1.svg";
import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_2_SVG } from "assets/images/products_bruno_bespoke_benefits_2.svg";
import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_3_SVG } from "assets/images/products_bruno_bespoke_benefits_3.svg";
import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_4_SVG } from "assets/images/products_bruno_bespoke_benefits_4.svg";
import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_5_SVG } from "assets/images/products_bruno_bespoke_benefits_5.svg";
import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_6_SVG } from "assets/images/products_bruno_bespoke_benefits_6.svg";
import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_7_SVG } from "assets/images/products_bruno_bespoke_benefits_7.svg";
import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_8_SVG } from "assets/images/products_bruno_bespoke_benefits_8.svg";
import { ReactComponent as PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_9_SVG } from "assets/images/products_bruno_bespoke_benefits_9.svg";

import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_1_SVG } from "assets/images/products_bruno_engine_benefits_1.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_2_SVG } from "assets/images/products_bruno_engine_benefits_2.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_3_SVG } from "assets/images/products_bruno_engine_benefits_3.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_4_SVG } from "assets/images/products_bruno_engine_benefits_4.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_5_SVG } from "assets/images/products_bruno_engine_benefits_5.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_6_SVG } from "assets/images/products_bruno_engine_benefits_6.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_7_SVG } from "assets/images/products_bruno_engine_benefits_7.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_8_SVG } from "assets/images/products_bruno_engine_benefits_8.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_9_SVG } from "assets/images/products_bruno_engine_benefits_9.svg";
import { ReactComponent as PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_10_SVG } from "assets/images/products_bruno_engine_benefits_10.svg";

import { ReactComponent as PRODUCTS_BENEFITS_BG_SHAPE_RED_SVG } from "assets/images/products_benefits_bg_shape_red.svg";
import { ReactComponent as PRODUCTS_BENEFITS_BG_SHAPE_GREEN_SVG } from "assets/images/products_benefits_bg_shape_green.svg";

import PRODUCTS_USE_CASE_IMAGE from "assets/images/products_use_case_image.webp";

// Legal
import LEGAL_TS_LOGO_IMAGE from "assets/images/legal_ts_logo.svg";

// Solutions / Preview Cards
import SOLUTIONS_DIGITAL_BANKS_IMAGE from "assets/images/solutions_digital_banks_image.webp";
import SOLUTIONS_BROKERAGE_IMAGE from "assets/images/solutions_brokerage_image.webp";
import SOLUTIONS_ADVISORS_IMAGE from "assets/images/solutions_advisors_image.webp";

// Solutions / Background Shapes
import { ReactComponent as SOLUTIONS_BG_SHAPE_RED_SVG } from "assets/images/solutions_bg_shape_red.svg";
import { ReactComponent as SOLUTIONS_BG_SHAPE_GREEN_SVG } from "assets/images/solutions_bg_shape_green.svg";

// Solution / Detail
import { ReactComponent as SOLUTION_HERO_SHAPE_RED_SVG } from "assets/images/solution_hero_bg_shape_red.svg";
import { ReactComponent as SOLUTION_HERO_BG_SVG } from "assets/images/solutions_hero_bg.svg";

import SOLUTION_HERO_DIGITAL_BANKS_IMAGE from "assets/images/solution_hero_digital_banks_image.webp";
import SOLUTION_HERO_BROKERAGE_IMAGE from "assets/images/solution_hero_brokerage_image.webp";
import SOLUTION_HERO_ADVISORS_IMAGE from "assets/images/solution_hero_advisors_image.webp";

import SOLUTION_ARCHITECTURE_PROTOTYPE_IMAGE from "assets/images/solution_architecture_prototype_image.webp";

// Section / Detail / Features
import { ReactComponent as SOLUTION_FEATURE_DIGITAL_BANKS_ICON_1_SVG } from "assets/images/solution_feature_digital_banks_icon_1.svg";
import { ReactComponent as SOLUTION_FEATURE_DIGITAL_BANKS_ICON_2_SVG } from "assets/images/solution_feature_digital_banks_icon_2.svg";
import { ReactComponent as SOLUTION_FEATURE_DIGITAL_BANKS_ICON_3_SVG } from "assets/images/solution_feature_digital_banks_icon_3.svg";

import { ReactComponent as SOLUTION_FEATURE_BROKERAGE_ICON_1_SVG } from "assets/images/solution_feature_brokerage_icon_1.svg";
import { ReactComponent as SOLUTION_FEATURE_BROKERAGE_ICON_2_SVG } from "assets/images/solution_feature_brokerage_icon_2.svg";
import { ReactComponent as SOLUTION_FEATURE_BROKERAGE_ICON_3_SVG } from "assets/images/solution_feature_brokerage_icon_3.svg";
import { ReactComponent as SOLUTION_FEATURE_BROKERAGE_ICON_4_SVG } from "assets/images/solution_feature_brokerage_icon_4.svg";
import { ReactComponent as SOLUTION_FEATURE_BROKERAGE_ICON_5_SVG } from "assets/images/solution_feature_brokerage_icon_5.svg";
import { ReactComponent as SOLUTION_FEATURE_BROKERAGE_ICON_6_SVG } from "assets/images/solution_feature_brokerage_icon_6.svg";
import { ReactComponent as SOLUTION_FEATURE_BROKERAGE_ICON_7_SVG } from "assets/images/solution_feature_brokerage_icon_7.svg";

import { ReactComponent as SOLUTION_FEATURE_ADVISORS_ICON_1_SVG } from "assets/images/solution_feature_advisors_icon_1.svg";
import { ReactComponent as SOLUTION_FEATURE_ADVISORS_ICON_2_SVG } from "assets/images/solution_feature_advisors_icon_2.svg";
import { ReactComponent as SOLUTION_FEATURE_ADVISORS_ICON_3_SVG } from "assets/images/solution_feature_advisors_icon_3.svg";
import { ReactComponent as SOLUTION_FEATURE_ADVISORS_ICON_4_SVG } from "assets/images/solution_feature_advisors_icon_4.svg";
import { ReactComponent as SOLUTION_FEATURE_ADVISORS_ICON_5_SVG } from "assets/images/solution_feature_advisors_icon_5.svg";
import { ReactComponent as SOLUTION_FEATURE_ADVISORS_ICON_6_SVG } from "assets/images/solution_feature_advisors_icon_6.svg";

// Solutions / Architecture / Cards

import { ReactComponent as SOLUTION_ARCHITECTURE_BG_SHAPE_GREEN_SVG } from "assets/images/solution_architecture_bg_shape_green.svg";
import { ReactComponent as SOLUTION_ARCHITECTURE_BG_SHAPE_RED_SVG } from "assets/images/solution_architecture_bg_shape_red.svg";
import { ReactComponent as SOLUTION_ARCHITECTURE_BG_SHAPE_YELLOW_SVG } from "assets/images/solution_architecture_bg_shape_yellow.svg";

import SOLUTION_ARCHITECTURE_DIGITAL_BANKS_INTEGRATION_IMAGE from "assets/images/solution_architecture_digital_banks_integration_image.webp";
import SOLUTION_ARCHITECTURE_DIGITAL_BANKS_SECURITY_IMAGE from "assets/images/solution_architecture_digital_banks_security_image.webp";
import SOLUTION_ARCHITECTURE_DIGITAL_BANKS_SCALABILITY_IMAGE from "assets/images/solution_architecture_digital_banks_scalability_image.webp";

import SOLUTION_ARCHITECTURE_BROKERAGE_CUSTOMIZATION_IMAGE from "assets/images/solution_architecture_brokerage_customization_image.webp";
import SOLUTION_ARCHITECTURE_BROKERAGE_SUPPORT_IMAGE from "assets/images/solution_architecture_brokerage_support_image.webp";

import SOLUTION_ARCHITECTURE_ADVISORS_CUSTOMIZATION_IMAGE from "assets/images/solution_architecture_advisors_customization_image.webp";
import SOLUTION_ARCHITECTURE_ADVISORS_SUPPORT_IMAGE from "assets/images/solution_architecture_advisors_support_image.webp";

const IMAGES = {
  // Navbar
  TS_LOGO: {
    src: TS_LOGO_SVG,
    alt: "Tradesocio",
  },
  NAV_MENU_ICON_SVG,
  TS_LOGO_ICON_SVG,
  // Home / Hero
  HERO_LAPTOP: {
    src: HERO_LAPTOP_IMAGE,
    alt: "Laptop and Smart Phone",
  },
  HERO_BG: {
    src: HERO_BG,
    alt: "",
  },
  HERO_BG_2: {
    src: HERO_BG_2,
    alt: "",
  },
  HERO_BG_SHAPE_RED: {
    src: HERO_BG_SHAPE_RED_IMAGE,
    alt: "",
  },
  HERO_BG_SHAPE_YELLOW: {
    src: HERO_BG_SHAPE_YELLOW_IMAGE,
    alt: "",
  },
  HOME_HERO_BRUNO_LOGO_SVG,
  FEATURES_IMAGE: {
    src: FEATURES_IMAGE,
    alt: "Features Image",
  },
  FEATURES_BRUNO: {
    src: FEATURES_BRUNO,
    alt: "Features Image",
  },
  FEATURES_ICON_1: {
    src: FEATURES_ICON_1,
    alt: "Features Image",
  },
  FEATURES_ICON_2: {
    src: FEATURES_ICON_2,
    alt: "Features Image",
  },
  FEATURES_ICON_3: {
    src: FEATURES_ICON_3,
    alt: "Features Image",
  },
  FEATURES_ICON_4: {
    src: FEATURES_ICON_4,
    alt: "Features Image",
  },
  // Home / History
  HOME_HISTORY_BG: {
    src: HOME_HISTORY_BG_IMAGE,
    alt: "",
  },

  // Blog
  BLOG_1: {
    src: BLOG_1,
    alt: "",
  },
  BLOG_2: {
    src: BLOG_2,
    alt: "",
  },
  BLOG_3: {
    src: BLOG_3,
    alt: "",
  },
  // Question
  QUESTION_1: {
    src: QUESTION_1,
    alt: "",
  },
  QUESTION_2: {
    src: QUESTION_2,
    alt: "",
  },
  // Leadership
  FACEBOOK: {
    src: FACEBOOK,
    alt: "",
  },
  INSTAGRAM: {
    src: INSTAGRAM,
    alt: "",
  },
  PINTEREST: {
    src: PINTEREST,
    alt: "",
  },
  LINKDIN: {
    src: LINKDIN,
    alt: "",
  },
  LEADER1: {
    src: LEADER1_IMAGE,
    alt: "",
  },
  LEADER2: {
    src: LEADER2_IMAGE,
    alt: "",
  },
  LEADER3: {
    src: LEADER3_IMAGE,
    alt: "",
  },
  LEADER4: {
    src: DENNIS_IMAGE,
    alt: "",
  },
  POLYGON: {
    src: POLYGON,
    alt: "",
  },
  LEADERSHIP: {
    src: LEADERSHIP,
    alt: "",
  },
  // Bruno Products
  HOME_PRODUCTS_BG_COUNTDOWN_SVG,
  PRODUCTS_BG_SHAPE_GREEN: {
    src: PRODUCTS_BG_SHAPE_GREEN,
    alt: "",
  },
  BRUNO_CORE_ICON: {
    src: BRUNO_CORE_ICON,
    alt: "Bruno Core",
  },
  BRUNO_BESPOKE_ICON: {
    src: BRUNO_BESPOKE_ICON,
    alt: "Bruno Bespoke",
  },
  BRUNO_API_ICON: {
    src: BRUNO_API_ICON,
    alt: "Bruno Api",
  },
  BRUNO_ENGINE_ICON: {
    src: BRUNO_ENGINE_ICON,
    alt: "Bruno Engine",
  },
  CHOOSE_PRODUCT_SELF: {
    src: CHOOSE_PRODUCT_SELF,
    alt: "",
  },
  CHOOSE_PRODUCT_DIGITAL: {
    src: CHOOSE_PRODUCT_DIGITAL,
    alt: "",
  },
  CHOOSE_PRODUCT_THIMATIC: {
    src: CHOOSE_PRODUCT_THIMATIC,
    alt: "",
  },
  CHOOSE_PACKAGE_IMG: {
    src: CHOOSE_PACKAGE_IMG,
    alt: "",
  },
  HOME_PRODUCTS_PHONE_PROTOTYPE: {
    src: HOME_PRODUCTS_PHONE_PROTOTYPE_IMAGE,
    alt: "Bruno Products",
  },
  PRODUCTS_BG_SHAPE_BLUE: {
    src: PRODUCTS_BG_SHAPE_BLUE,
    alt: "",
  },
  PRODUCTS_BG_SHAPE_ORANGE: {
    src: PRODUCTS_BG_SHAPE_ORANGE,
    alt: "",
  },
  // Features Self Trading
  SELF_TRADING_HEADER: {
    src: SELF_TRADING_HEADER,
  },
  SELF_TRADING_FEATURE_ICON_1: {
    src: SELF_TRADING_FEATURE_ICON_1,
    alt: "feature Icon One",
  },
  SELF_TRADING_FEATURE_ICON_2: {
    src: SELF_TRADING_FEATURE_ICON_2,
    alt: "featureIconTwo",
  },
  SELF_TRADING_FEATURE_ICON_3: {
    src: SELF_TRADING_FEATURE_ICON_3,
    alt: "featureIconThree",
  },
  SELF_TRADING_MARKET_DATA: {
    src: SELF_TRADING_MARKET_DATA,
    alt: "Market Data",
  },
  SELF_TRADING_CONCLUSION_ICON: {
    src: SELF_TRADING_CONCLUSION_ICON,
    alt: "Feature Conclusion",
  },

  SELF_TRADING_CONCLUSION_BACKGROUND: {
    src: SELF_TRADING_CONCLUSION_BACKGROUND,
    alt: "Feature Conversation background",
  },

  //  Features Thematic Basket Page
  THEMATIC_BASKET_HEADER: {
    src: THEMATIC_BASKET_HEADER,
    alt: "",
  },
  THEMATIC_BASKET_ANALYTIC_BACKGROUND: {
    src: THEMATIC_BASKET_ANALYTIC_BACKGROUND,
    alt: "THEMATIC BASKET ANALYTIC BACKGROUND",
  },
  THEMATIC_BASKET_ANALYTIC_ICON: {
    src: THEMATIC_BASKET_ANALYTIC_ICON,
    alt: "THEMATIC BASKET ANALYTIC ICON",
  },
  THEMATIC_BASKET_BENEFITS_BANKS: {
    src: THEMATIC_BASKET_BENEFITS_BANKS,
    alt: "THEMATIC BASKET BENEFITS BANKS",
  },
  THEMATIC_BASKET_BENEFITS_BANKS_BACKGROUND: {
    src: THEMATIC_BASKET_BENEFITS_BANKS_BACKGROUND,
    alt: "THEMATIC BASKET BENEFITS BANKS BACKGROUND",
  },
  THEMATIC_BASKET_BENEFIT_CUSTOMERS: {
    src: THEMATIC_BASKET_BENEFIT_CUSTOMERS,
    alt: "THEMATIC BASKET BENEFIT CUSTOMERS",
  },
  THEMATIC_BASKET_BENEFIT_CUSTOMERS_BACKGROUND: {
    src: THEMATIC_BASKET_BENEFIT_CUSTOMERS_BACKGROUND,
    alt: "THEMATIC BASKET BENEFIT CUSTOMERS BACKGROUND",
  },
  THEMATIC_BASKET_FEATURE_ICON_1: {
    src: THEMATIC_BASKET_FEATURE_ICON_1,
    alt: "THEMATIC BASKET FEATURE ICON 1",
  },
  THEMATIC_BASKET_FEATURE_ICON_2: {
    src: THEMATIC_BASKET_FEATURE_ICON_2,
    alt: "THEMATIC BASKET FEATURE ICON 2",
  },
  THEMATIC_BASKET_FEATURE_ICON_3: {
    src: THEMATIC_BASKET_FEATURE_ICON_3,
    alt: "THEMATIC BASKET FEATURE ICON 3",
  },
  THEMATIC_BASKET_FEATURE_ICON_4: {
    src: THEMATIC_BASKET_FEATURE_ICON_4,
    alt: "THEMATIC BASKET FEATURE ICON 4",
  },
  THEMATIC_BASKET_FEATURE_ICON_5: {
    src: THEMATIC_BASKET_FEATURE_ICON_5,
    alt: "THEMATIC BASKET FEATURE ICON 5",
  },
  THEMATIC_BASKET_FEATURE_ICON_6: {
    src: THEMATIC_BASKET_FEATURE_ICON_6,
    alt: "THEMATIC BASKET FEATURE ICON 6",
  },
  THEMATIC_BASKET_REGIONAL_ICON_1: {
    src: THEMATIC_BASKET_REGIONAL_ICON_1,
    alt: "THEMATIC BASKET REGIONAL ICON 1",
  },
  THEMATIC_BASKET_REGIONAL_ICON_2: {
    src: THEMATIC_BASKET_REGIONAL_ICON_2,
    alt: "THEMATIC BASKET REGIONAL ICON 2",
  },
  THEMATIC_BASKET_REGIONAL_ICON_3: {
    src: THEMATIC_BASKET_REGIONAL_ICON_3,
    alt: "THEMATIC BASKET REGIONAL ICON 3",
  },
  THEMATIC_BASKET_REGIONAL_ICON_4: {
    src: THEMATIC_BASKET_REGIONAL_ICON_4,
    alt: "THEMATIC BASKET REGIONAL ICON 4",
  },
  THEMATIC_BASKET_REGIONAL_ICON_5: {
    src: THEMATIC_BASKET_REGIONAL_ICON_5,
    alt: "THEMATIC BASKET REGIONAL ICON 5",
  },
  THEMATIC_BASKET_BENEFITS_BANKS_BACKGROUND_2: {
    src: THEMATIC_BASKET_BENEFITS_BANKS_BACKGROUND_2,
    alt: "THEMATIC_BASKET USECASE BACKGROUND 2",
  },
  THEMATIC_BASKET_USCASE_IMG: {
    src: THEMATIC_BASKET_USECASE_IMG,
    alt: "THEMATIC BASKET USECASE IMG",
  },
  THEMATIC_MARKET_DATA: {
    src: THEMATIC_MARKET_DATA,
    alt: "THEMATIC MARKET DATA",
  },
  // Goal Based
  GOAL_BASED_HEADER: {
    src: GOAL_BASED_HEADER,
    alt: "GOAL BASED HEADER",
  },
  GOAL_BASED_GETTING_STARTED: {
    src: GOAL_BASED_GETTING_STARTED,
    alt: "GOAL BASED GETTING STARTED",
  },
  GOAL_BASED_TYPES_GOALS: {
    src: GOAL_BASED_TYPES_GOALS,
    alt: "GOAL BASED TYPES GOALS",
  },
  GOAL_BASED_USE_CASE: {
    src: GOAL_BASED_USE_CASE,
    alt: "GOAL BASED USE CASE",
  },
  GOAL_BASED_TYPES_GOALS_ICON: {
    src: GOAL_BASED_TYPES_GOALS_ICON,
    alt: "GOAL BASED TYPES GOALS ICON",
  },
  // Architecture
  ARCHITECTURE_BG_SHAPE_BLUE: {
    src: ARCHITECTURE_BG_SHAPE_BLUE,
    alt: "",
  },
  SECURITY_ICON: {
    src: SECURITY_ICON,
    alt: "Security",
  },
  API_READY_ICON: {
    src: API_READY_ICON,
    alt: "Api Ready",
  },
  MULTI_ASSET_ICON: {
    src: MULTI_ASSET_ICON,
    alt: "Multi Asset",
  },
  TECHNOLOGY_STACK_ICON: {
    src: TECHNOLOGY_STACK_ICON,
    alt: "Technology Stack",
  },
  CLOUD_HOSTING_ICON: {
    src: CLOUD_HOSTING_ICON,
    alt: "Cloud Hosting",
  },
  MARKETPLACE_ICON: {
    src: MARKETPLACE_ICON,
    alt: "Marketplace",
  },

  // Projects
  PROJECTS_1: {
    src: PROJECTS_1,
    alt: "",
  },
  PROJECTS_2: {
    src: PROJECTS_2,
    alt: "",
  },
  PROJECTS_3: {
    src: PROJECTS_3,
    alt: "",
  },
  PROJECTS_4: {
    src: PROJECTS_4,
    alt: "",
  },
  PROJECTS_5: {
    src: PROJECTS_5,
    alt: "",
  },
  PROJECTS_6: {
    src: PROJECTS_6,
    alt: "",
  },
  PROJECTS_7: {
    src: PROJECTS_7,
    alt: "",
  },
  PROJECTS_8: {
    src: PROJECTS_8,
    alt: "",
  },
  PROJECTS_9: {
    src: PROJECTS_9,
    alt: "",
  },
  PROJECTS_10: {
    src: PROJECTS_10,
    alt: "",
  },
  // JFD_BANK_IMAGE: {
  //   src: JFD_BANK_IMAGE,
  //   alt: "JFD Bank",
  // },
  // PROJECTS_APEX_FINTECH: {
  //   src: PROJECTS_APEX_FINTECH_IMAGE,
  //   alt: "Apex Fintech Solution logo",
  // },
  // PROJECTS_INVESTEC: {
  //   src: PROJECTS_INVESTEC_IMAGE,
  //   alt: "Investec logo",
  // },
  // PROJECTS_GO_MARKETS: {
  //   src: PROJECTS_GO_MARKETS_IMAGE,
  //   alt: "Go Markets logo",
  // },
  // WHO WE SERVE
  WHO_WE_SERVE_1: {
    src: WHO_WE_SERVE_1,
    alt: "",
  },
  WHO_WE_SERVE_2: {
    src: WHO_WE_SERVE_2,
    alt: "",
  },
  WHO_WE_SERVE_3: {
    src: WHO_WE_SERVE_3,
    alt: "",
  },
  WHO_WE_SERVE_4: {
    src: WHO_WE_SERVE_4,
    alt: "",
  },
  // Marketplace
  APEX_FIN_IMAGE: {
    src: APEX_FIN_IMAGE,
    alt: "Apex Fintech Solutions",
  },
  ALPACA_IMAGE: {
    src: ALPACA_IMAGE,
    alt: "Alpaca Markets",
  },
  INTERACTIVE_BROKERS_IMAGE: {
    src: INTERACTIVE_BROKERS_IMAGE,
    alt: "Interactive Brokers",
  },
  PRIME_XM_IMAGE: {
    src: PRIME_XM_IMAGE,
    alt: "Prime XM",
  },
  DRIVE_WEALTH_IMAGE: {
    src: DRIVE_WEALTH_IMAGE,
    alt: "Drive Wealth",
  },
  SAXO_BE_INVESTED_IMAGE: {
    src: SAXO_BE_INVESTED_IMAGE,
    alt: "Saxo Be Invested",
  },
  BLOOM_BERG_IMAGE: {
    src: BLOOM_BERG_IMAGE,
    alt: "Bloom Berg",
  },
  FACT_SET_IMAGE: {
    src: FACT_SET_IMAGE,
    alt: "Fact Set",
  },
  THOMSON_REUTERS_IMAGE: {
    src: THOMSON_REUTERS_IMAGE,
    alt: "Thomson Reuters",
  },
  INTERCOM_IMAGE: {
    src: INTERCOM_IMAGE,
    alt: "Intercom",
  },
  TWILIO_IMAGE: {
    src: TWILIO_IMAGE,
    alt: "Twilio",
  },
  ARK_DEV_IMAGE: {
    src: ARK_DEV_IMAGE,
    alt: "Ark Dev",
  },
  PLAID_IMAGE: {
    src: PLAID_IMAGE,
    alt: "Plaid",
  },
  FX_CUBIC_IMAGE: {
    src: FX_CUBIC_IMAGE,
    alt: "FX Cubic",
  },
  GOLD_I_IMAGE: {
    src: GOLD_I_IMAGE,
    alt: "Gold I",
  },
  ONE_ZERO_IMAGE: {
    src: ONE_ZERO_IMAGE,
    alt: "One Zero",
  },
  AWS_IMAGE: {
    src: AWS_IMAGE,
    alt: "Aws",
  },
  DATA_DOG_IMAGE: {
    src: DATA_DOG_IMAGE,
    alt: "Data Dog",
  },
  PARTNER_IMAGE: {
    src: PARTNER_IMAGE,
    alt: "Partner",
  },
  QUALYS_IMAGE: {
    src: QUALYS_IMAGE,
    alt: "Qualys",
  },
  JUMIO_IMAGE: {
    src: JUMIO_IMAGE,
    alt: "Jumio",
  },
  TRULIOO_IMAGE: {
    src: TRULIOO_IMAGE,
    alt: "Trulioo",
  },
  // new market place

  MARKET_BROKERAGE_1: {
    src: MARKET_BROKERAGE_1,
    alt: "",
  },
  MARKET_BROKERAGE_2: {
    src: MARKET_BROKERAGE_2,
    alt: "",
  },
  MARKET_BROKERAGE_3: {
    src: MARKET_BROKERAGE_3,
    alt: "",
  },
  MARKET_BROKERAGE_4: {
    src: MARKET_BROKERAGE_4,
    alt: "",
  },
  MARKET_BROKERAGE_5: {
    src: MARKET_BROKERAGE_5,
    alt: "",
  },

  MARKET_DMS_1: {
    src: MARKET_DMS_1,
    alt: "",
  },
  MARKET_DMS_2: {
    src: MARKET_DMS_2,
    alt: "",
  },
  MARKET_DMS_3: {
    src: MARKET_DMS_3,
    alt: "",
  },
  MARKET_DMS_4: {
    src: MARKET_DMS_4,
    alt: "",
  },
  MARKET_DMS_5: {
    src: MARKET_DMS_5,
    alt: "",
  },
  MARKET_MARKET_1: {
    src: MARKET_MARKET_1,
    alt: "",
  },
  MARKET_MARKET_2: {
    src: MARKET_MARKET_2,
    alt: "",
  },
  MARKET_MARKET_3: {
    src: MARKET_MARKET_3,
    alt: "",
  },
  MARKET_OTHER_1: {
    src: MARKET_OTHER_1,
    alt: "",
  },
  MARKET_OTHER_2: {
    src: MARKET_OTHER_2,
    alt: "",
  },
  MARKET_OTHER_3: {
    src: MARKET_OTHER_3,
    alt: "",
  },
  MARKET_OTHER_4: {
    src: MARKET_OTHER_4,
    alt: "",
  },
  MARKET_OTHER_5: {
    src: MARKET_OTHER_5,
    alt: "",
  },
  //  ABOUT US HERO
  ABOUT_US_HERO: {
    src: ABOUT_US_HERO,
    alt: "",
  },
  ABOUT_US_HERO_2: {
    src: ABOUT_US_HERO_2,
    alt: "",
  },
  ABOUT_US_BG: {
    src: ABOUT_US_BG,
    alt: "",
  },
  ABOUT_US_ICON_1: {
    src: ABOUT_US_ICON_1,
    alt: "",
  },
  ABOUT_US_ICON_2: {
    src: ABOUT_US_ICON_2,
    alt: "",
  },
  ABOUT_US_ICON_3: {
    src: ABOUT_US_ICON_3,
    alt: "",
  },
  ABOUT_US_ICON_4: {
    src: ABOUT_US_ICON_4,
    alt: "",
  },
  // Awards
  GLOBAL_FOREX_AWARDS: {
    src: GLOBAL_FOREX_AWARDS,
    alt: "Global Forex Awards",
  },
  GLOBAL_FOREX_B2B_AWARDS: {
    src: GLOBAL_FOREX_B2B_AWARDS,
    alt: "Global Forex B2B Awards",
  },
  WEALTH_TECH_100_AWARDS: {
    src: WEALTH_TECH_100_AWARDS,
    alt: "Wealth Tech 100 Awards",
  },
  MEA_FINANCE_AWARDS: {
    src: MEA_FINANCE_AWARDS,
    alt: "MEA Finance Awards",
  },
  CLEAR_VIEW_FINANCIAL_AWARDS: {
    src: CLEAR_VIEW_FINANCIAL_AWARDS,
    alt: "Clear View Financial Awards",
  },
  FRANKLIN_TEMPLETON_AWARDS: {
    src: FRANKLIN_TEMPLETON_AWARDS,
    alt: "Franklin Templeton",
  },
  // HIRING_MODAL_ICON
  HIRING_MODAL_ICON: {
    src: HIRING_MODAL_ICON,
    alt: "Franklin Templeton",
  },

  // Home / Delivery Plans
  PLANS_CHECK_CIRCLE_GREEN: {
    src: PLANS_CHECK_CIRCLE_GREEN_IMAGE,
    alt: "",
  },
  PLANS_CHECK_CIRCLE_WHITE: {
    src: PLANS_CHECK_CIRCLE_WHITE_IMAGE,
    alt: "",
  },

  // Contact
  ContactBackground,
  PhoneIcon,
  EmailIcon,
  LocationIcon,
  TSLogo,
  // Call Action
  CallActionBG,

  // About / History
  ABOUT_HISTORY: {
    src: ABOUT_HISTORY_IMAGE,
    alt: "",
  },

  // Products / Hero
  PRODUCTS_HERO_PROTOTYPE: {
    src: PRODUCTS_HERO_PROTOTYPE_IMAGE,
    alt: "Smart phone with Bruno app open",
  },

  // Products / Benefits
  PRODUCTS_BRUNO_CORE_BENEFITS_ICON_1_SVG,
  PRODUCTS_BRUNO_CORE_BENEFITS_ICON_2_SVG,
  PRODUCTS_BRUNO_CORE_BENEFITS_ICON_3_SVG,
  PRODUCTS_BRUNO_CORE_BENEFITS_ICON_4_SVG,
  PRODUCTS_BRUNO_CORE_BENEFITS_ICON_5_SVG,

  PRODUCTS_BRUNO_API_BENEFITS_ICON_1_SVG,
  PRODUCTS_BRUNO_API_BENEFITS_ICON_2_SVG,
  PRODUCTS_BRUNO_API_BENEFITS_ICON_3_SVG,
  PRODUCTS_BRUNO_API_BENEFITS_ICON_4_SVG,
  PRODUCTS_BRUNO_API_BENEFITS_ICON_5_SVG,

  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_1_SVG,
  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_2_SVG,
  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_3_SVG,
  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_4_SVG,
  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_5_SVG,
  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_6_SVG,
  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_7_SVG,
  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_8_SVG,
  PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_9_SVG,

  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_1_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_2_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_3_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_4_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_5_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_6_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_7_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_8_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_9_SVG,
  PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_10_SVG,

  PRODUCTS_BENEFITS_BG_SHAPE_RED_SVG,
  PRODUCTS_BENEFITS_BG_SHAPE_GREEN_SVG,

  PRODUCTS_USE_CASE: {
    src: PRODUCTS_USE_CASE_IMAGE,
    alt: "a man using a laptop while holding it in his hand while standing",
  },
  // Legal
  LEGAL_TS_LOGO: {
    src: LEGAL_TS_LOGO_IMAGE,
    alt: "Tradesocio logo",
  },

  // Solutions / Preview Cards
  SOLUTIONS_DIGITAL_BANKS: {
    src: SOLUTIONS_DIGITAL_BANKS_IMAGE,
    alt: "Currencies symbols",
  },
  SOLUTIONS_ADVISORS: {
    src: SOLUTIONS_ADVISORS_IMAGE,
    alt: "People having a meeting",
  },
  SOLUTIONS_BROKERAGE: {
    src: SOLUTIONS_BROKERAGE_IMAGE,
    alt: "Laptop and Iphone on a desktop with bruno app open",
  },

  // Solutions / Bg Shapes
  SOLUTIONS_BG_SHAPE_RED_SVG,
  SOLUTIONS_BG_SHAPE_GREEN_SVG,
  // Solutions / Detail
  SOLUTION_HERO_BG_SVG,
  SOLUTION_HERO_SHAPE_RED_SVG,

  SOLUTION_HERO_DIGITAL_BANKS: {
    src: SOLUTION_HERO_DIGITAL_BANKS_IMAGE,
    alt: "Currencies symbols",
  },
  SOLUTION_HERO_BROKERAGE: {
    src: SOLUTION_HERO_BROKERAGE_IMAGE,
    alt: "Laptop and Iphone on a desktop with bruno app open",
  },
  SOLUTION_HERO_ADVISORS: {
    src: SOLUTION_HERO_ADVISORS_IMAGE,
    alt: "People having a meeting",
  },
  // Solutions / Detail / Feature
  SOLUTION_FEATURE_DIGITAL_BANKS_ICON_1_SVG,
  SOLUTION_FEATURE_DIGITAL_BANKS_ICON_2_SVG,
  SOLUTION_FEATURE_DIGITAL_BANKS_ICON_3_SVG,

  SOLUTION_FEATURE_BROKERAGE_ICON_1_SVG,
  SOLUTION_FEATURE_BROKERAGE_ICON_2_SVG,
  SOLUTION_FEATURE_BROKERAGE_ICON_3_SVG,
  SOLUTION_FEATURE_BROKERAGE_ICON_4_SVG,
  SOLUTION_FEATURE_BROKERAGE_ICON_5_SVG,
  SOLUTION_FEATURE_BROKERAGE_ICON_6_SVG,
  SOLUTION_FEATURE_BROKERAGE_ICON_7_SVG,

  SOLUTION_FEATURE_ADVISORS_ICON_1_SVG,
  SOLUTION_FEATURE_ADVISORS_ICON_2_SVG,
  SOLUTION_FEATURE_ADVISORS_ICON_3_SVG,
  SOLUTION_FEATURE_ADVISORS_ICON_4_SVG,
  SOLUTION_FEATURE_ADVISORS_ICON_5_SVG,
  SOLUTION_FEATURE_ADVISORS_ICON_6_SVG,

  SOLUTION_ARCHITECTURE_PROTOTYPE: {
    src: SOLUTION_ARCHITECTURE_PROTOTYPE_IMAGE,
    alt: "Smart phone with Bruno app open",
  },
  // Solutions / Architecture / Cards
  SOLUTION_ARCHITECTURE_DIGITAL_BANKS_INTEGRATION: {
    src: SOLUTION_ARCHITECTURE_DIGITAL_BANKS_INTEGRATION_IMAGE,
    alt: "",
  },
  SOLUTION_ARCHITECTURE_DIGITAL_BANKS_SECURITY: {
    src: SOLUTION_ARCHITECTURE_DIGITAL_BANKS_SECURITY_IMAGE,
    alt: "",
  },
  SOLUTION_ARCHITECTURE_DIGITAL_BANKS_SCALABILITY: {
    src: SOLUTION_ARCHITECTURE_DIGITAL_BANKS_SCALABILITY_IMAGE,
    alt: "",
  },
  SOLUTION_ARCHITECTURE_BROKERAGE_CUSTOMIZATION: {
    src: SOLUTION_ARCHITECTURE_BROKERAGE_CUSTOMIZATION_IMAGE,
    alt: "",
  },
  SOLUTION_ARCHITECTURE_BROKERAGE_SUPPORT: {
    src: SOLUTION_ARCHITECTURE_BROKERAGE_SUPPORT_IMAGE,
    alt: "",
  },
  SOLUTION_ARCHITECTURE_ADVISORS_CUSTOMIZATION: {
    src: SOLUTION_ARCHITECTURE_ADVISORS_CUSTOMIZATION_IMAGE,
    alt: "",
  },
  SOLUTION_ARCHITECTURE_ADVISORS_SUPPORT: {
    src: SOLUTION_ARCHITECTURE_ADVISORS_SUPPORT_IMAGE,
    alt: "",
  },
  SOLUTION_ARCHITECTURE_BG_SHAPE_GREEN_SVG,
  SOLUTION_ARCHITECTURE_BG_SHAPE_RED_SVG,
  SOLUTION_ARCHITECTURE_BG_SHAPE_YELLOW_SVG,
};

export default IMAGES;
