import { useEffect, useRef } from "react";
//  MUI
import { Box, Container, Grid } from "@mui/material";

// shared
import IMAGES from "shared/images";
import SECTIONS from "shared/sections";

// components
import TitleComponent from "components/Section/Title/title.component";
import CardComponent from "./Card/card.component";
import LeadershipMain from "./LeadershipMain/leadershipMain.component";

// styles
import {
  CardBottomLogo,
  LeadershipBox,
  LeadershipWrp,
} from "./leadership.styles";
// animations
import "./animation.css";

function Leadership({ colorSub }) {
  const elementRef = useRef(null);
  const { LEADERSHIP } = SECTIONS;
  useEffect(() => {
    const bodyRect = document.body.getBoundingClientRect();
    const leaderContainerPosition = elementRef.current.getBoundingClientRect();
    const offset = Math.abs(bodyRect.top - leaderContainerPosition.top) - 200;
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;

      if (currentPosition > offset) {
        elementRef.current.classList.add("card-list-wrp");
      } else {
        elementRef.current.classList.add("card-list-wrp");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <LeadershipBox>
      {/* <LeadershipMain /> */}
      <LeadershipWrp>
        <TitleComponent
          title={LEADERSHIP.title}
          subTitle={LEADERSHIP.subTitle}
          colorSub={colorSub}
        />
        <Container maxWidth="xl">
          <Grid container spacing={3} ref={elementRef} id="cardListMain">
            {LEADERSHIP?.content?.map((leadershipDetail, index) => {
              const key = `leader-card-${index + 1}`;

              return (
                <CardComponent
                  key={key}
                  name={leadershipDetail?.name}
                  desc={leadershipDetail?.desc}
                  designation={leadershipDetail?.designation}
                  image={leadershipDetail?.image}
                  index={index + 1}
                  socialImage={LEADERSHIP?.socialImage}
                  linkedin={leadershipDetail?.linkedin}
                  // instagram={LEADERSHIP?.socialImage?.instagram}
                  // pinterest={LEADERSHIP?.socialImage?.pinterest}
                />
              );
            })}
            {/* <CardBottomLogo>
              <Box
                component="img"
                src={IMAGES.TS_LOGO.src}
                alt={IMAGES.TS_LOGO.alt}
              />
            </CardBottomLogo> */}
          </Grid>
        </Container>
      </LeadershipWrp>
    </LeadershipBox>
  );
}
export default Leadership;
