import { useRef } from "react";

const useSlider = () => {
  const sliderRef = useRef();

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  return { sliderRef, handleNext, handlePrevious };
};

export default useSlider;
