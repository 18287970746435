import axios from "axios";
import { useState, useMemo, useEffect } from "react";

async function getPosts() {
  const response = await axios.get(
    `${process.env.REACT_APP_WORDPRESS_BLOG_URL}/posts/?order=desc&orderby=date&per_page=3`,
  );
  return response;
}
function usePosts() {
  const [posts, setPosts] = useState([]);
  const postsList = useMemo(() => posts, [posts]);

  useEffect(() => {
    getPosts().then((posts) => setPosts(posts));
  }, []);

  return postsList;
}

export default usePosts;
