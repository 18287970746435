// MUI
import { Stack, styled, Typography } from "@mui/material";
import Container from "@mui/material/Container";
// theme
import { COLORS } from "styles/theme";

export const TitleContainer = styled(Container)(({ theme }) => ({
  // paddingTop: theme.spacing(14),
  position: "relative",
}));

export const ContentContainer = styled(Stack)(() => ({
  paddingLeft: "15px",
  paddingRight: "15px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN Pro",
  fontStyle: "normal",
  fontWeight: 900,
  textAlign: "center",
  fontSize: "60px",
  lineHeight: "73px",
  marginBottom: "16px",
  color: theme.palette.primary.main,
}));
export const Subtitle = styled(Typography)(() => ({
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "18px",
  lineHeight: "28px",
  maxWidth: "1073px",
  margin: "0 auto",
  textAlign: "center",
  marginBottom: "72px",
  color: COLORS.TEXT_GREY6,
}));
