import { createTheme } from "@mui/material";

export const COLORS = {
  PRIMARY_BLUE: "#303183",
  PRIMARY_YELLOW: "#DD9326",
  SECONDARY_GREEN: "#217C4E",
  SECONDARY_RED: "#FB3934",
  SECONDARY_BLUE: "#40419D",
  TEXT_BLACK: "#212529",
  TEXT_WHITE: "#fff",
  TEXT_WHITE2: "#FBFDFD",
  TEXT_GREY: "#505E6A",
  TEXT_GREY2: "#808080",
  TEXT_GREY3: "#D0CECE",
  TEXT_GREY6: "#737373",

  TEXT_LIGHT_GREY: "#D9D9D9",
  TEXT_SKYBLUE: "#009ECD",
  BORDER_GREY_LIGHT: "#EBEBEB",
  BORDER_GREY_MEDIUM: "#D9DBE1",
  BORDER_GREY_DARK: "#D9D9D9",
  TEXT_YELLOW: "#FFD945",

  SOLUTIONS_CARD_BUTTON_GREEN: "rgba(33, 124, 78, 0.05)",
};

export const GRADIENTS = {
  PRIMARY_BLUE: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${COLORS.PRIMARY_BLUE}`,
  PLANS_CARD: `linear-gradient(180deg, ${COLORS.SECONDARY_GREEN} 0%, rgba(47, 54, 127, 0.7) 70%)`,
  MOST_POPULAR_BADGE: `linear-gradient(180deg, #3E5178 0%, #504B75 100%)`,
};

export const SHADOWS = {
  PLANS_CONTAINER: "0px 2px 15px rgba(0, 0, 0, 0.08)",
  PLANS_CARD: "0px 42px 34px rgba(82, 67, 194, 0.295755)",
  TEXT_SHADOW: "4px 2px 4px rgba(0, 0, 0, 0.5)",
  ARCHITECTURE_CARD: "48px 24px 48px rgba(24, 37, 56, 0.12)",
  PRODUCT_BENEFIT_BOX: " 0px 6px 7px -4px rgba(0, 0, 0, 0.2)",
};

export const BORDERS = {
  ARCHITECTURE_CARD: "1px solid rgba(88, 88, 88, 0.26)",
  PRODUCT_BENEFIT_BOX: "1px solid #D0D0D0",
  PRODUCT_USE_CASE_CONTENT_BOX: "2px solid #D9D9D9",
};
// Used Fonts in Design
// Check index.css for fonts added
// Heading //
// Din Pro 900

// Sub-heading //
// Courier New 700

// Body //
// Nexa Bold 700

// Buttons //
// Din Pro 900

// Navbar //
// Din Pro 900

// Footer //
// Din Pro 900

const theme = createTheme();

const customTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY_BLUE,
      yellow: COLORS.PRIMARY_YELLOW,
    },
    secondary: {
      main: COLORS.SECONDARY_RED,
      green: COLORS.SECONDARY_GREEN,
    },
    text: {
      black: COLORS.TEXT_BLACK,
      white: COLORS.TEXT_WHITE,
      grey: COLORS.TEXT_GREY,
      skyblue: COLORS.TEXT_SKYBLUE,
    },
    border: {
      light: COLORS.BORDER_GREY_LIGHT,
      medium: COLORS.BORDER_GREY_MEDIUM,
      dark: COLORS.BORDER_GREY_DARK,
    },
  },
  typography: {
    fontFamily: "Nexa Bold, sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 700,
    fontWeightBold: 900,
    h1: {
      fontFamily: ["DIN Pro", "sens-serif"].join(","),
      fontSize: "4.375rem",
      fontWeight: 900,
      [theme.breakpoints.down("sm")]: {
        fontSize: "3rem",
      },
    },
    h2: {
      fontFamily: ["DIN Pro", "sens-serif"].join(","),
      fontSize: "3.75rem",
      fontWeight: 900,
      color: COLORS.PRIMARY_BLUE,
      [theme.breakpoints.down("sm")]: {
        fontSize: "3rem",
      },
    },
    h3: {
      fontFamily: ["DIN Pro", "sens-serif"].join(","),
      fontSize: "2rem",
      fontWeight: 900,
      color: COLORS.SECONDARY_GREEN,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    h4: {
      fontFamily: ["DIN Pro", "sens-serif"].join(","),
      fontSize: "1.375rem",
      fontWeight: 900,
      color: COLORS.PRIMARY_BLUE,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
    h5: {
      fontFamily: ["DIN Pro", "sens-serif"].join(","),
      fontSize: 16,
      fontWeight: 900,
      color: COLORS.TEXT_GREY,
    },

    subtitle1: {
      fontFamily: "Nexa Bold",
      fontWeight: 700,
      fontSize: "1.125rem",
      color: COLORS.TEXT_GREY6,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    subtitle2: {
      fontFamily: "Nexa Bold",
      fontWeight: 700,
      fontSize: "1rem",
      color: COLORS.TEXT_GREY6,
    },
    body1: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    body2: {
      fontFamily: "Nexa Bold",
      fontWeight: 700,
      fontSize: 15,
      color: COLORS.SECONDARY_BLUE,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1300,
      xxl: 1536,
    },
  },
  shape: {},
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => "none",
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "DIN Pro",
          fontWeight: 700,
          minWidth: "8.5rem",
          fontSize: ".875rem",
          letterSpacing: ".025rem",
        },
      },
    },
  },
});

export default customTheme;
