import { COLORS } from "styles/theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const { styled, Box, Typography, ListItemText } = require("@mui/material");

export const FeaturesContainer = styled(Box)(({ theme }) => ({
  //   backgroundColor: COLORS.PRIMARY_BLUE,
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));
export const FeaturesTitle = styled(Typography)(() => ({
  color: COLORS.TEXT_WHITE,
  fontSize: "3.5rem",
}));
export const FeaturesDesc = styled(Typography)(() => ({
  color: "#D5D5D5",
  lineHeight: "23px",
  fontSize: "1.25rem",
}));
export const TitleFeatures = styled(Typography)(() => ({
  color: COLORS.PRIMARY_BLUE,
  lineHeight: "68px",
  fontSize: "2rem",
  fontFamily: "Courier New",
}));
export const ListText = styled(ListItemText)(() => ({
  "& p": {
    fontFamily: "Nexa Bold",
    color: "#8C8C8C",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28.8px",
  },
  "& span": {
    color: COLORS.PRIMARY_BLUE,
  },
}));

export const FeaturesIcon = styled("img")(() => ({
  objectFit: "contain",
  paddingRight: "8px",
  width: "100%",
}));
export const FeaturesImgWrap = styled(Box)(() => ({
  textAlign: "right",
}));
export const FeaturesImg = styled("img")(() => ({
  maxWidth: "100%",
  height: "auto",
  objectFit: "contain",
}));
