import React from "react";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import SECTIONS from "shared/sections";
import { Container, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  WhoWeServeCards,
  WhoWeServeCardsContent,
  WhoWeServeCardsContentCard,
  WhoWeServeCardsContentCardDesc,
  WhoWeServeCardsContentCardImg,
  WhoWeServeCardsContentCardTitle,
  WhoWeServeWrapper,
} from "./WhoWeServe.style";

function WhoWeServe() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const { ABOUT_SERVE } = SECTIONS;
  const cards = ABOUT_SERVE?.cards?.map((card, index) => (
    <Grid item md={3} key={card.title}>
      <WhoWeServeCardsContentCard>
        <Stack alignItems="center" justifyContent="center">
          <WhoWeServeCardsContentCardTitle variant="subtitle2">
            {card.title}
          </WhoWeServeCardsContentCardTitle>
          <WhoWeServeCardsContentCardDesc variant="subtitle2">
            {card.desc}
          </WhoWeServeCardsContentCardDesc>
        </Stack>
        <WhoWeServeCardsContentCardImg
          src={card.img.src}
          sx={{
            left: "-5px",
            top: index % 2 !== 0 ? "0" : "initial",
            bottom: index % 2 === 0 ? "-17px" : "initial",
          }}
        />
      </WhoWeServeCardsContentCard>
    </Grid>
  ));
  return (
    <WhoWeServeWrapper>
      <SectionTitle
        title={ABOUT_SERVE.title}
        subtitle={ABOUT_SERVE.subTitle}
        maxWidth="75%"
      />
      <WhoWeServeCards>
        <Container>
          <WhoWeServeCardsContent>
            <Grid container spacing={2} justifyContent="space-between">
              {cards}
            </Grid>
          </WhoWeServeCardsContent>
        </Container>
      </WhoWeServeCards>
    </WhoWeServeWrapper>
  );
}

export default WhoWeServe;
