import { Box, Grid, Stack } from "@mui/material";
import SECTIONS from "shared/sections";
import { HashLink } from "react-router-hash-link";

import { HeroContainer } from "../hero.styles";

import {
  HeroImg,
  HeroRightSection,
  HeroRightSectionContentBtn,
  HeroRightSectionContentTitle,
} from "./HomeHero.style";

function HomeHero() {
  const { HOME_HERO } = SECTIONS;
  return (
    <HeroContainer maxWidth="xl">
      <Grid container rowGap={1} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box>
            <HeroImg src={HOME_HERO.media.src} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <HeroRightSection>
            <Stack sx={{ px: { sm: 12, md: 0 } }}>
              <HeroRightSectionContentTitle variant="h2">
                Your end to end white label solution in less than 30 days
              </HeroRightSectionContentTitle>
              <HeroRightSectionContentBtn
                component={HashLink}
                variant="contained"
                to="/#contact"
              >
                Request a demo
              </HeroRightSectionContentBtn>
            </Stack>
          </HeroRightSection>
        </Grid>
      </Grid>
    </HeroContainer>
  );
}

export default HomeHero;
