// MUI
import { Container, Grid } from "@mui/material";
// shared
import SECTIONS from "shared/sections";
// component
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import AccordianComponent from "./Accordian/accordian.component";
// styles
import QuestionWrap from "./question.styles";

function Question() {
  const { QUESTION } = SECTIONS;
  return (
    <QuestionWrap component="section" id="faq">
      <SectionTitle title={QUESTION.title} subtitle={QUESTION.subTitle} />
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {QUESTION?.content?.map((questionDetail, index) => {
            const key = `question-${index + 1}`;
            return (
              <AccordianComponent
                key={key}
                question={questionDetail?.question}
                answer={questionDetail?.answer}
              />
            );
          })}
        </Grid>
      </Container>
    </QuestionWrap>
  );
}
export default Question;
