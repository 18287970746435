import PropTypes from "prop-types";
// MUI
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
// Theme
import { COLORS } from "styles/theme";
// Shared
import IMAGES from "shared/images";

const LegalData = {
  "/privacy-policy": {
    title: "Privacy Policy",
    content: [
      "At Tradesocio, we take the privacy and security of our customers' personal and financial information very seriously. We understand that trust is the foundation of any successful business, and we are committed to protecting your data and maintaining your confidence in us.",
      "We collect, use, and store your personal and financial information in accordance with applicable data protection laws and regulations. This includes the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).",
      "We use your personal and financial information to provide our services to you, including opening and maintaining your account, processing transactions, and providing customer support. We may also use your information to improve our services, prevent fraud, and comply with legal and regulatory requirements.",
      "We do not sell or share your personal and financial information with third parties, except as required by law or as necessary to provide our services to you. We may share your information with our service providers, who are contractually bound to protect the confidentiality and security of your information.",
      "We have implemented strong security measures to protect your information from unauthorized access, use, or disclosure. This includes encryption of sensitive information, secure servers, and regular security audits.",
      "We also provide you with control over your personal and financial information through your account settings, where you can view, update, or delete your information.",
      "If you have any questions or concerns about our privacy policy, please contact us at support@tradesocio.com. We are committed to working with you to address any issues and maintain your trust in us.",
    ],
    isNumbered: false,
  },
  "/legal-disclaimer": {
    title: "Disclaimer",
    content: [
      "The information provided on the Tradesocio website is for general informational purposes only. It is not intended to be, and should not be taken as, financial or investment advice. While we make every effort to ensure that the information on our website is accurate and up-to-date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability or suitability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.",
      "In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of or in connection with the use of this website.",
      "Through this website you are able to link to other websites which are not under the control of Tradesocio. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.",
      "Every effort is made to keep the website up and running smoothly. However, Tradesocio takes no responsibility for and will not be liable for the website being temporarily unavailable due to technical issues beyond our control.",
      "Please also note that the performance data displayed on the Tradesocio website is hypothetical and for illustrative purposes only. Past performance is not indicative of future results.",
      "This Disclaimer is subject to change without notice.",
    ],
    isNumbered: false,
  },
  "/terms-of-service": {
    title: "Terms of Service",
    subtitle: `Thank you for considering Tradesocio for your investment needs. By accessing or using our services, you agree to be bound by the following terms and conditions of service ("Terms of Service"). If you do not agree to all of the terms and conditions of this agreement, you may not access or use our services.`,
    content: [
      `Agreement to Terms of Service: These terms of service ("Terms of Service") apply to your access to and use of the Tradesocio website, services and products (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree to these terms, you may not access or use the Service.`,
      `Changes to Terms of Service: We reserve the right to make changes to these Terms of Service at any time. We will notify you of any changes by posting the new Terms of Service on the Tradesocio website. Your continued use of the Service following the posting of changes to these terms will mean you accept those changes.`,
      `Service Availability: The Service may be temporarily unavailable from time to time for maintenance or other reasons. Tradesocio assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, User communications.`,
      `User Content: By using the Service, you may submit, upload, publish or otherwise make available certain content, including but not limited to text, photos, videos, and audio (collectively, "User Content"). You retain all rights in, and are solely responsible for, the User Content you make available through the Service.`,
      `Prohibited Uses: You may use the Service only for lawful purposes and in accordance with these Terms of Service. You agree not to use the Service:
      <ul>
       <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
       <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable</li>
      </ul>
      `,
    ],
    isNumbered: true,
  },
};

function getLegalContent(contentList, pageTitle, isNumbered = false) {
  return contentList.map((content, index) => {
    const key = `${pageTitle}-content-${index}`;
    return (
      <Typography
        key={key}
        component={isNumbered ? "li" : "p"}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: content }}
        color={COLORS.TEXT_GREY}
      />
    );
  });
}

function LegalContent({ url }) {
  const { LEGAL_TS_LOGO } = IMAGES;
  const page = LegalData[url];
  return (
    <Box
      component="section"
      sx={{
        paddingY: 15,
        background: `url(${LEGAL_TS_LOGO.src})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Container
        maxWidth="xxl"
        sx={{
          backgroundColor: "rgb(255, 255, 255,.5)",
          backdropFilter: "blur(15px)",
        }}
      >
        <SectionTitle title={page.title} textAlign="left" />
        <Typography variant="body1" sx={{ marginTop: 2 }} paragraph>
          {page.subtitle}
        </Typography>
        <Stack
          component={page.isNumbered ? "ol" : "ul"}
          sx={{
            paddingTop: 2,
            gap: 4,
            paddingLeft: page.isNumbered ? 4 : 0,
            width: "90%",
          }}
        >
          {getLegalContent(page.content, page.title, page.isNumbered)}
        </Stack>
      </Container>
    </Box>
  );
}

LegalContent.propTypes = {
  url: PropTypes.string.isRequired,
};
export default LegalContent;
