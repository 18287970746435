// MUI
import Container from "@mui/material/Container";
import Section from "components/Section/section.component";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

// Components
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import SECTIONS from "shared/sections";
// Shared
import IMAGES from "shared/images";
// Theme
import { COLORS } from "styles/theme";
// Styles
import {
  PlanContainer,
  PlanGradientButton,
  PlanWhiteButton,
  PlanCard,
  MostPopularBadge,
} from "./plans.styles";

const DeliveryPlansData = {
  brunoMVP: {
    title: "Bruno Core",
    subtitle: "Your softlaunch in 30 days will include.",
    features: [
      "Custody Ready",
      "Payments Ready",
      "Azure cloud hosting",
      "Execution Ready",
      "Identity Validation ",
      "Custom Branding",
      "Market Data Ready",
    ],
    button: { text: "Contact Us", link: "/#contact-us" },
  },
  brunoBuild: {
    title: "Bruno Bespoke",
    subtitle: "Unlimited Features:",
    features: ["Unlimited", "IP Transferrable", "On-premise delivery"],
    cta: { link: "/#", text: "View all the features" },
    button: { text: "Contact Us", link: "/#contact-us" },
  },
};

function getPlanFeatures(featuresList, icon, textColor) {
  return featuresList.map((feature) => (
    <Stack key={feature} direction="row" sx={{ gap: 1, marginBottom: 1 }}>
      <Box component="img" src={icon.src} alt={icon.alt} />
      <Typography variant="body1" sx={{ color: textColor, fontWeight: 400 }}>
        {feature}
      </Typography>
    </Stack>
  ));
}

function Plans() {
  const { DELIVERY_PLANS } = SECTIONS;
  const { brunoMVP, brunoBuild } = DeliveryPlansData;
  const { PLANS_CHECK_CIRCLE_GREEN, PLANS_CHECK_CIRCLE_WHITE } = IMAGES;
  return (
    <Section>
      <Container maxWidth="xl">
        <SectionTitle
          title={DELIVERY_PLANS.title}
          subtitle={DELIVERY_PLANS.subtitle}
          maxWidth="55%"
        />
      </Container>
      <PlanContainer>
        {/* Left Card */}
        <Box>
          <Typography
            variant="h2"
            sx={{ fontSize: "1.75rem", color: COLORS.TEXT_BLACK }}
            gutterBottom
          >
            {brunoMVP.title}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "1.125rem", color: COLORS.TEXT_BLACK }}
            gutterBottom
          >
            {brunoMVP.subtitle}
          </Typography>

          {getPlanFeatures(
            brunoMVP.features,
            PLANS_CHECK_CIRCLE_GREEN,
            COLORS.TEXT_GREY2,
          )}
          <PlanGradientButton variant="contained" href={brunoMVP.button.link}>
            {brunoMVP.button.text}
          </PlanGradientButton>
        </Box>
        {/* Right Card */}
        <PlanCard>
          <MostPopularBadge>MOST POPULAR</MostPopularBadge>
          <Typography
            variant="h2"
            sx={{
              fontSize: "1.75rem",
              color: COLORS.TEXT_WHITE2,
              marginBottom: 4,
            }}
          >
            {brunoBuild.title}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "1.5rem",
              color: COLORS.TEXT_WHITE2,
              marginBottom: 4,
            }}
          >
            {brunoBuild.subtitle}
          </Typography>

          {getPlanFeatures(
            brunoBuild.features,
            PLANS_CHECK_CIRCLE_WHITE,
            COLORS.TEXT_WHITE2,
          )}
          <Link
            href={brunoBuild.cta.link}
            sx={{
              textDecoration: "underline",
              color: COLORS.TEXT_WHITE2,
              marginTop: 4,
              textAlign: "center",
            }}
          >
            {brunoBuild.cta.text}
          </Link>
          <PlanWhiteButton
            variant="contained"
            href={brunoBuild.button.link}
            fullWidth
          >
            {brunoBuild.button.text}
          </PlanWhiteButton>
        </PlanCard>
      </PlanContainer>
    </Section>
  );
}

export default Plans;
