// Components
import Blog from "components/Blog/blog.component";
import Architecture from "components/Architecture/architecture.component";
import BrunoProducts from "components/BrunoProducts/brunoProducts.component";
import Projects from "components/Projects/projects.component";
import Features from "components/Features/features.component";
import Hero from "components/Hero/hero.component";
import Plans from "components/Plans/plans.component";
import Leadership from "components/Leadership/leadership.component";
import Question from "components/Question/question.component";
import Contact from "components/Contact/contact.component";
import Marketplace from "components/Marketplace/marketplace.component";
import Awards from "components/Awards/awards.component";
import History from "components/History/history.component";
import MarketIntegration from "components/FeaturesContent/marketIntegration/MarketIntegration.component";
// import CallAction from "components/CallAction/callaction.component";
import SECTIONS from "shared/sections";
import ChoosePackage from "components/ChoosePackage/ChoosePackage.component";
import FeaturesBruno from "components/Features/FeaturesBruno.component";

const { CHOOSE_PACKAGE, FEATURESBRUNO_DATA } = SECTIONS;

function Home() {
  return (
    <>
      <Hero page="Home" />
      <BrunoProducts />
      <ChoosePackage sectionData={CHOOSE_PACKAGE} />
      <Marketplace />
      <FeaturesBruno sectionData={FEATURESBRUNO_DATA} />
      <Architecture />
      {/* <Projects /> */}
      {/* <Plans /> */}
      {/* <History page="Home" /> */}
      {/* <Awards /> */}
      {/* <Leadership /> */}
      <Question />
      <Blog />
      {/* <CallAction /> */}
      <Contact />
    </>
  );
}

export default Home;
