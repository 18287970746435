import PropTypes from "prop-types";
// MUI
import { Grid, Stack } from "@mui/material";

// Styles
import {
  CardBox,
  CardButton,
  CardCategory,
  CardContentBox,
  CardContentDetails,
  CardDate,
  CardImage,
  CardTitle,
} from "./card.styles";

function CardComponent({ title, image, content, dateString, link }) {
  const date = new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return (
    <Grid item xs={12} sm={6} md={4} sx={{ minHeight: "100%" }}>
      <CardBox>
        <Stack height="100%">
          <CardImage src={image} />
          <CardContentBox>
            <CardCategory>
              Category .<CardDate>{date}</CardDate>
            </CardCategory>
            <CardTitle
              gutterBottom
              variant="h5"
              component="div"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <CardContentDetails dangerouslySetInnerHTML={{ __html: content }} />
          </CardContentBox>
          <CardButton variant="contained" href={link} target="_blank">
            Read More
          </CardButton>
        </Stack>
      </CardBox>
    </Grid>
  );
}
CardComponent.defaultProps = {
  title: "",
  image: "",
  content: "",
  dateString: "",
  link: "",
};

CardComponent.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  content: PropTypes.string,
  dateString: PropTypes.string,
  link: PropTypes.string,
};

export default CardComponent;
