import { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Tab,
  useMediaQuery,
} from "@mui/material";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import SECTIONS from "shared/sections";
import { COLORS } from "styles/theme";
import TabPanel from "./TabPanel";
import { TabTitle, StyledTabs } from "./marketplace.styles";

function Marketplace() {
  const [value, setValue] = useState(0);
  const {
    MARKETPLACE: { title, subtitle, categories, brands },
  } = SECTIONS;
  const matchesDownMD = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      component="section"
      sx={{
        marginTop: "64px",
        marginBottom: "64px",
      }}
    >
      <Container maxWidth="lg">
        <Box>
          <SectionTitle title={title} subtitle={subtitle} maxWidth="80%" />

          <Box sx={{ marginTop: "68px" }}>
            <Grid container columnSpacing={5}>
              <Grid item xs={12} md={4}>
                <Box>
                  {/* <TabTitle>Categories</TabTitle> */}

                  <StyledTabs
                    orientation={matchesDownMD ? "horizontal" : "vertical"}
                    value={value}
                    onChange={handleChange}
                    variant={matchesDownMD ? "scrollable" : "fullWidth"}
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                  >
                    {categories.map((category) => (
                      <Tab
                        label={
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "24px",
                            }}
                          >
                            {category}
                          </Typography>
                        }
                        key={category}
                      />
                    ))}
                  </StyledTabs>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  marginTop: 5,
                  paddingRight: { md: 2 },
                  maxHeight: { xs: 400, md: 768 },
                  minHeight: 200,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: COLORS.PRIMARY_BLUE,
                  },
                }}
              >
                {brands.map((categoryBrand, index) => (
                  <TabPanel
                    value={value}
                    index={index}
                    key={index}
                    brands={categoryBrand}
                  />
                ))}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Marketplace;
