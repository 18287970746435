import { COLORS } from "styles/theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const { styled, Box, Typography, ListItemText } = require("@mui/material");

export const MarketContainer = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.PRIMARY_BLUE,
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
}));
export const MarketTitle = styled(Typography)(() => ({
  color: COLORS.TEXT_WHITE,
  fontSize: "3.5rem",
}));
export const MarketDesc = styled(Typography)(() => ({
  color: "#D5D5D5",
  lineHeight: "23px",
  fontSize: "1.25rem",
}));
export const TitleFeatures = styled(Typography)(() => ({
  color: COLORS.PRIMARY_YELLOW,
  lineHeight: "20px",
  fontSize: "1.25rem",
}));
export const ListText = styled(ListItemText)(() => ({
  fontFamily: "Nexa Bold",
  color: COLORS.TEXT_WHITE,
  fontWeight: 700,
  fontSize: "1.25rem",
  lineHeight: "29.5px",
}));

export const MarkIcon = styled(FiberManualRecordIcon)(() => ({
  color: COLORS.TEXT_WHITE,
  fontSize: ".7rem",
}));
export const MarketImgWrap = styled(Box)(() => ({
  textAlign: "right",
}));
export const MarketImg = styled("img")(() => ({
  maxWidth: "100%",
  height: "auto",
}));
