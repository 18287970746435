import { styled, Box } from "@mui/material";

const DotWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "-20px",
});

export default DotWrapper;
