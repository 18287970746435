// MUI
import { Container, Grid } from "@mui/material";
// components
import CardComponent from "components/Blog/Card/card.component";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import usePosts from "hooks/usePosts";
// shared
import SECTIONS from "shared/sections";
// style
import { BlogBox } from "./blog.styles";

function Blog() {
  const { BLOG } = SECTIONS;
  const posts = usePosts();
  return (
    <BlogBox component="section">
      <Container maxWidth="xl">
        <SectionTitle title={BLOG.title} subtitle={BLOG.subTitle} />
        <Grid
          container
          sx={{ marginTop: 4 }}
          spacing={2}
          justifyContent="center"
        >
          {posts?.data?.map((post, index) => {
            return (
              <CardComponent
                key={(BLOG.title, index)}
                title={post?.title.rendered}
                content={post?.excerpt.rendered}
                dateString={post?.date}
                link={post?.link}
                image={post?.jetpack_featured_media_url}
              />
            );
          })}
        </Grid>
      </Container>
    </BlogBox>
  );
}
export default Blog;
