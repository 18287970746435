import {
  Box,
  Container,
  Grid,
  Typography,
  Tab,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PROTOTYPE_VIDEO from "assets/videos/home_bruno_phone_prototype.mp4";

import { Fragment, useState } from "react";
import IMAGES from "shared/images";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import SECTIONS from "shared/sections";
import Product from "./Product";
import TabPanel from "./TabPanel";
import {
  ProductWrapper,
  StyledTabs,
  Section,
  TabStyle,
  ImgItem,
} from "./brunoProducts.styles";

function BrunoProducts() {
  const [value, setValue] = useState(0);
  const {
    BRUNO_PRODUCTS: { title, subtitle, products, tabs, panels },
  } = SECTIONS;
  const theme = useTheme();
  const screenDownToMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Section component="section">
      <Container maxWidth="xl">
        <Box>
          <SectionTitle title={title} />

          {/* <ProductWrapper>
            {!screenDownToMD && (
              <Box
                component={IMAGES.HOME_PRODUCTS_BG_COUNTDOWN_SVG}
                sx={{
                  position: "absolute",
                  width: "100%",
                  zIndex: -1,
                  top: { xs: "12%", lg: 0 },
                }}
              />
            )}
            <Grid container rowSpacing={12} columnSpacing={4}>
              {products.map((product, index) => {
                const key = `product-${index}`;
                const { title, description, icon, link } = product;
                return (
                  <Product
                    title={title}
                    description={description}
                    icon={icon}
                    index={index}
                    link={link}
                    key={key}
                  />
                );
              })}
            </Grid>
          </ProductWrapper> */}

          <Box position="relative" mt={8}>
            {/* <Grid item xs={12} md={5}>
                <Stack
                  position="relative"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    minHeight: "45rem",
                    width: "100%",
                  }}
                > */}
            {/* <Box
                    component="img"
                    src={IMAGES.HOME_PRODUCTS_PHONE_PROTOTYPE.src}
                    alt={IMAGES.HOME_PRODUCTS_PHONE_PROTOTYPE.alt}
                    sx={{
                      zIndex: 2,
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                      maxWidth: { xs: "25rem", lg: "20rem" },
                      maxHeight: { xs: "35rem", lg: "45rem" },
                    }}
                  /> */}
            {/* <Box
                    component="video"
                    sx={{
                      zIndex: 2,
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                      maxWidth: { xs: "35rem", lg: "30rem" },
                      maxHeight: { xs: "40rem", lg: "45rem" },
                    }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source src={PROTOTYPE_VIDEO} type="video/mp4" />
                  </Box> */}

            {/* <Box
                    component="img"
                    src={IMAGES.PRODUCTS_BG_SHAPE_BLUE.src}
                    alt={IMAGES.PRODUCTS_BG_SHAPE_BLUE.alt}
                    sx={{
                      zIndex: -1,
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      maxWidth: { xs: "24rem", lg: "27rem" },
                      maxHeight: { xs: "38.5rem", lg: "43rem" },
                      position: "absolute",
                    }}
                  /> */}
            {/* </Stack>
              </Grid> */}
            <StyledTabs
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              aria-label="bruno-options"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((tab, index) => (
                <TabStyle
                  value={index}
                  key={tab}
                  label={tab}
                  disableRipple
                  sx={{ minWidth: "150px" }}
                />
              ))}
            </StyledTabs>
            {panels.map((items, index) => {
              const key = `item-${index}`;
              return (
                <Fragment key={key}>
                  <TabPanel
                    key={key}
                    value={value}
                    index={index}
                    items={items}
                  />
                </Fragment>
              );
            })}

            {/* {!screenDownToMD && (
              <Box
                component="img"
                src={IMAGES.PRODUCTS_BG_SHAPE_ORANGE.src}
                alt={IMAGES.PRODUCTS_BG_SHAPE_ORANGE.alt}
                sx={{
                  position: "absolute",
                  top: "-14%",
                  bottom: 0,
                  right: "-6%",
                  zIndex: -1,
                }}
              />
            )} */}
          </Box>
        </Box>
        {/* {!screenDownToMD && (
          <Box
            component="img"
            src={IMAGES.PRODUCTS_BG_SHAPE_GREEN.src}
            alt={IMAGES.PRODUCTS_BG_SHAPE_GREEN.alt}
            sx={{
              position: "absolute",
              top: "-2%",
              left: "5%",
              zIndex: -5,
            }}
          />
        )} */}
      </Container>
    </Section>
  );
}

export default BrunoProducts;
