import { IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DotWrapper from "./appendDots.styles";

function AppendDots(dots, handleNext, handlePrevious) {
  return (
    <DotWrapper>
      <IconButton
        edge="end"
        disableFocusRipple
        disableRipple
        onClick={handlePrevious}
      >
        <NavigateBeforeIcon fontSize="large" />
      </IconButton>
      <ul> {dots} </ul>
      <IconButton
        edge="start"
        disableFocusRipple
        disableRipple
        onClick={handleNext}
      >
        <NavigateNextIcon fontSize="large" />
      </IconButton>
    </DotWrapper>
  );
}

export default AppendDots;
