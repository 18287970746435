import { useState } from "react";
import PropTypes from "prop-types";
// MUI
import { AccordionDetails, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// styles
import {
  AccordianAnswer,
  AccordianBox,
  AccordianQuestion,
  AccordianWrap,
  AccordionSummaryBox,
} from "./accrodian.style";

function AccordianComponent({ question, answer }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Grid item xs={12} md={6}>
      <AccordianWrap>
        <AccordianBox
          onChange={() => {
            setExpanded((prev) => !prev);
          }}
        >
          <AccordionSummaryBox
            expandIcon={expanded === false ? <AddIcon /> : <RemoveIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AccordianQuestion>{question}</AccordianQuestion>
          </AccordionSummaryBox>
          <AccordionDetails>
            <AccordianAnswer>{answer}</AccordianAnswer>
          </AccordionDetails>
        </AccordianBox>
      </AccordianWrap>
    </Grid>
  );
}

AccordianComponent.defaultProps = {
  question: "",
  answer: "",
};

AccordianComponent.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
};
export default AccordianComponent;
