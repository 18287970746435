import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { COLORS, GRADIENTS, SHADOWS } from "styles/theme";

export const PlanContainer = styled(Stack)(({ theme }) => ({
  width: "43.5rem",
  minHeight: "29.25rem",
  position: "relative",
  background: "rgba(255, 255, 255, 0.5)",
  border: `1px solid ${COLORS.BORDER_GREY_LIGHT}`,
  boxShadow: SHADOWS.PLANS_CONTAINER,
  borderRadius: "1.625rem",
  margin: "0 auto",
  marginTop: theme.spacing(8),
  paddingLeft: theme.spacing(8),
  paddingTop: theme.spacing(6),
  paddingRight: theme.spacing(48),
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    padding: theme.spacing(4, 0, 4, 3),
    gap: theme.spacing(8),
    minHeight: "fit-content",
    alignItems: "center",
  },
}));

export const PlanButtonBase = styled(Button)(({ theme }) => ({
  borderRadius: "1.5rem",
  fontSize: "1.25rem",
  height: "2.8rem",
  marginTop: theme.spacing(1),
}));

export const PlanGradientButton = styled(PlanButtonBase)(() => ({
  background: GRADIENTS.PRIMARY_BLUE,
  color: COLORS.TEXT_WHITE,
  mixBlendMode: "normal",
  opacity: 0.5,
  width: "13rem",
}));

export const PlanWhiteButton = styled(PlanButtonBase)(() => ({
  backgroundColor: COLORS.TEXT_WHITE2,
  color: COLORS.PRIMARY_BLUE,
  marginTop: "auto",
  "&:hover": {
    color: COLORS.TEXT_WHITE2,
  },
}));

export const PlanCard = styled(Stack)(({ theme }) => ({
  width: "18.25rem",
  minHeight: "29.5rem",
  position: "absolute",
  right: "5%",
  top: "-8%",
  background: GRADIENTS.PLANS_CARD,
  boxShadow: SHADOWS.PLANS_CARD,
  borderRadius: "1.625rem",
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(4),
  paddingRight: theme.spacing(4),
  backdropFilter: "blur(17.5px)",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    position: "relative",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const MostPopularBadge = styled(Stack)(({ theme }) => ({
  width: "7.5rem",
  height: "1.75rem",
  position: "absolute",
  right: "5%",
  top: "5%",
  background: GRADIENTS.MOST_POPULAR_BADGE,
  borderRadius: "13px",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Courier New",
  fontSize: "0.725rem",
  color: COLORS.TEXT_YELLOW,
  paddingTop: theme.spacing(0.5),
}));
