import SectionTitle from "components/SectionTitle/sectionTitle.component";
import React, { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import SECTIONS from "shared/sections";
import { Button, Container, Grid, Stack } from "@mui/material";
import {
  HeroCard,
  HeroCardBtns,
  HeroCardContent,
  HeroCardTitle,
  HeroCardsWrapper,
  HeroContainer,
  HeroContent,
} from "./Hiring.style";
import Modal from "./Modal/Modal.component";

const { HIRING } = SECTIONS;
function Hiring() {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [activeCard, setActiveCard] = useState(null);

  const handleClickOpen = (scrollType, card) => () => {
    setOpen(true);
    setScroll(scrollType);
    setActiveCard(card);
  };
  const cards = HIRING?.cards?.map((card) => (
    <Grid item md={3} key={card.title}>
      <HeroCard>
        <Stack height="100%">
          <HeroCardTitle variant="h5">{card.title}</HeroCardTitle>
          <HeroCardContent variant="subtitle2">{card.content}</HeroCardContent>
          <HeroCardBtns>
            <Stack direction="row" spacing={1} width="100%">
              <Button
                variant="outlined"
                sx={{
                  color: "#DD9326",
                  borderColor: "#DD9326",
                  minWidth: "5.5rem",
                }}
                startIcon={<LocationOnIcon />}
              >
                {card.location}
              </Button>
            </Stack>
            <Button
              size="small"
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              sx={{
                fontSize: "16px",
                padding: "10px 20px",
                mt: 3,
                width: "100%",
              }}
              onClick={handleClickOpen("paper", card.popUpContent)}
            >
              Read More
            </Button>
          </HeroCardBtns>
        </Stack>
      </HeroCard>
    </Grid>
  ));
  return (
    <HeroContainer>
      <SectionTitle
        title={HIRING.title}
        maxWidth="80%"
        subtitle={HIRING.contetentTitle}
      />
      <Container>
        <HeroContent>
          <HeroCardsWrapper>
            <Grid container spacing={1} justifyContent="center">
              {cards}
            </Grid>
          </HeroCardsWrapper>
          <Modal
            open={open}
            setOpen={setOpen}
            scroll={scroll}
            setScroll={setScroll}
            handleClickOpen={handleClickOpen}
            data={activeCard}
          />
        </HeroContent>
      </Container>
    </HeroContainer>
  );
}

export default Hiring;
