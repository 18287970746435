// MUI
import { Stack, styled, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
// theme
import { COLORS } from "styles/theme";

export const BlogBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  minHeight: "800px",
}));

// Clean up
export const BlogContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(14),
  position: "relative",
}));

export const BlogContentContainer = styled(Stack)(() => ({
  paddingLeft: "15px",
  paddingRight: "15px",
}));

export const BlogTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN Pro",
  fontStyle: "normal",
  fontWeight: 900,
  textAlign: "center",
  fontSize: "60px",
  lineHeight: "73px",
  marginBottom: "23px",
  color: theme.palette.primary.yellow,
}));
export const BlogSubtitle = styled(Typography)(() => ({
  fontFamily: "Courier New",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "28px",
  maxWidth: "1073px",
  textAlign: "center",
  color: COLORS.TEXT_GREY,
}));
